import { Outlet } from "react-router-dom";

const FulllayoutLogin=()=>{
    return(
<div>
    <Outlet/>
    </div>
    )
}

export default FulllayoutLogin;
