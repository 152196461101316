import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { BiRupee } from 'react-icons/bi';
import { useNavigate, useParams } from "react-router";
 import ProductSKeleton from "../../WebApp/WebComponets/skeleton/ProductSkeleton";
import PlaceholderImage from "../../Assets/images/image_placeholder.jpg"
import WebProductSKeleton from "../../WebApp/WebComponets/skeleton/WebProductSkeleton";
function WebProducts({ cat }) {
    let { id } = useParams()
    let navigate = useNavigate()
    let GoToProduct = () => {
        navigate('/productdetail')
    }
    const [productList, setProductList] = useState([])
    let [loading, setLoading] = useState(false)
    const [viewAll, setViewAll] = useState(false)
    // const [imageList, seImageList] = useState([{
    //     image: Image_0
    // },
    // {
    //     image: Image_1
    // },
    // {
    //     image: Image_2
    // },
    // {
    //     image: Image_3
    // },
    // ])
    useEffect(() => {
        productListData();
    }, [])
    const productListData = () => {
        setLoading(true);
        let url = process.env.REACT_APP_BASEURL_TWO + `product_list/`
        const config = {
            headers: {
                // "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        axios.get(url, config)
            .then(res => {
                setProductList(res.data.data)
                // console.log(res.data.data)
                setLoading(false)
            }
            ).catch(err => {
                // console.log(err)
                setLoading(false);
            })
    }

    // .....post addtocart api.........
    // const addtoCart = (public_id) => {
    //     setLoading(true)
    
    //         let url = "http://192.168.1.51:8001/cart/"
    //         const config = {
    //             headers: {
    //                 "Authorization": localStorage.getItem("token"),
    //                 "Access-Control-Allow-Origin": "*",
    //                 "Content-type": "application/json",
    //             }
    //         };
    //         const body = {
    //             products: public_id,
    //             quantity: 1
    //         }
    //         axios.post(url, body, config)
    //             .then(res => {
    //                 setLoading(false)

    //                 console.log("Add cart product", res.data)
    //             }
    //             ).catch(err => {
    //                 console.log("errrrr", err)
    //                 setLoading(false)
    //             })
        
    // }

    return (
        <>
            <div className="mt-5"  style={{fontFamily:'roboto'}}>
                <div className="d-flex justify-content-between " style={{ paddingLeft: '150px', paddingRight: '140px' }}>
                    <div style={{ fontWeight: 'bold', color: '#0F328E', borderBottom: "2px solid #0F328E", width: '200px', textAlign: 'left' }}>All Products</div>
                    {/* <div style={{ fontWeight: 'bold', display: 'flex', cursor: "pointer" }} onClick={() => { setViewAll(!viewAll) }}>{!viewAll ? 'View More' : 'View less'}</div>  */}
                </div>
                {loading ? <WebProductSKeleton /> : <div className='row  col-12' >
                   <div class=" flex-wrap d-flex justify-content-center mt-4 mb-4" >
                        {productList.length === 0 ? <div className="d-flex justify-content-center mx-auto mt-5"><div className="text-center fs-4 fw-bold">Coming Soon, Check back later.</div></div> :
                            productList.map((prdouct) => {
                                return (<>
                                    <Card onClick={() => navigate(`/webdetail/${prdouct.public_id}`)}  style={{ width: '230px', height: 'auto', borderColor: '#A9ADB0', borderRadius: '16px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)", margin: '10px', cursor: 'pointer' }} >
                                        <div style={{ background: '#F5F5F5', borderRadius: '16px' }}>
                                            <Card.Img variant="top"  src={prdouct.images === "" ? PlaceholderImage : prdouct.images} style={{ background: '#F5F5F5', height: 'auto', width: 'auto', borderRadius: '16px', }} />
                                        </div>
                                        <Card.Body>
                                            <Card.Title  style={{ color: '#0F328E', textAlign: 'left', marginTop: '10PX', fontSize: '16px' }}>{prdouct.part_number}</Card.Title>
                                            <Card.Text  style={{ color: 'grey', textAlign: 'left', fontSize: '13px', lineHeight: '22px' }}>
                                                {prdouct.description}
                                                <div className='d-flex justify-content-between mt-1' >
                                                    {/* <h5 style={{ textAlign: 'left', fontSize: '18px' }}><BiRupee />{prdouct.product_price}</h5> */}
                                                    {/* <h5 style={{ color: 'green', fontSize: '16px' }}>Save-<BiRupee />{Math.floor(Math.random() * 10000)}</h5> */}
                                                </div>
                                                <hr style={{ marginTop: "-3px" }}></hr>
                                            </Card.Text>
                                            {/* <div className='d-flex justify-content-between' style={{ marginTop: "-3px" }}>
                                                <h5 style={{ color: 'red', fontSize: '16px' }}>In stock</h5>
                                                <h5 style={{ color: 'green', fontSize: '16px' }}>Save-<BiRupee />42000</h5>
                                            </div> */}
                                            <div className='d-flex justify-content-center mt-1 '>
                                                <Button style={{ background: '#0F328E', borderColor: '#0F328E' }} onClick={() => { navigate(`/webdetail/${prdouct.public_id}`) }}>View Detail</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </>)
                            })
                        }
                    </div>
                </div>}
            </div>

        </>
    )
}
export default WebProducts;