import React, { useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { toast, ToastContainer } from "react-toastify";
import axios from 'axios';

const CheckoutPage = () => {
    const [loading, setLoading] = useState(false)
    const [countryList, setcountryList] = useState()
    const [publicId, setPublicId] = useState()
    const [addData, setAddData] = useState({
        email: '', emailerr: '',
        full_name: '', full_nameerr: '',
        last_name: '', last_nameerr: '',
        address: '', addresserr: '',
        address_2: '', address_2err: '',
        state: '', stateerr: '',
        country: '', countryerr: '',
        city: '', cityerr: '',
        number: null,
        phone_number: '', phone_numbererr: '',
        zipcode: '', zipcodeerr: ''
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        let error = { status: 0, full_name: '', last_name: '', address: '', city: '', email: '', phone_number: '', zipcode: '', state: '', country: '', }
        if (addData.full_name === '') { error.full_name = 'Please enter full name'; error.status = 1 } else { error.full_name = '' }
        if (addData.last_name === '') { error.last_name = 'Please enter last name'; error.status = 1 } else { error.last_name = '' }
        if (addData.address === '') { error.address = 'Please enter address'; error.status = 1 } else { error.address = '' }
        if (addData.city === '') { error.city = 'Please enter city'; error.status = 1 } else { error.city = '' }
        if (addData.state === '') { error.state = 'Please enter state'; error.status = 1 } else { error.state = '' }
        if (addData.country === '') { error.country = 'Please enter country'; error.status = 1 } else { error.country = '' }
        if (addData.phone_number === '') { error.phone_number = 'Please enter Phone no'; error.status = 1 } else { error.phone_number = '' }
        if (addData.zipcode === '') { error.zipcode = 'Please enter zipcode'; error.status = 1 } else { error.zipcode = '' }

        if (addData.email <= 0 || addData.email === '') { error.email = 'Please enter email'; error.status = 1 } else { error.email = '' }
        setAddData({ ...addData, full_nameerr: error.full_name, last_nameerr: error.last_name, emailerr: error.email, phone_numbererr: error.phone_number, addresserr: error.address, cityerr: error.city, zipcodeerr: error.zipcode, stateerr: error.state, countryerr: error.country })
        if (error.status === 0) {
            setLoading(true)

            let url = process.env.REACT_APP_BASEURL_TWO + "address/"
            const config = {
                headers: {
                    Authorization: localStorage.getItem('token'),
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json"
                }
            };
            let data = [{
                "first_name": addData.full_name,
                "last_name": addData.last_name,
                "address_1": addData.address,
                "address_2": addData.address_2,
                "email_for_order_confirmation": addData.email,
                "phone_number": addData.phone_number,
                "zip_code": addData.zipcode,
                "city": addData.city,
                "state": addData.state,
                "country": addData.country,
                "address_type": "billing_address"
            }];
            console.log(data, "dattaaa")
            axios.post(url, data, config)
                .then(res => {
                    setLoading(false)
                    toast.success("Form Submit successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                        theme: "colored",
                    })

                })
                .catch(err => {
                    setLoading(false)
                    toast.error(`${err.response.data.error}`, {
                        autoClose: 1000,
                        theme: "colored",
                    });
                })
        }
    }
    return (<>
        <ToastContainer></ToastContainer>
            <div style={{ marginTop: '50px', fontFamily: 'roboto' }}>
                <div className="row justify-content-evenly">
                    <div className="col-lg-7 col-md-12 col-sm-12 col-12">
                        <div className=' mb-5'>
                            <div className='ms-md-4 ms-sm-0 p-3 text-left' style={{ background: '#f7f7f7', borderTopRightRadius: '10px', borderTopLeftRadius: '10PX', fontSize: '19px' }}>Customer Detail</div>
                            <Card className='ms-md-4 ms-sm-0' style={{ boxShadow: '1px 1px 2px 0px #b0b0b0', background: '#ffffff' }}>
                                <Card.Body className='text-left'>
                                    <Form onSubmit={handleSubmit}>
                                        <Row className='col-12 ' >
                                            <p>Billing address</p>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="email" placeholder="Email for order confirmation"
                                                    defaultValue={addData.email} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, email: e.target.value, emailerr: '' }) : setAddData({ ...addData, email: '', emailerr: 'email  is required.' }) }} />
                                                <small className="text-danger">{addData.emailerr}</small>
                                            </Form.Group>
                                        </Row>
                                        <Row className='col-12'>
                                            <Col sm={12} lg={6}>
                                                <Form.Group className="m-1  text-justify" >
                                                    <Form.Control type="text" placeholder="Full Name"
                                                        defaultValue={addData.full_name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, full_name: e.target.value, full_nameerr: '' }) : setAddData({ ...addData, full_name: '', full_nameerr: 'fullname  is required.' }) }} />
                                                    <small className="text-danger ">{addData.full_nameerr}</small>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <Form.Group className="m-1 text-justify" controlId="formBasicEmail">
                                                    <Form.Control type="text" placeholder="Last Name"
                                                        defaultValue={addData.last_name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, last_name: e.target.value, last_nameerr: '' }) : setAddData({ ...addData, last_name: '', last_nameerr: 'lastname  is required.' }) }} />
                                                    <small className="text-danger ">{addData.last_nameerr}</small>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row className='col-12 ' >
                                            <Form.Group className="mb-3 mt-2" controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="address-1"
                                                    defaultValue={addData.address} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, address: e.target.value, addresserr: '' }) : setAddData({ ...addData, address: '', addresserr: 'address  is required.' }) }} />
                                                <small className="text-danger">{addData.addresserr}</small>
                                            </Form.Group>
                                        </Row>
                                        <Row className='col-12 ' >
                                            <Form.Group className="mb-3" >
                                                <Form.Control type="text" placeholder="address-2 (optional)"
                                                    defaultValue={addData.address_2} onChange={(e) => { setAddData({ ...addData, address_2: e.target.value }) }} />
                                                <small className="text-danger"></small>
                                            </Form.Group>
                                        </Row>
                                        <Row className='col-12 ' >
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="City"
                                                    defaultValue={addData.city} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, city: e.target.value, cityerr: '' }) : setAddData({ ...addData, city: '', cityerr: 'city  is required.' }) }} />
                                                <small className="text-danger">{addData.cityerr}</small>
                                            </Form.Group>
                                        </Row>
                                        <Row className='col-12'>
                                            <Col sm={12} lg={6}>
                                                <Form.Group className="m-1">
                                                    <Form.Control type="text" placeholder="Country"
                                                        defaultValue={addData.country} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, country: e.target.value, countryerr: '' }) : setAddData({ ...addData, country: '', countryerr: 'country  is required.' }) }} />
                                                    <small className="text-danger ">{addData.countryerr}</small>


                                                    {/* {countryList.length === 0 ? <Form.Select><option>Not Found</option></Form.Select> : <Form.Select style={{ padding: '15px' }} >
                                                    <option disabled>Vehicle List</option>

                                                    {countryList.map((item) => {

                                                        return (<option onChange={() => { setPublicId(item) }}>{item.name}</option>)
                                                    })
                                                    }
                                                </Form.Select>} */}
                                                </Form.Group>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <Form.Group className="m-1 text-justify" controlId="formBasicEmail">
                                                    <Form.Control type="text" placeholder="State"
                                                        defaultValue={addData.state} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, state: e.target.value, stateerr: '' }) : setAddData({ ...addData, state: '', nameerr: 'state  is required.' }) }} />
                                                    <small className="text-danger ">{addData.stateerr}</small>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className='col-12'>
                                            <Col sm={12} lg={6}>
                                                <Form.Group className="m-1  text-justify" >
                                                    <Form.Control type="tel" placeholder="Zip Code"
                                                        defaultValue={addData.zipcode} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, zipcode: e.target.value, zipcodeerr: '' }) : setAddData({ ...addData, zipcode: '', zipcodeerr: 'zipcode  is required.' }) }} />
                                                    <small className="text-danger ">{addData.zipcodeerr}</small>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                    <Form.Control type="number" placeholder="Enter phone number"
                                                        maxLength={10} value={addData.phone_number} onChange={(e) => { e.target.value !== '' || e.target.value.length > e.target.maxLength ? setAddData({ ...addData, phone_number: e.target.value.slice(0, e.target.maxLength), phone_numbererr: '' }) : setAddData({ ...addData, phone_number: '', phone_numbererr: "Number is required" }) }} />
                                                    <small className="text-danger">{addData.phone_numbererr}</small>

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {/* ............Shipping Detail..................... */}
                                        {/* <Row className='col-12'>
                                        <p>Shipping Details</p>
                                        <Col sm={12} lg={6}>
                                            <Form.Group className="m-1  text-justify" >
                                                <Form.Control type="text" placeholder="Full Name"
                                                    defaultValue={addData.full_name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, name: e.target.value, nameerr: '' }) : setAddData({ ...addData, name: '', nameerr: 'name  is required.' }) }} />
                                                <small className="text-danger ">{addData.full_nameerr}</small>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} lg={6}>
                                            <Form.Group className="m-1 text-justify" controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="Last Name"
                                                    defaultValue={addData.last_name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, name: e.target.value, nameerr: '' }) : setAddData({ ...addData, name: '', nameerr: 'name  is required.' }) }} />
                                                <small className="text-danger ">{addData.last_nameerr}</small>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='col-12 ' >
                                        <Form.Group className="mb-3 mt-2" controlId="formBasicEmail">
                                            <Form.Control type="text" placeholder="address-1"
                                                defaultValue={addData.email} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, email: e.target.value, emailerr: '' }) : setAddData({ ...addData, email: '', emailerr: 'email  is required.' }) }} />
                                            <small className="text-danger">{addData.emailerr}</small>
                                        </Form.Group>
                                    </Row>
                                    <Row className='col-12 ' >
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="email" placeholder="address-2 (optional)"
                                                defaultValue={addData.email} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, email: e.target.value, emailerr: '' }) : setAddData({ ...addData, email: '', emailerr: 'email  is required.' }) }} />
                                            <small className="text-danger">{addData.emailerr}</small>
                                        </Form.Group>
                                    </Row>
                                    <Row className='col-12 ' >
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="text" placeholder="City"
                                                defaultValue={addData.email} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, email: e.target.value, emailerr: '' }) : setAddData({ ...addData, email: '', emailerr: 'email  is required.' }) }} />
                                            <small className="text-danger">{addData.emailerr}</small>
                                        </Form.Group>
                                    </Row>
                                    <Row className='col-12'>
                                        <Col sm={12} lg={6}>
                                            <Form.Group className="m-1  text-justify" >
                                                <Form.Control type="text" placeholder="Country"
                                                    defaultValue={addData.name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, name: e.target.value, nameerr: '' }) : setAddData({ ...addData, name: '', nameerr: 'name  is required.' }) }} />
                                                <small className="text-danger ">{addData.nameerr}</small>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} lg={6}>
                                            <Form.Group className="m-1 text-justify" controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="State"
                                                    defaultValue={addData.name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, name: e.target.value, nameerr: '' }) : setAddData({ ...addData, name: '', nameerr: 'name  is required.' }) }} />
                                                <small className="text-danger ">{addData.nameerr}</small>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='col-12'>
                                        <Col sm={12} lg={6}>
                                            <Form.Group className="m-1  text-justify" >
                                                <Form.Control type="text" placeholder="Zip Code"
                                                    defaultValue={addData.name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, name: e.target.value, nameerr: '' }) : setAddData({ ...addData, name: '', nameerr: 'name  is required.' }) }} />
                                                <small className="text-danger ">{addData.nameerr}</small>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} lg={6}>
                                            <Form.Group className="m-1 text-justify" controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="Phone Number"
                                                    defaultValue={addData.name} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, name: e.target.value, nameerr: '' }) : setAddData({ ...addData, name: '', nameerr: 'name  is required.' }) }} />
                                                <small className="text-danger ">{addData.nameerr}</small>
                                            </Form.Group>
                                        </Col>
                                    </Row> */}

                                        <div className="d-flex justify-content-end p-3" style={{}} >
                                            <p style={{ fontSize: '20px' }} className='mr-4'>Total Price<br></br><span style={{ fontSize: '20px', color: '#4CB5E2' }}>Rs. 80,483.00</span></p>

                                            <div>
                                                <Button className="ms-1" type="submit" style={{ background: '#4CB5E2', borderColor: '#4CB5E2' }}>
                                                    Place Order
                                                </Button>
                                            </div>
                                        </div>

                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className="card w-lg-50 w-md-100 w-sm-100 w-50" style={{ backgroundColor: '#0F328E' }} >
                            <div className="card-body">
                                <h5 className="card-title text-white">Order Summery</h5>
                                <Row className='d-flex justify-content-between mt-4 text-white'>
                                    <Col>Items </Col>
                                    <Col>01</Col>
                                </Row>
                                <Row className='d-flex justify-content-between mt-4 text-white'>
                                    <Col>Subtotal </Col>
                                    <Col>3335</Col>
                                </Row>
                                <Row className='d-flex justify-content-between mt-4 text-white'>
                                    <Col>Discount </Col>
                                    <Col>0.00</Col>
                                </Row>
                                <Row className='d-flex justify-content-between mt-4 text-info'>
                                    <Col>Total </Col>
                                    <Col>1234</Col>
                                </Row>
                                <div className='d-flex justify-content-center mt-5 '>
                                    <Button style={{ background: '#4CB5E2', borderColor: '#4CB5E2' }} >Place Order</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       
    </>
    )
}

export default CheckoutPage
