import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { handleError } from "../../util/HandleErrors";
import { Link, useNavigate } from "react-router-dom";
import { Row,Col } from "react-bootstrap";

function Profile() {
let navigate = useNavigate()

  useEffect(() => {
    getProfile()
  }, [])
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [profileData, setProfileData] = useState(false);

  const getProfile = () => {
    setLoadingProfile(true);


    let url = process.env.REACT_APP_BASEURL + "profile/";
    let config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",
        "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_KEY),

      },
    };
    axios
      .get(url, config)
      .then((res) => {
        console.log("res", res.data.data);
        setProfileData(res.data.data)
        setLoadingProfile(false);



      })
      .catch((error) => {
        setLoadingProfile(false);
        if (error !== {}) {
          const getErrRes = handleError(error);
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
            theme: "colored",
          });
        }
      });
  };
  return (
    <div className="col-10">

   
   <div className="d-flex justify-content-center p-0" style={{marginTop:'50px'}}>
   <Col lg={6}>
        <div className="card p-0 mt-5 mb-5 ">
          <div className='card-header p-0'>
            <h3 className='align-middle mt-3'>Profile</h3>
          </div>
          <div className='card-body'>
            <ul style={{ listStyleType: 'none',textAlign:'left' }}>
              <li>
                  <span style={{ fontWeight: '500', fontSize: '25px', marginRight: '10px' }}>Name:</span> <span style={{ fontSize: '20px' }}>{profileData.name ? profileData.name:'Sales'}</span>
              </li>
              <li>
                <hr></hr>
                  <span style={{ fontWeight: '500', fontSize: '25px', marginRight: '10px' }}>  Email:</span><span style={{ fontSize: '20px' }}>{profileData.email ? profileData.email:'sales@adroit.com'}</span>
              </li>
              <hr></hr>
              <li>
                  <span style={{ fontWeight: '500', fontSize: '25px', marginRight: '10px' }}>  Mobile No.:</span><span style={{ fontSize: '20px' }}>{profileData.mobile_no ? profileData.mobile_no:'9090909090'}</span>
              </li>
              <hr></hr>
            </ul>
              <button onClick={() => { navigate('/adroitmain')}} type="button" class="btn btn-primary">Go to figma</button>
          </div>


        </div>
          </Col>
        </div>
      </div>
      
    
  )
}

export default Profile