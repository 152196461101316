import React from 'react'
import { Row, Col } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import introvideo from "../Assets/images/adroitvideo.mp4"
import Carousel from 'react-bootstrap/Carousel';
import WhyAdroit from "../Assets/images/whyadroit.svg"
import "./Content.css"

function Content() {


  let navigate = useNavigate()
  const sliderVideos = document.querySelectorAll(".slider-video");

  return (
    <div>
      {/* Content */}
      <div className="page-content bg-white ">
        {/* <div className="Hero mt-5">
          <div style={{ background: 'black' }}>

            <div className="wrapper b " style={{ marginTop: "100px" }}>
              <Video autoPlay loop style={{ height: '520px' }}>
                <source src={introvideo} type="video/webm" />
              </Video>
            </div>
          </div>
        </div> */}
        <div className='slider-mob'>
          <Carousel className='mt-8' variant="dark">
            <Carousel.Item >
              <img
                className="d-block w-100 slider-image"
                src={require('../Assets/images/since_1966.jpeg')}
                alt="First slide"
              />
              <Carousel.Caption>
                <div className='d-flex justify-content-center '>
                  <h1 className='text-white font-change pl-3 pr-3' style={{ fontWeight: '600', fontSize: '50px', background: '#4CB5E2', borderRadius: '5px' }}>Manufacturing Since 1966</h1>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 slider-image"
                src={require('../Assets/images/forgin.jpg')}
                alt="second slide"

              />
              <Carousel.Caption>
                <div className='d-flex justify-content-center '>
                  <h1 className='text-white font-change pl-3 pr-3' style={{ fontWeight: '600', fontSize: '50px', background: '#4CB5E2', borderRadius: '5px' }}>In-house forging capability</h1>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 slider-image"
                src={require('../Assets/images/slider_02.jpeg')}
                alt="second slide"

              />
              <Carousel.Caption>
                <div className='d-flex justify-content-center '>
                  <h1 className='text-white font-change pl-3 pr-3' style={{ fontWeight: '600', fontSize: '50px', background: '#4CB5E2', borderRadius: '5px' }}>Continous Heat Treat Facility</h1>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 "
                src={require('../Assets/images/adroitimg1.jpeg')}
                alt="Third slide"
              />
              <Carousel.Caption >
                <div className='d-flex justify-content-center '>
                  <h1 className='text-white font-change pl-3 pr-3' style={{ fontWeight: '600', fontSize: '50px', background: '#4CB5E2', borderRadius: '5px' }}>World's second largest range</h1>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item >
              <img
                className="d-block w-100 slider-image"
                src={require('../Assets/images/certified.jpeg')}
                alt="fourth slide"
              />
              <Carousel.Caption>
                <div className='d-flex justify-content-center '>
                  <h1 className='text-white font-change pl-3 pr-3' style={{ fontWeight: '600', fontSize: '50px', background: '#4CB5E2', borderRadius: '5px' }}>TS16494:2016 Certified</h1>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 slider-image"
                src={require('../Assets/images/slide_05.jpg')}
                alt="Third slide"
              />
              <Carousel.Caption>
                <div className='d-flex justify-content-center '>
                  <h1 className='text-white font-change pl-3 pr-3' style={{ fontWeight: '600', fontSize: '50px', background: '#4CB5E2', borderRadius: '5px' }}>Balancing Excellence</h1>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div className="wrapper b " style={{ marginTop: "" }}>
                <Video className="d-block  w-100" loop style={{ height: '' }}>
                  <source src={introvideo} type="video/webm" />
                </Video>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
        <div >
        </div>

        {/* Slider END */}
      
        <div className='background_contain2'>
          <div className="content-block d-flex justify-content-center flex-column">
         
            <div className="section-full call-action wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s" >
              <div className="container">
                <div className="row " data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" >
                  <div className='d-flex justify-content-center'>
                    <div className="  col-lg-10 ">
                      <h5 style={{ fontSize: '30px' }} className="title text-center">Welcome To Adroit</h5>
                      <p className="mt-4 text-center" style={{ fontSize: '15px', color: '#767676' }}>Adroit Industries India Ltd. is a reputable company that was established in 1966. It has
                        gained recognition for its quality standards through certifications from IATF and ISO
                        9001.Adroit stands as one of the largest manufacturers of propeller shafts and components in India, offering a wide range of products used in power transmission for global markets.</p>
                    </div>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-center mt-3" onClick={() => { navigate('/aboutus') }}>
                    <a href="#" className=" align-self-center btn  " style={{ background: '#0f338f', color: 'white', fontSize: '14px' }} >
                      <span className='text-center' >Learn more </span>
                      {/* <i className="la la-long-arrow-alt-right" /> */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3 ms-0 me-0" data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
              <div className="col-lg-12 section-head text-center">
                <h5 className="title" style={{ fontSize: '30px' }}>WHAT DEFINES ADROIT</h5>
                <div className="dlab-separator-outer">
                  <div className="dlab-separator bg-primary style-skew" />
                </div>
                {/* <h2 className="font-weight-700 m-b0">What Defines Adroit</h2> */}
              </div>
            </div>
            <div className='d-flex justify-content-center ' data-aos="zoom-in-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
              <Row style={{ background: '#f2f7fa' }}>
                <Col className='d-flex justify-content-center'>
                  <Card style={{ width: '18rem', margin: '10px', border: 'none', background: "transparent" }} >
                    <Card.Body>
                      <div className='d-flex'>
                        <img src={require('../Assets/images/icon_trust.png')} style={{ height: '70px' }} alt="trust"></img>
                        <span style={{ fontSize: '20px', fontWeight: '500', marginTop: '20px' }} >TRUST</span>
                      </div>
                      <Card.Text style={{ color: '#585757', fontSize: '14px', textAlign: 'justify' }}>
                        At Adroit Industries, trust is built into every aspect of our skillful and competent approach to designing, engineering,
                        and manufacturing high-quality driveline products.
                      </Card.Text>
                      <div className="d-flex justify-content-center ">
                        {/* <Button style={{ background: '#4CB5E2', border: 'none' }}>Read More </Button> */}
                      </div>

                    </Card.Body>
                  </Card>
                </Col>
                <Col className='d-flex justify-content-center'>
                  <Card style={{ width: '18rem', margin: '10px', border: 'none', background: "transparent" }} >
                    <Card.Body className=''>
                      <div className='d-flex'>
                        <img src={require('../Assets/images/icons-quality.png')} style={{ height: '70px' }} alt="quality"></img>
                        <span style={{ fontSize: '20px', fontWeight: '500', marginTop: '20px' }} >QUALITY</span>
                      </div>
                      <Card.Text style={{ color: '#585757', fontSize: '14px', textAlign: 'justify' }}>
                        We are committed to delivering quality driveline products that meet or exceed industry standards, backed by exceptional customer support.
                      </Card.Text>
                      <div className="d-flex justify-content-center ">
                        {/* <Button style={{ background: '#4CB5E2', border: 'none' }}>Read More </Button> */}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className='d-flex justify-content-center'>

                  <Card style={{ width: '18rem', margin: '10px', border: 'none', background: "transparent" }} >
                    <Card.Body>
                      <div className='d-flex'>
                        <img src={require('../Assets/images/icons-innovation.png')} style={{ height: '70px' }} alt="innovation"></img>
                        <span style={{ fontSize: '20px', fontWeight: '500', marginTop: '20px' }} >INNOVATION</span>
                      </div>
                      <Card.Text style={{ color: '#585757', fontSize: '14px', textAlign: 'justify' }}>
                        We foster a culture of innovation to drive the development of cutting-edge driveline products that meet
                        the ever-evolving needs of our global customers.
                      </Card.Text>
                      <div className="d-flex justify-content-center ">
                        {/* <Button style={{ background: '#4CB5E2', border: 'none' }}>Read More </Button> */}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className='d-flex justify-content-center'>
                  <Card style={{ width: '18rem', margin: '10px', border: 'none', background: "transparent" }} >
                    <Card.Body>
                      <div>
                        <div className='d-flex'>
                          <img src={require('../Assets/images/icons-responsibility.png')} alt="responsibility" style={{ height: '70px' }}></img>
                          <span style={{ fontSize: '20px', fontWeight: '500', marginTop: '20px' }} >RESPONSIBILITY</span>
                        </div>

                      </div>
                      <Card.Text style={{ color: '#585757', fontSize: '14px', textAlign: 'justify' }}>
                        As a socially responsible organization, we are committed to conducting business with the highest ethical
                        standards, ensuring the safety of our employees.
                      </Card.Text>
                      <div className="d-flex justify-content-center ">
                        {/* <Button style={{ background: '#4CB5E2', border: 'none' }}>Read More </Button> */}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>


            </div>
            {/* Call To Action End */}
            {/* About Us */}
            <div className='d-flex justify-content-center'>
              <Row className='d-flex justify-content-center mt-5 col-10'>
                <Col sm={12} lg={6} >
                  <img src={require("../Assets/images/newimg.png")} alt="about1" />
                </Col>
                <Col sm={12} lg={6}>
                  <h2 className="title mt-4 text-lg-start" style={{ fontSize: '30px' }}> Why Adroit</h2>
                  <div >
                    <img src={WhyAdroit} style={{ width: "" }} alt="milestone"></img>
                  </div></Col>
              </Row>
            </div>
            {/* <div className="section-full   wow fadeIn" data-wow-duration="2s" data-wow-delay="0.4s" id="about-us" style={{ paddingTop: '80px' }}>
              <div className="container">
                <div className="row about-bx3" data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                  <div className="col-lg-5 col-md-12 m-b30" >
                    <div className="about-img-box">
                      <div className="about-text">
                        <p>Since -1966</p>
                      </div>
                      <div className='responsive-img' >
                        <img src={require('../../src/Assets/images/about_1.png')} alt="about"  ></img>
                      </div>
                      <div className="about-img-one ">
                        <img src={require("../Assets/images/newimg.png")} alt="about1" />
                      </div>
                
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-12" >
                    <div className="about-contact-box" >
                      <div className="dlab-tabs tabs-site-button">
                        <div className="tab-content">
                          <div id="web-design-1" className="tab-pane active">
                            <div className="about-inner-content ml-3" >
                              <h2 className="rotate-text" style={{ color: '#A9ADB0' }}>Why Adroit</h2>
                              <h2 className="title mt-4 text-lg-start" style={{ fontSize: '30px' }}> Why Adroit</h2>
                              <div >
                                <img src={WhyAdroit} style={{ width: "100vw" }} alt="milestone"></img>
                              </div>
                            </div>
                          </div>
                          <div id="graphic-design-1" className="tab-pane">
                            <div className="about-inner-content">
                              <h2 className="rotate-text">Tradezone</h2>
                              <div className="m-b30">
                                <img src="./Assets/images/about/construct/logo.png" alt="" />
                              </div>
                              <h2 className="title">We design and build innovative platforms for inspiring brand</h2>
                              <p className="m-b20">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                              <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                            </div>
                          </div>
                          <div id="developement-1" className="tab-pane">
                            <div className="about-inner-content">
                              <h2 className="rotate-text">ABOUT History</h2>
                              <div className="m-b30">
                              
                              </div>
                              <h2 className="title">We design and build innovative platforms for inspiring brand</h2>
                              <p className="m-b20">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                              <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</p>
                            </div>
                          </div>
                        </div>
                    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* About Us End */}
            {/* Services */}
            <div className="row mt-3  ms-0 me-0" data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
              <div className="col-lg-12 section-head text-center">
                <h5 className="title" style={{ fontSize: '30px' }}>Our technology</h5>
                <div className="dlab-separator-outer">
                  <div className="dlab-separator bg-primary style-skew" />
                </div>
                {/* <h2 className="font-weight-700 m-b0">What Defines Adroit</h2> */}
              </div>
            </div>
            <div className='d-flex justify-content-center ' data-aos="zoom-in-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
              <Row style={{ background: '#f2f7fa' }}>
                <Col className='d-flex justify-content-center'>
                  <Card style={{ width: '18rem', margin: '10px', border: 'none', background: "transparent" }} >
                    <Card.Body>
                      <div className='d-flex'>
                        <img src={require('../Assets/images/engeneering_content.png')} style={{ height: '70px' }} alt="trust"></img>
                        <span style={{ fontSize: '20px', fontWeight: '500', marginTop: '20px' }} >Engineering</span>
                      </div>
                      <Card.Text style={{ color: '#585757', fontSize: '14px', textAlign: 'justify' }}>
                        We leverage cutting-edge technology and advanced manufacturing processes to engineer high-performance driveline
                        products for diverse industries and applications.&nbsp;<Link to='/engineering' style={{ textDecoration: 'none' }} ><span style={{ fontWeight: "bold", color: '#00BFFF', }}>Read More</span></Link>
                      </Card.Text>
                      <div className="d-flex justify-content-center ">
                        {/* <Button style={{ background: '#4CB5E2', border: 'none' }}>Read More </Button> */}
                      </div>

                    </Card.Body>
                  </Card>
                </Col>
                <Col className='d-flex justify-content-center'>
                  <Card style={{ width: '18rem', margin: '10px', border: 'none', background: "transparent" }} >
                    <Card.Body className=''>
                      <div className='d-flex'>
                        <img src={require('../Assets/images/quality_content.png')} style={{ height: '70px' }} alt="quality"></img>
                        <span style={{ fontSize: '20px', fontWeight: '500', marginTop: '20px' }} >Quality</span>
                      </div>
                      <Card.Text style={{ color: '#585757', fontSize: '14px', textAlign: 'justify' }}>
                        Quality is at the core of everything we do - from design and engineering to manufacturing and customer support - ensuring that our driveline products meet or exceed industry standards.
                        &nbsp;<Link to='/quality' style={{ textDecoration: 'none' }}><span style={{ fontWeight: "bold", color: '#00BFFF', }}>Read More</span></Link>
                      </Card.Text>
                      <div className="d-flex justify-content-center ">
                        {/* <Button style={{ background: '#4CB5E2', border: 'none' }}>Read More </Button> */}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className='d-flex justify-content-center'>

                  <Card style={{ width: '18rem', margin: '10px', border: 'none', background: "transparent" }} >
                    <Card.Body>
                      <div className='d-flex'>
                        <img src={require('../Assets/images/manufacturing_content.png')} style={{ height: '70px' }} alt="innovation"></img>
                        <span style={{ fontSize: '20px', fontWeight: '500', marginTop: '20px' }} >Manufacturing</span>
                      </div>
                      <Card.Text style={{ color: '#585757', fontSize: '14px', textAlign: 'justify' }}>
                        Our world-class manufacturing facilities and processes enable us to produce high-quality
                        driveline products with precision and efficiency, ensuring timely delivery to our customers.
                        &nbsp;<Link to='/manufacture' style={{ textDecoration: 'none' }}><span style={{ fontWeight: "bold", color: '#00BFFF', }}>Read More</span></Link>
                      </Card.Text>
                      <div className="d-flex justify-content-center ">
                        {/* <Button style={{ background: '#4CB5E2', border: 'none' }}>Read More </Button> */}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>

              </Row>


            </div>
            {/* <div className="section-full content-inner-1 services-box-area bg-white" id="services" style={{ background: 'linear-gradient(45deg, #a9adb0, transparent)', marginBottom: '100px' }}>
              <div className="container">
                <div className="section-head text-center" data-aos="zoom-in-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600" >
                  <h5 style={{ fontSize: '30px' }}>OUR TECHNOLOGY</h5>
                  <div className="dlab-separator-outer">
                    <div className="dlab-separator bg-primary style-skew" />
                  </div>
                  <h2 className="title mt-3">Meet The Team</h2>
                </div>
                <div>
                  <div className="row m-lr0" style={{ marginTop: '-100px', }}>
                    <div className="col-lg-4 col-md-4 p-lr0"  >
                      <div className="icon-bx-wraper services-box">
                        <div className="icon-content">
                          <div className="icon-lg m-b30">
                            <a href="#" className="icon-cell icon-up"><img src={require("../Assets/images/icons8-engineering.png")} alt="engineering" /></a>
                          </div>
                          <h3 style={{fontSize:'25px'}}>Engineering</h3>
                          <p style={{ color: '#585757', fontSize: '14px',  }}>We leverage cutting-edge technology and advanced manufacturing processes to engineer high-performance driveline
                            products for diverse industries and applications.</p>
                          <Link to='/engineering' ><p style={{ fontWeight: "bold" }}>Read More</p></Link>
                        </div>
                        <div className="d-flex justify-content-center ">
                          <Button style={{ background: '#4CB5E2', border: 'none' }}>Read More </Button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 p-lr0">
                      <div className="icon-bx-wraper services-box">
                        <div className="icon-content">
                          <div className="icon-lg m-b30">
                            <a href="#" className="icon-cell icon-up"><img src={require("../Assets/images/icons8-manufacturing.png")} alt="" /></a>
                          </div>
                          <h3 style={{ fontSize: '25px' }} >Manufacturing</h3>
                          <p style={{ color: '#585757', fontSize: '14px', }}>Our world-class manufacturing facilities and processes enable us to produce high-quality
                            driveline products with precision and efficiency, ensuring timely delivery to our customers.</p>
                          <Link to='/manufacture' ><p style={{ fontWeight: "bold" }}>Read More</p></Link>
                          <div className="d-flex justify-content-center ">
                            <Button style={{ background: '#4CB5E2', border: 'none' }}>Read More </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 p-lr0" >
                      <div className="icon-bx-wraper services-box">
                        <div className="icon-content">
                          <div className="icon-lg m-b30">
                            <a href="#" className="icon-cell icon-up"><img src={require("../Assets/images/icons8-guarantee.png")} alt="" /></a>
                          </div>
                          <h3 style={{ fontSize: '25px' }}>Quality</h3>
                          <p style={{ color: '#585757', fontSize: '14px', }}>Quality is at the core of everything we do - from design and engineering to manufacturing and customer support - ensuring that our driveline products meet or exceed industry standards.</p>
                          <Link to='/quality' ><p style={{ fontWeight: "bold" }}>Read More</p></Link>
                          <div className="d-flex justify-content-center ">
                            <Button style={{ background: '#4CB5E2', border: 'none' }}>Read More </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* Services End */}
            {/* Our Services */}
            <div className="section-full  bg-img-fix mt-3" id="projects" style={{ background: 'transparent' }}>
              <div className="container ">
                <div className="row" data-aos="fade-up" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                  <div className="col-lg-12 section-head mt-4" >
                    <h5 className="title" style={{ fontSize: '30px' }} >OUR PRODUCT</h5>
                    <div className="dlab-separator-outer">
                      <div className="dlab-separator bg-primary style-skew" />
                    </div>

                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center '>
                <Row className='col-10'>
                  <Col sm={12} md={12} lg={4}>
                    <Link to={{ pathname: "/ourproduct", hash: "#splined" }}>
                      <img src={require('../Assets/images/spliend_stub_shaft_content.png')}></img>
                    </Link>
                  </Col>
                  <Col sm={12} md={12} lg={4}>
                    <Link to={{ pathname: "/ourproduct", hash: "#cardenshaft" }}>
                      <img src={require('../Assets/images/propeller_shaft_content.png')}></img>
                    </Link>
                  </Col>
                  <Col sm={12} md={12} lg={4}>
                    <Link to={{ pathname: "/ourproduct", hash: "#tubeyoke" }}>
                      <img src={require('../Assets/images/tube_yoke_content.png')}></img>
                    </Link>
                  </Col>
                </Row>
              </div>
              <div className='d-flex justify-content-center mb-5'>
                <Row className='col-10'>
                  <Col sm={12} md={12} lg={4}>
                    <Link to={{ pathname: "/ourproduct", hash: "#universaljoint" }}>
                      <img src={require('../Assets/images/universal_joint_content.png')}></img>
                    </Link>
                  </Col>
                  <Col sm={12} md={12} lg={4}>
                    <Link to={{ pathname: "/ourproduct", hash: "#flangeyoke" }}>
                      <img src={require('../Assets/images/flange_yoke_content.png')}></img>
                    </Link>
                  </Col>
                  <Col sm={12} md={12} lg={4}>
                    <Link to={{ pathname: "/ourproduct", hash: "#centebearing" }}>
                      <img src={require('../Assets/images/center_bearing_content.png')}></img>
                    </Link>
                  </Col>
                </Row>
              </div>
              {/* <div className="row spno " style={{ background: 'transparent' }} data-aos="zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                <div className="col-lg-3 col-md-6 col-sm-6 col-6" >
                  <div className="dlab-box dlab-portfolio-bx">
                    <Link to={{ pathname: "/ourproduct", hash: "#splined" }}>
                      <div className="dlab-media">
                        <a href="javascript:void(0);"> <img src={require('../../src/Assets/images/stub.jpeg')} alt="" /> </a>
                        <div className="overlay-bx">
                          <div className="port-box align-b">
                            <h5 className="title text-white">
                            
                              Splined Stub Shaft
                            </h5>
                          </div>
                        </div>
                      </div></Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-6"  >
                  <div className="dlab-box dlab-portfolio-bx">
                    <Link to={{ pathname: "/ourproduct", hash: "#cardenshaft" }}><div className="dlab-media">
                      <a href="javascript:void(0);"> <img src={require('../../src/Assets/images/product02.jpeg')} alt="" style={{ height: "255px" }} /> </a>
                      <div className="overlay-bx">
                        <div className="port-box align-b">
                          <h5 className="title text-white" >
                            Propeller shafts/ Cardan Shaft
                          </h5>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-4 col-12">
                  <div className="dlab-box dlab-portfolio-bx">
                    <Link to={{ pathname: "/ourproduct", hash: "#tubeyoke" }}>
                      <div className="dlab-media">
                        <a href="javascript:void(0);"> <img src={require('../../src/Assets/images/tubeyoke.jpeg')} alt="" style={{ height: "255px" }} /> </a>
                        <div className="overlay-bx">
                          <div className="port-box align-b">
                            <h5 className="title text-white">
                              Tube Yokes
                            </h5>

                          </div>
                        </div>
                      </div></Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                  <div className="dlab-box dlab-portfolio-bx">
                    <Link to={{ pathname: "/ourproduct", hash: "#yokeshaft" }}> <div className="dlab-media">
                      <a href="javascript:void(0);"> <img src={require('../../src/Assets/images/yokeshaft.jpeg')} alt="" /> </a>
                      <div className="overlay-bx">
                        <div className="port-box align-b">
                          <h5 className="title text-white">
                            Yoke Shaft
                          </h5>

                        </div>
                      </div>
                    </div></Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                  <div className="dlab-box dlab-portfolio-bx">
                  <Link to={{ pathname: "/ourproduct", hash: "#universaljoint" }}>
                    <div className="dlab-media">
                      <a href="javascript:void(0);"> <img src={require('../../src/Assets/images/product05.jpeg')} alt="" style={{ height: "255px" }} /> </a>
                      <div className="overlay-bx">
                        <div className="port-box align-b">
                          <h5 className="title text-white">
                            Universal Joints
                          </h5>

                        </div>
                      </div>
                    </div></Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-4 col-6">
                  <div className="dlab-box dlab-portfolio-bx">
                  <Link to={{ pathname: "/ourproduct", hash: "#flangeyoke" }}>
                    <div className="dlab-media">
                      <a href="javascript:void(0);"> <img src={require('../../src/Assets/images/flang_yoke.jpeg')} alt="" style={{ height: "255px" }} /> </a>
                      <div className="overlay-bx">
                        <div className="port-box align-b">
                          <h5 className="title text-white">
                           
                           Flange Yoke

                          </h5>

                        </div>
                      </div>
                    </div></Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-4 col-12">
                  <div className="dlab-box dlab-portfolio-bx">
                  <Link to={{ pathname: "/ourproduct", hash: "#centebearing" }}><div className="dlab-media">
                      <a href="javascript:void(0);"> <img src={require('../../src/Assets/images/centre-bearing.png')} alt="" style={{ height: "255px" }} /> </a>
                      <div className="overlay-bx">
                        <div className="port-box align-b">
                          <h5 className="title text-white">
                            Center Bearing

                          </h5>

                        </div>
                      </div>
                    </div></Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-4 col-6">
                  <div className="dlab-box dlab-portfolio-bx">
                  <Link to={{ pathname: "/ourproduct", hash: "#slipyoke" }}><div className="dlab-media">
                      <a href="javascript:void(0);"> <img src={require('../../src/Assets/images/slipyoke.jpeg')} alt="" style={{ height: "255px" }} /> </a>
                      <div className="overlay-bx">
                        <div className="port-box align-b">
                          <h5 className="title text-white">
                            Slip Yokes

                          </h5>

                        </div>
                      </div>
                    </div></Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>


        </div>
      </div>
      {/* Content END */}
      {/* <ChatBox /> */}
    </div>
  )
}

export default Content