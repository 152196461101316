// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { IoIosArrowForward } from "react-icons/io";
// import { BsGrid, BsCartPlus } from "react-icons/bs";
// import { GiHamburgerMenu } from "react-icons/gi";
// import { HiInboxIn } from "react-icons/hi"
// import { TbBox } from "react-icons/tb"
// import { SlSocialDropbox } from "react-icons/sl"
// import { RiDeleteBin6Line } from "react-icons/ri"
// import { Col } from "react-bootstrap";
// import { Card, Input, Row, Button } from "reactstrap";
// import { toast } from "react-toastify";
// import { useDispatch } from "react-redux";
// import axios from "axios";
// import { updateCartQty } from "../../../Redux/feature/cartSlice";
// import BulkExcel from "./BulkExcel";
// import { Modal } from "react-bootstrap";
// import BulkTable from "./BulkTable";
// import BulkConfirm from "./BulkConfirm";
// function BulkImport() {
//     const dispatch = useDispatch()
//     let navigate = useNavigate()
//     const [show, setShow] = useState(false);
//     let [views, setViews] = useState({})
//     const [ProductList, setProductList] = useState([])
//     let [loading, setLoading] = useState(false)
//     const [cartList, setCartList] = useState([])
//     const [compareProd, setCompareProd] = useState([])
//     const [stepModal, setStepmodal] = useState(1)
//     useEffect(() => {
//         productListData();
//     }, [])
//     const getCartlist = () => {
//         setLoading(true);
//         if (localStorage.getItem('token')) {
//             let url = process.env.REACT_APP_BASEURL_TWO + 'cart/';
//             const config = {
//                 headers: {
//                     Authorization: localStorage.getItem('token'),
//                     'Access-Control-Allow-Origin': '*',
//                     'Content-type': 'application/json',
//                 },
//             };
//             axios
//                 .get(url, config)
//                 .then((res) => {
//                     setLoading(false);
//                     setCartList(res.data.data.item_list);
//                     dispatch(updateCartQty(res.data.data.item_list.length))


//                 })
//                 .catch((err) => {
//                     setLoading(false);
//                     console.log(err);
//                 });
//         } else {
//             navigate('/home');
//             toast.error('Your session has expired. Please login again.', {
//                 autoClose: 1000,
//                 theme: 'colored',
//             });
//         }
//     };
//     const productListData = () => {
//         setLoading(true)
//         if (localStorage.getItem('token')) {
//             let url = process.env.REACT_APP_BASEURL_TWO + `product_list/?search=&category_id=2332214712225481&series=1000&universal=`
//             const config = {
//                 headers: {
//                     "Authorization": localStorage.getItem("token"),
//                     "Access-Control-Allow-Origin": "*",
//                     "Content-type": "application/json",
//                 }
//             };
//             axios.get(url, config)
//                 .then(res => {
//                     setLoading(false)
//                     setProductList(res.data.data)
//                     // console.log(res.data.data)
//                 }
//                 ).catch(err => {
//                     // console.log(err)
//                     setLoading(false)
//                 })
//         }
//         else {
//             navigate('/home');
//             toast.error(' your session has been expired login again.', {
//                 autoClose: 1000,
//                 theme: "colored",
//             });
//         }
//     }

//     const checkboxClick = (item, e) => {
//         if (e.target.checked === true) {

//             setCompareProd((compareProd) => [...compareProd, item])
//             console.log(compareProd)

//         }
//         console.log(e.target.checked)

//     }
//     const checkUserCompare = () => {
//         console.log('runnnn')
//         // setLoading(true)
//         const compareData = compareProd.map((obj) => ({
//             'product_id': obj.public_id.toString(),
//             'quantity': 30,

//         }));

//         // setSendPublicId(compareData)
//         console.log(compareData)
//         let url = process.env.REACT_APP_BASEURL_TWO + 'cart/';
//         const config = {
//             headers: {
//                 Authorization: localStorage.getItem('token'),
//                 'Access-Control-Allow-Origin': '*',
//                 'Content-type': 'application/json',
//             },
//         };
//         const body = {
//             cart_data: compareData
//         };
//         axios
//             .post(url, body, config)
//             .then((res) => {
//                 // setLoading(false);
//                 getCartlist();
//                 navigate('/showallcart')
//                 console.log('sucess')
//             })
//             .catch((err) => {
//                 console.log('errrrr', err);
//                 // setLoading(false);
//             })

//     }
//     return (<>
//         <div className="d-flex justify-content-between col-10 mt-5" style={{ paddingLeft: "50px" }}>
//             <div style={{ color: '#797979', width: '200px', textAlign: 'left' }}>Home <IoIosArrowForward style={{ color: '#008ECC' }} /><span style={{ color: '#0F328E' }}> Bulk Import</span></div>
//             {/* <div style={{ display: 'flex', }}>View as <BsGrid style={{ color: '#4CB5E2', marginTop: "6px", marginLeft: '5px' }} /> <GiHamburgerMenu style={{ color: '#4CB5E2', marginTop: "5px", marginLeft: '5px', fontSize: '18px' }} /> </div> */}
//         </div>
//         <Row className=" mt-3 ps-4 pe-1" style={{ fontFamily: 'roboto' }}>
//             <Col lg={3} className='mt-3' >
//                 <div className="mb-3 d-flex justify-content-start ms-2"><span style={{ border: '1px solid #EDEDED', padding: '4px 10px 4px 10px', margin: '3px' }}><HiInboxIn />  Create List</span> <span style={{ border: '1px solid #EDEDED', padding: '4px 10px 4px 10px', margin: '3px' }}><TbBox /> Manage List</span></div>
//                 <aside className="card" style={{ height: "80vh" }}>
//                     <ul style={{ listStyleType: 'none', textAlign: 'left', marginLeft: '-20px' }}>
//                         <li className="me-2 mt-2"><Input type="text" placeholder="Search List" > </Input></li>
//                         <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px' }}>PO 3984(20)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
//                         <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px' }}>PO 3950(25)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
//                         <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px' }}>PO 3930(20)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
//                         <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px' }}>PO 3948 Stock Order(20)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
//                         <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px' }}>Jan 24 Stock Order(28)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
//                         <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px' }}>Jan 20 Stock Order(50)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
//                         <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px' }}>Feb 15 Stock Order(50)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
//                         <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px' }}>Feb 20 Stock Order(60)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>

//                     </ul>

//                 </aside>
//             </Col>
//             <Col lg={7} className='ps-4 mt-2 pe-1'>
//                 <Row style={{ background: '#F4F4F4', padding: '12px', boxShadow: '0px 6px 4px 1px grey' }}>
//                     <Row className=" mb-2 d-flex justify-content-start ">
//                         <Col className="d-flex justify-content-start" sm={12} lg={3} onClick={() => setShow(true)}>  <span style={{ border: '1px solid #EDEDED', padding: '4px 10px 4px 10px', background: 'white', cursor: 'pointer' }}><SlSocialDropbox /> Add/Upload Parts</span> </Col>
//                         <Modal show={show} onHide={() => { setShow(false) }} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
//                             <Modal.Header closeButton style={{ backgroundColor: '#fff' }}>
//                             </Modal.Header>
//                             <Modal.Body closeButton>
//                                 {stepModal === 1 ? <BulkExcel sendstep={setStepmodal} /> : stepModal === 2 ?
//                                     <BulkTable sendstep2={setStepmodal} /> : stepModal === 3 ?
//                                         <BulkConfirm /> : "null"}
//                             </Modal.Body>

//                         </Modal>
//                         <Col></Col>
//                         {/* <Col className="d-flex justify-content-start" sm={12} lg={3}> <span><Input type="text" placeholder="Search Part No/Part Name" className="ms-2 me-2" /></span>   </Col> */}
//                         <Col className="d-flex justify-content-start" sm={12} lg={3}>  <span style={{ border: '1px solid #EDEDED', padding: '4px 10px 4px 10px', background: 'white', marginLeft: '20px', cursor: 'pointer' }} ><RiDeleteBin6Line /> Remove</span>    </Col>
//                         <Col className="d-flex justify-content-start" style={{ cursor: 'pointer' }} sm={12} lg={3} onClick={() => { checkUserCompare() }}> <span style={{ border: '1px solid #EDEDED', padding: '4px 10px 4px 10px', background: 'white', marginLeft: '20px' }} ><BsCartPlus /> Add to Cart</span>    </Col>
//                     </Row>
//                     <Card className="p-2 d-flex flex-column flex-wrap" style={{ border: 'none' }} >
//                         <Row>

//                             {productList.length === 0 ? <div className="d-flex justify-content-center mx-auto mt-5"><div className="text-center fs-4 fw-bold">No Data Found</div></div> :
//                                 productList.map((item) => {
//                                     return (<>

//                                         <Col lg={6}>
//                                             <Card className="m-2">
//                                                 <Row>
//                                                     <Col lg={3} style={{ backgroundColor: '#DDF1FA' }} className='py-2 ps-2'><Input type="checkbox" style={{ border: "1px solid #4CB5E2", }} onChange={(e) => { checkboxClick(item, e) }}></Input>
//                                                         <img src={item.images} className='mt-2 ' style={{ width: '90%' }} alt="img"></img>
//                                                     </Col>
//                                                     <Col lg={8} className='py-2 ms-3' style={{ textAlign: 'left' }} ><span style={{ color: '#0F328E', fontWeight: 'bold' }}>{item.part_number}</span><br></br>
//                                                         <span className="ms-1">Qty</span>   <span className="ms-4">Req Ship Date</span><br></br>
//                                                         <div className="py-2"><span style={{ border: '1px solid #D9D9D9', padding: '2px 18px 2px 18px' }}>30</span>   <span style={{ border: '1px solid #D9D9D9', padding: '2px 20px 2px 20px' }}>01/23/2023</span></div>
//                                                     </Col>
//                                                 </Row>
//                                             </Card>
//                                         </Col>


//                                     </>)
//                                 })
//                             }
//                         </Row>

//                     </Card>
//                 </Row>

//             </Col>
//         </Row>
//     </>)
// }
// export default BulkImport;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { BsGrid, BsCartPlus } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import { HiInboxIn } from "react-icons/hi"
import { TbBox } from "react-icons/tb"
import { SlSocialDropbox } from "react-icons/sl"
import { RiDeleteBin6Line } from "react-icons/ri"
import { Col } from "react-bootstrap";
import { Card, Input, Row, Button } from "reactstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import axios from "axios";
import { updateCartQty } from "../../../Redux/feature/cartSlice";
import BulkExcel from "./BulkExcel";
import { Modal } from "react-bootstrap";
import BulkTable from "./BulkTable";
import BulkConfirm from "./BulkConfirm";
function BulkImport(props) {
    const dispatch = useDispatch()
    let navigate = useNavigate()
    const [show, setShow] = useState(false);
    let [views, setViews] = useState({})
    const [productList, setProductList] = useState([])
    let [loading, setLoading] = useState(false)
    const [cartList, setCartList] = useState([])
    const [compareProd, setCompareProd] = useState([])
    const [stepModal, setStepmodal] = useState(1)
    const [bulklist, setBulklist] = useState([])
    const [deleteCartLoading, setDeleteCartLoading] = useState({})
    const [needsReload, setNeedsReload] = useState(false)
    // const [loading, setLoading] = useState(false);

    useEffect(() => {
        getbulkcart()
    }, [needsReload]);

    const getbulkcart = () => {
        if (localStorage.getItem('token')) {
            let url = process.env.REACT_APP_BASEURL_TWO + 'bulk-import/';
            const config = {
                headers: {
                    Authorization: localStorage.getItem('token'),
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                },
            };
            axios
                .get(url, config)
                .then((res) => {
                    // setLoading(false);
                    setBulklist(res.data.data);
                    console.log("fdsfsdfsd", res.data.data)
                    setNeedsReload(true)
                    // dispatch(updateCartQty(res.data.data.item_list.length))
                    console.log(res.data.data.item_list)
                })
                .catch((err) => {
                    // setLoading(false);
                    console.log(err);
                });
        } else {

            toast.error('Your session has expired. Please login again.', {
                autoClose: 1000,
                theme: 'colored',
            });
        }
    };

    const deleteCart = (publicIds) => {
        // Here we'll use Promise.all to make multiple delete requests concurrently
        Promise.all(
            publicIds.map((public_id) => {
                // Set loading state for each item to true
                setDeleteCartLoading((prevCartLoading) => ({
                    ...prevCartLoading,
                    [public_id]: true,
                }));

                return axios.delete(
                    process.env.REACT_APP_BASEURL_TWO + `bulk-import-remove/${public_id}/`,
                    {
                        headers: {
                            Authorization: localStorage.getItem('token'),
                            'Access-Control-Allow-Origin': '*',
                            'Content-type': 'application/json',
                        },
                    }
                );
            })
        )
            .then((responses) => {
                // All delete requests are successful
                console.log('All items deleted successfully');
                setLoading(false);
                setNeedsReload(true);
                getbulkcart();
            })
            .catch((error) => {
                // Handle error if any of the delete requests fails
                console.error('Error deleting items:', error);
                setLoading(false);
            })
            .finally(() => {
                // Reset the loading state for all items after deletion
                publicIds.forEach((public_id) => {
                    setDeleteCartLoading((prevCartLoading) => ({
                        ...prevCartLoading,
                        [public_id]: false,
                    }));
                });
            });
    };

    const handleRemoveClick = () => {
        // Get the public_ids of selected items to remove
        const selectedItems = bulklist.filter((item) => compareProd.includes(item));
        const publicIdsToRemove = selectedItems.map((item) => item.public_id);
        // Call the modified deleteCart function with the public_ids to remove
        deleteCart(publicIdsToRemove);
    };

    const checkboxClick = (item, e) => {
        if (e.target.checked === true) {
            setCompareProd((compareProd) => [...compareProd, item])
            console.log(compareProd)
        }
        console.log(e.target.checked)

    }
    const checkUserCompare = () => {
        console.log('runnnn')
        // setLoading(true)
        const compareData = compareProd.map((obj) => ({
            'product_id': parseInt(obj.public_id),
            'quantity': parseInt(obj.quantity),

        }));
        console.log("compareProd:", compareProd);
        console.log("compareData:", compareData);
        // setSendPublicId(compareData)
        console.log(compareData)
        let url = process.env.REACT_APP_BASEURL_TWO + 'cart/';
        const config = {
            headers: {
                Authorization: localStorage.getItem('token'),
                'Access-Control-Allow-Origin': '*',
                'Content-type': 'application/json',
            },
        };
        const body = {
            cart_data: compareData
        };
        axios
            .post(url, body, config)
            .then((res) => {
                // setLoading(false);
                
                navigate('/showallcart')
                console.log('sucess')
            })
            .catch((err) => {
                console.log('errrrr', err);
                // setLoading(false);
            })

    }

    return (<>
        <div className="d-flex justify-content-between col-10 mt-5" style={{ paddingLeft: "50px" }}>
            <div style={{ color: '#797979', width: '200px', textAlign: 'left' }}>Home <IoIosArrowForward style={{ color: '#008ECC' }} /><span style={{ color: '#0F328E' }}> Bulk Import</span></div>
            {/* <div style={{ display: 'flex', }}>View as <BsGrid style={{ color: '#4CB5E2', marginTop: "6px", marginLeft: '5px' }} /> <GiHamburgerMenu style={{ color: '#4CB5E2', marginTop: "5px", marginLeft: '5px', fontSize: '18px' }} /> </div> */}
        </div>
        <Row className=" mt-3 ps-4 pe-1" style={{ fontFamily: 'roboto' }}>
            <Col lg={3} className='mt-3' >
                <div className="mb-3 d-flex justify-content-start ms-2"><span style={{ border: '1px solid #EDEDED', padding: '4px 10px 4px 10px', margin: '3px' }}><HiInboxIn />  Create List</span> <span style={{ border: '1px solid #EDEDED', padding: '4px 10px 4px 10px', margin: '3px' }}><TbBox /> Manage List</span></div>
                <aside className="card" style={{ height: "80vh" }}>
                    <ul style={{ listStyleType: 'none', textAlign: 'left', marginLeft: '-20px' }}>
                        <li className="me-2 mt-2"><Input type="text" placeholder="Search List" > </Input></li>
                        <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px' }}>PO 3984(20)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
                        <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px' }}>PO 3950(25)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
                        <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px' }}>PO 3930(20)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
                        <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px' }}>PO 3948 Stock Order(20)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
                        <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px' }}>Jan 24 Stock Order(28)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
                        <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px' }}>Jan 20 Stock Order(50)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
                        <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px' }}>Feb 15 Stock Order(50)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>
                        <li className="mt-4  d-flex justify-content-between"><div style={{ fontSize: '13px' }}>Feb 20 Stock Order(60)</div> <div><IoIosArrowForward className="me-3" style={{ fontSize: '13px' }} /></div> </li>

                    </ul>

                </aside>
            </Col>
            <Col lg={7} className='ps-4 mt-2 pe-1'>
                <Row style={{ background: '#F4F4F4', padding: '12px', boxShadow: '0px 6px 4px 1px grey' }}>
                    <Row className=" mb-2 d-flex justify-content-start ">
                        <Col className="d-flex justify-content-start" sm={12} lg={3} onClick={() => setShow(true)}>  <span style={{ border: '1px solid #EDEDED', padding: '4px 10px 4px 10px', background: 'white', cursor: 'pointer' }}><SlSocialDropbox /> Add/Upload Parts</span> </Col>
                        <Modal show={show} onHide={() => { setShow(false) }} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header closeButton style={{ backgroundColor: '#fff' }}>
                            </Modal.Header>
                            <Modal.Body closeButton>
                                {/* {stepModal === 1 ? <BulkExcel sendstep={setStepmodal} /> : stepModal === 2 ?
                                    <BulkTable sendstep2={setStepmodal} /> : stepModal === 3 ?
                                        <BulkConfirm /> : "null"} */}

                                <BulkExcel closeModal={setShow} reload={setNeedsReload} />
                            </Modal.Body>

                        </Modal>
                        <Col></Col>
                        {/* <Col className="d-flex justify-content-start" sm={12} lg={3}> <span><Input type="text" placeholder="Search Part No/Part Name" className="ms-2 me-2" /></span>   </Col> */}
                        <Col className="d-flex justify-content-start" sm={12} lg={3}>  <span style={{ border: '1px solid #EDEDED', padding: '4px 10px 4px 10px', background: 'white', marginLeft: '20px', cursor: 'pointer' }} onClick={handleRemoveClick} ><RiDeleteBin6Line /> Remove</span>    </Col>
                        <Col className="d-flex justify-content-start" style={{ cursor: 'pointer' }} sm={12} lg={3} onClick={() => { checkUserCompare() }}> <span style={{ border: '1px solid #EDEDED', padding: '4px 10px 4px 10px', background: 'white', marginLeft: '20px' }} ><BsCartPlus /> Add to Cart</span>    </Col>
                    </Row>
                    <Card className="p-2 d-flex flex-column flex-wrap" style={{ border: 'none' }} >
                        <Row>

                            {bulklist.length === 0 ? <div className="d-flex justify-content-center mx-auto mt-5"><div className="text-center fs-4 fw-bold">No Data Found</div></div> :
                                bulklist.map((item) => {
                                    return (<>

                                        <Col lg={6}>
                                            <Card className="m-2">
                                                <Row>
                                                    <Col lg={3} style={{ backgroundColor: '#DDF1FA' }} className='py-2 ps-2'><Input type="checkbox" style={{ border: "1px solid #4CB5E2", }} onChange={(e) => { checkboxClick(item, e) }}></Input>
                                                        <img src={item.image} className='mt-2 ' style={{ width: '90%' }} alt="img"></img>
                                                    </Col>
                                                    <Col lg={8} className='py-2 ms-3' style={{ textAlign: 'left' }} ><span style={{ color: '#0F328E', fontWeight: 'bold' }}>{item.part_number}</span><br></br>
                                                        <span className="ms-1">Qty</span>   <span className="ms-4">Req Ship Date</span><br></br>
                                                        <div className="py-2"><span style={{ border: '1px solid #D9D9D9', padding: '2px 18px 2px 18px' }}>{item.quantity}</span>   <span style={{ border: '1px solid #D9D9D9', padding: '2px 20px 2px 20px' }}>01/23/2023</span></div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>


                                    </>)
                                })
                            }
                        </Row>

                    </Card>
                </Row>

            </Col>
        </Row>
    </>)
}
export default BulkImport;

