import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import Card from 'react-bootstrap/Card';
import { BsFillPlayFill } from 'react-icons/bs';
import ExcelDownload from './ExcelDownload';
const BulkExcel = ({ viewDetail, sendstep, closeModal, reload }) => {
    const ExcleExportData = [
        {
            part_number: "",
            quantity: ""
        }
    ]
    return (
        <>
            <Container style={{fontFamily:'roboto'}}>
                {/* <div className='d-flex justify-content-center mt-3'>
                        <div style={{ height: "18px", width: "18px", backgroundColor: '#249B3E', borderRadius: "50%", }}></div>
                        <div style={{ borderTop: '2px solid #bbb', height: "60px", width: "60px", marginTop: '8px' }}></div>
                        <div style={{ height: "18px", width: "18px", backgroundColor: '#bbb', borderRadius: "50%", }}></div>
                        <div style={{ borderTop: '2px solid #bbb', height: "60px", width: "60px", marginTop: '8px' }}></div>
                        <div style={{ height: "18px", width: "18px", backgroundColor: '#bbb', borderRadius: "50%", }}></div>
                    </div> */}

                <Row >
                    <p className='d-flex justify-content-center' style={{fontWeight:'500'}}>Download & Upload Excel File</p>
                    <Col lg={12}>
                        <div className='d-flex  mt-3'>
                            <ExcelDownload excelData={ExcleExportData} sendClose={closeModal} reloading={reload} fileName={"file"} />
                        </div>
                    </Col>

                </Row>
            </Container>
        </>
    )
}

export default BulkExcel
