import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { BiRupee } from 'react-icons/bi';
import { useNavigate, useParams } from "react-router";
import { Col } from "react-bootstrap";
import ProductSKeleton from "./skeleton/ProductSkeleton";
import { toast } from "react-toastify";
import PlaceholderImage from "../../Assets/images/image_placeholder.jpg"

function CategoryProduct() {
    let { id } = useParams()
    let navigate = useNavigate()
    let GoToProduct = () => {
        navigate('/productdetail')
    }
    const [productList, setProductList] = useState([])
    let [loading, setLoading] = useState(false)
    useEffect(() => {
        productListData();
    }, [])
    const productListData = () => {
        if (localStorage.getItem('token')) {
            setLoading(true)
            let url = process.env.REACT_APP_BASEURL_TWO + `category/${id}/products/`
            const config = {
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                }
            };
            axios.get(url, config)
                .then(res => {
                    setLoading(false)
                    setProductList(res.data.data)
                    // console.log(res.data.data)
                }
                ).catch(err => {
                    setLoading(false)
                })
        }
        else {
            navigate('/home')
            toast.error(' your session has been expired login again.', {
                autoClose: 1000,
                theme: "colored",
            });
        }
    }

    return (
        <>
            <div style={{ marginTop: '50px' }}>

                {loading ? <div> <ProductSKeleton /> <ProductSKeleton /> </div> : <div className='row  col-10' style={{ paddingLeft: "50px" }}>
                    <div class=" flex-wrap d-flex justify-content-start " >
                        {productList.length === 0 ? <div className="d-flex justify-content-center mx-auto mt-5"><div className="text-center fs-4 fw-bold">No Data Found</div></div> :
                            productList.map((prdouct) => {
                                return (<>
                                    <Card onClick={() => navigate(`/productdetail/${prdouct.public_id}`)} style={{ width: '230px', height: 'auto', borderColor: '#A9ADB0', borderRadius: '16px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)", margin: '10px', }} >
                                        {/* <div className="boxheight" style={{ position: 'absolute', background: '#0F328E', width: '50px', top: '0px', right: "0px", borderBottomLeftRadius: "10px", borderTopRightRadius: "10px", justifyContent: "center", alignItems: "center" }}>
                                            <span style={{ color: 'white', fontSize: '14px' }}>20% OFF</span>
                                        </div> */}
                                        <div style={{ background: '#F5F5F5', borderRadius: '16px' }}>
                                            <Card.Img variant="top" src={prdouct.images==="" ?PlaceholderImage:prdouct.images} style={{ background: '#F5F5F5', height: 'auto', width: 'auto', borderRadius: '16px', }} />
                                        </div>
                                        <Card.Body>
                                            <Card.Title style={{ color: '#0F328E', textAlign: 'left', marginTop: '10PX', fontSize: '16px' }}>{prdouct.part_number}</Card.Title>
                                            <Card.Text style={{ color: 'grey', textAlign: 'left', fontSize: '13px', lineHeight: '22px' }}>
                                                {prdouct.description}
                                                <div className='d-flex justify-content-between mt-1' >
                                                    <h5 style={{ textAlign: 'left', fontSize: '18px' }}><BiRupee />{prdouct.product_price}</h5>
                                                    {/* <h5 style={{ color: 'green', fontSize: '16px' }}>Save-<BiRupee />{Math.floor(Math.random() * 10000)}</h5> */}
                                                </div>
                                                <hr style={{ marginTop: "-3px" }}></hr>
                                            </Card.Text>
                                            {/* <div className='d-flex justify-content-between' style={{ marginTop: "-3px" }}>
                                                <h5 style={{ color: 'red', fontSize: '16px' }}>In stock</h5>
                                                <h5 style={{ color: 'green', fontSize: '16px' }}>Save-<BiRupee />42000</h5>
                                            </div> */}
                                            <div className='d-flex justify-content-center mt-1 '>
                                                <Button onClick={() => navigate(`/productdetail/${prdouct.public_id}`)} style={{ background: '#0F328E', borderColor: '#0F328E' }}><AiOutlineShoppingCart /> &nbsp;Add to cart</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>


                                </>)
                            })
                        }
                    </div>
                </div>}
            </div>

        </>
    )
}
export default CategoryProduct;