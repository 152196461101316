import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { AiOutlineShoppingCart, AiOutlineHeart } from "react-icons/ai";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import StarRatings from "react-star-ratings";
import { Input } from "reactstrap";
import Productimg from '../../Assets/images/product_1.png';
import SleeveYolk1 from '../../Assets/images/sleevyoke1.PNG';
import SleeveYolk2 from '../../Assets/images/sleevyoke2.PNG';
import Sleevyoke3 from '../../Assets/images/sleevyoke3.PNG';
import Endyoke1 from '../../Assets/images/endyoke1.PNG';
import Endyoke2 from '../../Assets/images/endyoke2.PNG';
import Endyoke3 from '../../Assets/images/endyoke3.PNG';
import Endyoke4 from '../../Assets/images/endyoke4.PNG';
import Slipshaft from '../../Assets/images/slipshaft1.PNG';
import FlangeYoke1 from '../../Assets/images/flangeyoke1.PNG';
import FlangeYoke2 from '../../Assets/images/flangeyoke2.PNG';
import FlangeYoke3 from '../../Assets/images/flangeyoke3.PNG';
import CVFlangeyoke from '../../Assets/images/cvflangeyoke.PNG';
import YokeShaft1 from '../../Assets/images/yokeshaft1.PNG';
import YokeShaft2 from '../../Assets/images/yokeshaft2.PNG';
import YokeShaft3 from '../../Assets/images/yokeshaft3.PNG';
import MidShaft1 from '../../Assets/images/midshaft1.PNG';
import MidShaft2 from '../../Assets/images/midshaft2.PNG';
import Splinedsleeve from '../../Assets/images/splinedsleeve.PNG';
import TubeYoke1 from '../../Assets/images/tubeyoke1.PNG';
import TubeYoke2 from '../../Assets/images/tubeyoke2.PNG';
import TubeYoke3 from '../../Assets/images/tubeyoke3.PNG';
import CampanionFlange1 from '../../Assets/images/sfcampanion1.PNG';
import CampanionFlange2 from '../../Assets/images/slfcampanion2.PNG';
import ShortCouple from '../../Assets/images/SAEshortcouple.PNG';
import HoleCampanion5 from '../../Assets/images/4holecampanion5.PNG';
import CampanionFlange3 from '../../Assets/images/roundcampanion3.PNG';
import Groovetype from '../../Assets/images/groove_type.PNG';
import SlipJoint from '../../Assets/images/slip_joint.PNG';
import SlipStubShaft from '../../Assets/images/slip_stub_shaft.PNG';
import Cseries from '../../Assets/images/c_series_sy.PNG';
import CseriesTy from '../../Assets/images/c_series_ty.PNG';
import CseriesYs from '../../Assets/images/c_series_ys.PNG';
import CseriesSj from '../../Assets/images/slip_joint_c_series.PNG';
import CseriesShortcouple from '../../Assets/images/short_couple_c_series.PNG';
import CenterYoke from '../../Assets/images/center_yoke.PNG';
import CvTubeyoke from '../../Assets/images/cv_tube_yoke.PNG';
import CvFlangeyoke from '../../Assets/images/cv_flange_yoke.PNG';
import SarratedFlange from '../../Assets/images/serrated_flange_yoke.PNG';
import CVheadTypeA from '../../Assets/images/CVheadA.PNG';
import CVheadTypeB from '../../Assets/images/CVheadB.PNG';
import Productimg2 from '../../Assets/images/product_2.png';
import Productimg5 from '../../Assets/images/product_5.png';
import { useParams } from "react-router-dom";
import axios from "axios";
// import ReactImageMagnify from "react-image-magnify";

function WebProductDetails() {
    let { id } = useParams()
    let [detail, setDetail] = useState({})
    const [img1, setImg1] = useState({ images: '' })
    const [img2, setImg2] = useState({ images: '' })
    const [img3, setImg3] = useState({ images: '' })
    const [img4, setImg4] = useState({ images: '' })
    const [img5, setImg5] = useState({ images: '' })
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        productDetail();
    }, [])
    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const productDetail = () => {
        let url = process.env.REACT_APP_BASEURL_TWO + `products/${id}/`
        const config = {
            headers: {
                // "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        setLoading(true)
        axios.get(url, config)
            .then(res => {
                console.log(res.data.data)
                setDetail(res.data.data)
                setImg1(res.data.data.images[0])
                setImg2(res.data.data.images[1])
                setImg3(res.data.data.images[2])
                setImg4(res.data.data.images[3])
                setImg5(res.data.data.images[4])
                setLoading(false)
            }
            ).catch(err => {
                console.log(err)
                setLoading(false)

            })

    }
    return (
        <>
            <div style={{ marginTop: '130px', marginBottom: '180px' ,fontFamily:'roboto'}}>
                {loading ? <div className="d-flex justify-content-center"><div>
                    <img src={require('../../Assets/images/loading-waiting.gif')} alt="loading"></img>
                </div></div> :
                    <Row className="m-0 " >
                        {selectedImage === null ?
                            <Col md={12} lg={6} className="mt-5 justify-content-center"  >


                                {
                                    detail.fig === "1.0" && detail.category === 5 ?
                                 
                                            <img src={SleeveYolk1} alt="sleevyoke" style={{ height: '250px', width: '450px' }}></img>
                                :
                                        detail.fig === "2.0" && detail.category === 5 ?
                                            <img src={SleeveYolk2} alt="sleevyoke" style={{ height: '270px', width: '450px' }}></img>
                                            :
                                            detail.fig === "3.0" && detail.category === 5 ?
                                                <img src={Sleevyoke3} alt="sleevyoke" style={{ height: '250px', width: '450px' }}></img>
                                                :
                                                detail.fig === "1.0" && detail.category === 6 ?
                                                    <img src={Endyoke1} alt="endyoke" style={{ height: '250px', width: '450px' }}></img>
                                                    :
                                                    detail.fig === "2.0" && detail.category === 6 ?
                                                        <img src={Endyoke2} alt="endyoke" style={{ height: '250px', width: '450px' }}></img>
                                                        :
                                                        detail.fig === "3.0" && detail.category === 6 ?
                                                            <img src={Endyoke3} alt="endyoke" style={{ height: '250px', width: '450px' }}></img>
                                                            :
                                                            detail.fig === "4.0" && detail.category === 6 ?
                                                                <img src={Endyoke4} alt="endyoke" style={{ height: '270px', width: '450px' }}></img>
                                                                :
                                                                detail.category === 8 ?
                                                                    <img src={Slipshaft} alt="sleevyoke" style={{ height: '250px', width: '450px' }}></img>
                                                                    :
                                                                    detail.fig === "1" && detail.category === 3 ?

                                                                        <img src={FlangeYoke1} alt="flangeyoke" style={{ height: '270px', width: '470px', }}></img>

                                                                        :
                                                                        detail.fig === "2" && detail.category === 3 ?
                                                                            <img src={FlangeYoke2} alt="flangeyoke" style={{ height: '270px', width: '470px' }}></img>
                                                                            :
                                                                            detail.fig === "3" && detail.category === 3 ?
                                                                                <img src={FlangeYoke3} alt="flangeyoke" style={{ height: '270px', width: '470px' }}></img>
                                                                                :
                                                                                detail.fig === "BLANK" && detail.category === 3 ?
                                                                                    <img src={FlangeYoke1} alt="flangeyoke" style={{ height: '270px', width: '470px' }}></img>
                                                                                    :
                                                                                    detail.fig === "CVTYP" && detail.category === 3 ?
                                                                                        <img src={CVFlangeyoke} alt="flangeyoke" style={{ height: '270px', width: '470px' }}></img>
                                                                                        :
                                                                                        detail.fig === "1" && detail.category === 12 ?
                                                                                            <img src={YokeShaft1} alt="yokeshaft" style={{ height: '250px', width: '450px' }}></img>
                                                                                            :
                                                                                            detail.fig === "2" && detail.category === 12 ?
                                                                                                <img src={YokeShaft2} alt="yokeshaft" style={{ height: '250px', width: '450px' }}></img>
                                                                                                :
                                                                                                detail.fig === "3" && detail.category === 12 ?
                                                                                                    <img src={YokeShaft3} alt="yokeshaft" style={{ height: '250px', width: '450px' }}></img>
                                                                                                    :
                                                                                                    detail.fig === "1.0" && detail.category === 9 ?
                                                                                                        <img src={MidShaft1} alt="midshaft" style={{ height: '250px', width: '450px' }}></img>
                                                                                                        :
                                                                                                        detail.fig === "2.0" && detail.category === 9 ?
                                                                                                            <img src={MidShaft2} alt="midshaft" style={{ height: '250px', width: '450px' }}></img>
                                                                                                            :
                                                                                                            detail.category === 11 ?
                                                                                                                <img src={Splinedsleeve} alt="splinedsleeve" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                :
                                                                                                                detail.fig === "1.0" && detail.category === 7 ?
                                                                                                                    <img src={TubeYoke1} alt="tubeyoke" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                    :
                                                                                                                    detail.fig === "2.0" && detail.category === 7 ?
                                                                                                                        <img src={TubeYoke2} alt="tubeyoke" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                        :
                                                                                                                        detail.fig === "3.0" && detail.category === 7 ?
                                                                                                                            <img src={TubeYoke3} alt="tubeyoke" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                            :
                                                                                                                            detail.fig === "1" && detail.category === 1 ?
                                                                                                                                <img src={CampanionFlange1} alt="campanion" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                :
                                                                                                                                detail.fig === "2" && detail.category === 1 ?
                                                                                                                                    <img src={CampanionFlange2} alt="campanion" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                    :
                                                                                                                                    detail.fig === "5" && detail.category === 1 ?
                                                                                                                                        <img src={HoleCampanion5} alt="campanion" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                        :
                                                                                                                                        detail.fig === "3" && detail.category === 1 ?
                                                                                                                                            <img src={CampanionFlange3} alt="campanion" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                            :
                                                                                                                                            detail.category === 26 ?
                                                                                                                                                <img src={ShortCouple} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img> :
                                                                                                                                                detail.internal_category === "TY-GWB" && detail.category === 13 ?
                                                                                                                                                    <img src={Groovetype} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img> :
                                                                                                                                                    detail.internal_category === "FY Serrated - GWB" && detail.category === 13 ?
                                                                                                                                                        <img src={SarratedFlange} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                        : detail.internal_category === "SJ-GWB" && detail.category === 13 ?
                                                                                                                                                            <img src={SlipJoint} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                            : detail.internal_category === "C-SSS" && detail.category === 17 ?
                                                                                                                                                                <img src={SlipStubShaft} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                : detail.internal_category === "C-SY" && detail.category === 17 ?
                                                                                                                                                                    <img src={Cseries} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                    : detail.internal_category === "C-TY" && detail.category === 17 ?
                                                                                                                                                                        <img src={CseriesTy} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                        : detail.internal_category === "C-YS" && detail.category === 17 ?
                                                                                                                                                                            <img src={CseriesYs} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                            : detail.internal_category === "C-SJ" && detail.category === 17 ?
                                                                                                                                                                                <img src={CseriesSj} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                                : detail.internal_category === "C-SC" && detail.category === 17 ?
                                                                                                                                                                                    <img src={CseriesShortcouple} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                                    : detail.internal_category === "H-Yoke-Center Yoke" && detail.category === 35 ?
                                                                                                                                                                                        <img src={CenterYoke} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                                        : detail.internal_category === "Ball Stud Yoke1" && detail.category === 35 ?
                                                                                                                                                                                            <img src={CvTubeyoke} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                                            : detail.internal_category === "Ball Stud Yoke" && detail.category === 35 ?
                                                                                                                                                                                                <img src={CvTubeyoke} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                                                : detail.internal_category === "Center Yoke" && detail.category === 35 ?
                                                                                                                                                                                                    <img src={CvFlangeyoke} alt="shortcouple" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                                                    : detail.internal_category === "1310 CV Head" && detail.category === 35 ?
                                                                                                                                                                                                        <img src={CVheadTypeA} alt="cvhead" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                                                        : detail.internal_category === "1350 CV Head" && detail.category === 35 ?
                                                                                                                                                                                                            <img src={CVheadTypeB} alt="cvhead" style={{ height: '250px', width: '450px' }}></img>
                                                                                                                                                                                                            :
                                                                                                                                                                                                            img1?
                                                                                                                                                                                                            <img src={img1} alt="cvhead" style={{ height: '250px', width: '450px' }}></img>:
                                                                                                                                                                                                                <img src={Productimg} alt="cvhead" style={{ height: '250px', width: '450px' }}></img>


                                }

                                <Row className='d-flex justify-content-center mt-3'>
                                    {img1 ? <Col className="col-2 ms-2"> <img src={img1} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: '80px' }} alt="product" onClick={() => handleImageClick(img1)}></img></Col> : null}
                                    {img2 ? <Col className="col-2 ms-2"> <img src={img2} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: '80px' }} alt="product" onClick={() => handleImageClick(img2)}></img></Col> : null}
                                    {img3 ? <Col className="col-2 ms-2"> <img src={img3} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: '80px' }} onClick={() => handleImageClick(img3)} alt="product"></img></Col> : null}
                                    {img4 ? <Col className="col-2 ms-2"> <img src={img4} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(img4)} alt="product"></img></Col> : null}
                                
                                </Row>
                            </Col> :

                            <Col md={12} lg={6}>

                                {selectedImage ? <img src={selectedImage} alt="Selected" style={{ height: '270px', width: '470px' }} /> : <img src={Productimg} alt="product" style={{ height: '270px', width: '470px' }} />}
                                <Row className='d-flex justify-content-center mt-3'>
                                    {img1 ? <Col className="col-2 ms-2"> <img src={img1} style={{ cursor: 'pointer',border:'1px solid gray',borderRadius:'10px', width:'100%',height:'80px'  }} alt="product" onClick={() => handleImageClick(img1)}></img></Col> : null}
                                    {img2 ? <Col className="col-2 ms-2"> <img src={img2} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height:'80px' }} alt="product" onClick={() => handleImageClick(img2)}></img></Col> : null}
                                    {img3 ? <Col className="col-2 ms-2"> <img src={img3} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height:'80px' }} onClick={() => handleImageClick(img3)} alt="product"></img></Col> :null } 
                                    {img4 ? <Col className="col-2 ms-2"> <img src={img4} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%',height:"80px" }} onClick={() => handleImageClick(img4)} alt="product"></img></Col> : null}
                                    <Col className="col-2 ms-2">
                                        {
                                            detail.fig === "1.0" && detail.category === 5 ?

                                                <img src={SleeveYolk1} alt="sleevyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(SleeveYolk1)}></img>
                                                :
                                                detail.fig === "2.0" && detail.category === 5 ?
                                                    <img src={SleeveYolk2} alt="sleevyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(SleeveYolk2)}></img>
                                                    :
                                                    detail.fig === "3.0" && detail.category === 5 ?
                                                        <img src={Sleevyoke3} alt="sleevyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Sleevyoke3)}></img>
                                                        :
                                                        detail.fig === "1.0" && detail.category === 6 ?
                                                            <img src={Endyoke1} alt="endyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Endyoke1)}></img>
                                                            :
                                                            detail.fig === "2.0" && detail.category === 6 ?
                                                                <img src={Endyoke2} alt="endyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Endyoke2)}></img>
                                                                :
                                                                detail.fig === "3.0" && detail.category === 6 ?
                                                                    <img src={Endyoke3} alt="endyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Endyoke3)}></img>
                                                                    :
                                                                    detail.fig === "4.0" && detail.category === 6 ?
                                                                        <img src={Endyoke4} alt="endyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Endyoke4)}></img>
                                                                        :
                                                                        detail.category === 8 ?
                                                                            <img src={Slipshaft} alt="sleevyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Slipshaft)}></img>
                                                                            :
                                                                            detail.fig === "1" && detail.category === 3 ?

                                                                                <img src={FlangeYoke1} alt="flangeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(FlangeYoke1)}></img>

                                                                                :
                                                                                detail.fig === "2" && detail.category === 3 ?
                                                                                    <img src={FlangeYoke2} alt="flangeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(FlangeYoke2)}></img>
                                                                                    :
                                                                                    detail.fig === "3" && detail.category === 3 ?
                                                                                        <img src={FlangeYoke3} alt="flangeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(FlangeYoke3)}></img>
                                                                                        :
                                                                                        detail.fig === "BLANK" && detail.category === 3 ?
                                                                                            <img src={FlangeYoke1} alt="flangeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(FlangeYoke1)}></img>
                                                                                            :
                                                                                            detail.fig === "CVTYP" && detail.category === 3 ?
                                                                                                <img src={CVFlangeyoke} alt="flangeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CVFlangeyoke)}></img>
                                                                                                :
                                                                                                detail.fig === "1" && detail.category === 12 ?
                                                                                                    <img src={YokeShaft1} alt="yokeshaft" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(YokeShaft1)}></img>
                                                                                                    :
                                                                                                    detail.fig === "2" && detail.category === 12 ?
                                                                                                        <img src={YokeShaft2} alt="yokeshaft" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(YokeShaft2)}></img>
                                                                                                        :
                                                                                                        detail.fig === "3" && detail.category === 12 ?
                                                                                                            <img src={YokeShaft3} alt="yokeshaft" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(YokeShaft3)}></img>
                                                                                                            :
                                                                                                            detail.fig === "1.0" && detail.category === 9 ?
                                                                                                                <img src={MidShaft1} alt="midshaft" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(MidShaft1)}></img>
                                                                                                                :
                                                                                                                detail.fig === "2.0" && detail.category === 9 ?
                                                                                                                    <img src={MidShaft2} alt="midshaft" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(MidShaft2)}></img>
                                                                                                                    :
                                                                                                                    detail.category === 11 ?
                                                                                                                        <img src={Splinedsleeve} alt="splinedsleeve" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Splinedsleeve)}></img>
                                                                                                                        :
                                                                                                                        detail.fig === "1.0" && detail.category === 7 ?
                                                                                                                            <img src={TubeYoke1} alt="tubeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(TubeYoke1)}></img>
                                                                                                                            :
                                                                                                                            detail.fig === "2.0" && detail.category === 7 ?
                                                                                                                                <img src={TubeYoke2} alt="tubeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(TubeYoke2)}></img>
                                                                                                                                :
                                                                                                                                detail.fig === "3.0" && detail.category === 7 ?
                                                                                                                                    <img src={TubeYoke3} alt="tubeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(TubeYoke3)}></img>
                                                                                                                                    :
                                                                                                                                    detail.fig === "1" && detail.category === 1 ?
                                                                                                                                        <img src={CampanionFlange1} alt="campanion" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CampanionFlange1)}></img>
                                                                                                                                        :
                                                                                                                                        detail.fig === "2" && detail.category === 1 ?
                                                                                                                                            <img src={CampanionFlange2} alt="campanion" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CampanionFlange2)}></img>
                                                                                                                                            :
                                                                                                                                            detail.fig === "5" && detail.category === 1 ?
                                                                                                                                                <img src={HoleCampanion5} alt="campanion" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(HoleCampanion5)}></img>
                                                                                                                                                :
                                                                                                                                                detail.fig === "3" && detail.category === 1 ?
                                                                                                                                                    <img src={CampanionFlange3} alt="campanion" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CampanionFlange3)}></img>
                                                                                                                                                    :
                                                                                                                                                    detail.category === 26 ?
                                                                                                                                                        <img src={ShortCouple} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(ShortCouple)}></img> :
                                                                                                                                                        detail.internal_category === "TY-GWB" && detail.category === 13 ?
                                                                                                                                                            <img src={Groovetype} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Groovetype)}></img> :
                                                                                                                                                            detail.internal_category === "FY Serrated - GWB" && detail.category === 13 ?
                                                                                                                                                                <img src={SarratedFlange} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(SarratedFlange)}></img>
                                                                                                                                                                : detail.internal_category === "SJ-GWB" && detail.category === 13 ?
                                                                                                                                                                    <img src={SlipJoint} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(SlipJoint)}></img>
                                                                                                                                                                    : detail.internal_category === "C-SSS" && detail.category === 17 ?
                                                                                                                                                                        <img src={SlipStubShaft} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(SlipStubShaft)}></img>
                                                                                                                                                                        : detail.internal_category === "C-SY" && detail.category === 17 ?
                                                                                                                                                                            <img src={Cseries} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Cseries)}></img>
                                                                                                                                                                            : detail.internal_category === "C-TY" && detail.category === 17 ?
                                                                                                                                                                                <img src={CseriesTy} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CseriesTy)}></img>
                                                                                                                                                                                : detail.internal_category === "C-YS" && detail.category === 17 ?
                                                                                                                                                                                    <img src={CseriesYs} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CseriesYs)}></img>
                                                                                                                                                                                    : detail.internal_category === "C-SJ" && detail.category === 17 ?
                                                                                                                                                                                        <img src={CseriesSj} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CseriesSj)}></img>
                                                                                                                                                                                        : detail.internal_category === "C-SC" && detail.category === 17 ?
                                                                                                                                                                                            <img src={CseriesShortcouple} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CseriesShortcouple)}></img>
                                                                                                                                                                                            : detail.internal_category === "H-Yoke-Center Yoke" && detail.category === 35 ?
                                                                                                                                                                                                <img src={CenterYoke} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CenterYoke)}></img>
                                                                                                                                                                                                : detail.internal_category === "Ball Stud Yoke1" && detail.category === 35 ?
                                                                                                                                                                                                    <img src={CvTubeyoke} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CvTubeyoke)}></img>
                                                                                                                                                                                                    : detail.internal_category === "Ball Stud Yoke" && detail.category === 35 ?
                                                                                                                                                                                                        <img src={CvTubeyoke} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CvTubeyoke)}></img>
                                                                                                                                                                                                        : detail.internal_category === "Center Yoke" && detail.category === 35 ?
                                                                                                                                                                                                            <img src={CvFlangeyoke} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CvFlangeyoke)}></img>
                                                                                                                                                                                                            : detail.internal_category === "1310 CV Head" && detail.category === 35 ?
                                                                                                                                                                                                                <img src={CVheadTypeA} alt="cvhead" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CVheadTypeA)}></img>
                                                                                                                                                                                                                : detail.internal_category === "1350 CV Head" && detail.category === 35 ?
                                                                                                                                                                                                                    <img src={CVheadTypeB} alt="cvhead" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CVheadTypeB)}></img>
                                                                                                                                                                                                                    :
                                                                                                                                                                                                                    null


                                        }
                                    </Col>
                                </Row>
                            </Col>


                        }



                        <Col md={12} lg={6}>
                            <div style={{ textAlign: "left" }} className='col-10'>
                            <span style={{ color: '#0F328E', fontSize: '26px', fontWeight: '600',lineHeight:'36.4px'}}>{detail.series} / {detail.part_number} / {detail.category_name}</span>

                                {/* <div><StarRatings rating={4}
                                    starDimension="20px"
                                    starSpacing="5px"
                                    starRatedColor='#FDBC15'
                                /> <span style={{ color: '#A9A9A9' }}>(1 customer review)</span></div> */}

                            </div>
                            <div style={{ textAlign: "left", color: "#6C6C6C", fontSize: '14px', marginTop: '10px' }} className='col-10' >
                                <span style={{ fontSize: '18px', color: '000#' }}>
                                    Description :-  <span style={{ fontSize: '15px', color: '#000' }}>{detail.description}</span>
                                </span>
                            </div>
                            <Row style={{ textAlign: "left", marginTop: "20px", paddingLeft: '20px', marginRight: '0px ' }}>
                                <Col lg={6}>
                                    <div className="mt-2"> Category Name : <span style={{ color: 'black', fontWeight: '500' }}>{detail.category_name}</span></div>
                                    <div className="mt-2"> Category : <span style={{ color: 'black', fontWeight: '500' }}>{detail.category}</span></div>
                                    {detail.series !== null ? <div className="mt-2"> Series : <span style={{ color: 'black', fontWeight: '500' }}>{detail.series}</span></div> : null}
                                    <div className="mt-2"> Part Number : <span style={{ color: 'black', fontWeight: '500' }}>{detail.part_number}</span></div>
                                    {detail.fig !== null ? <div className="mt-2">  Fig : <span style={{ color: 'black', fontWeight: '500' }}>{detail.fig} </span></div> : null}
                                    {detail.dim_f_key_height !== null ? <div className="mt-2"> Ddim F Key Height : <span style={{ color: 'black', fontWeight: '500' }}>{detail.dim_f_key_height}</span></div> : null}
                                    {detail.dim_k_key_width !== null ? <div className="mt-2">  Dim K Key Width : <span style={{ color: 'black', fontWeight: '500' }}> {detail.dim_k_key_width}</span></div> : null}
                                    {detail.dim_d_bore !== null ? <div className="mt-2">  Dim D Bore : <span style={{ color: 'black', fontWeight: '500' }}> {detail.dim_d_bore}</span></div> : null}
                                    {detail.center_line_to_end !== null ? <div className="mt-2"> Center Line To end : <span style={{ color: 'black', fontWeight: '500' }}>{detail.center_line_to_end}</span></div> : null}
                                    {detail.center_line_to_end_mm !== null ? <div className="mt-2"> Center Line To end MM: <span style={{ color: 'black', fontWeight: '500' }}>{detail.center_line_to_end_mm}</span></div> : null}
                                    {detail.flange_diameter !== null ? <div className="mt-2">  Flange Diameter : <span style={{ color: 'black', fontWeight: '500' }}>{detail.flange_diameter} </span></div> : null}
                                    {detail.pilot_dia_c !== null ? <div className="mt-2">  Pilot Dia C : <span style={{ color: 'black', fontWeight: '500' }}>{detail.pilot_dia_c} </span></div> : null}
                                    {detail.pcd !== null ? <div className="mt-2">  PCD : <span style={{ color: 'black', fontWeight: '500' }}>{detail.pcd} </span></div> : null}
                                    {detail.thread_size !== null ? <div className="mt-2">  Thread Size : <span style={{ color: 'black', fontWeight: '500' }}>{detail.thread_size} </span></div> : null}
                                    {detail.tube_wall_thickness !== null ? <div className="mt-2">  Tube Wall Thickness : <span style={{ color: 'black', fontWeight: '500' }}>{detail.tube_wall_thickness} </span></div> : null}
                                    {detail.spline_size_bore_key_way !== null ? <div className="mt-2">  Spline Size Bore Key Way : <span style={{ color: 'black', fontWeight: '500' }}>{detail.spline_size_bore_key_way} </span></div> : null}
                                    {detail.uj_size !== null ? <div className="mt-2">  Uj Size : <span style={{ color: 'black', fontWeight: '500' }}>{detail.uj_size} </span></div> : null}
                                    {detail.spline !== null ? <div className="mt-2">  Spline : <span style={{ color: 'black', fontWeight: '500' }}>{detail.spline} </span></div> : null}
                                    {detail.f_to_end_g !== null ? <div className="mt-2">  F To end G : <span style={{ color: 'black', fontWeight: '500' }}>{detail.f_to_end_g} </span></div> : null}
                                    {detail.face_to_weld_lc !== null ? <div className="mt-2">  Face To Weld Lc : <span style={{ color: 'black', fontWeight: '500' }}>{detail.face_to_weld_lc} </span></div> : null}
                                    {detail.bolt_holes !== null ? <div className="mt-2">  Bolt Holes : <span style={{ color: 'black', fontWeight: '500' }}>{detail.bolt_holes} </span></div> : null}
                                    {detail.joint_angles !== null ? <div className="mt-2">  Joint Angles : <span style={{ color: 'black', fontWeight: '500' }}>{detail.joint_angles} </span></div> : null}
                                    {detail.tube_size !== null ? <div className="mt-2">  Tube Size : <span style={{ color: 'black', fontWeight: '500' }}>{detail.tube_size} </span></div> : null}
                                    {detail.f_2_f !== null ? <div className="mt-2">  F2F : <span style={{ color: 'black', fontWeight: '500' }}>{detail.f_2_f} </span></div> : null}
                                    {detail.adroit_drg_no !== null ? <div className="mt-2">  Adroit DrgNo : <span style={{ color: 'black', fontWeight: '500' }}>{detail.adroit_drg_no} </span></div> : null}
                                    {detail.reference_part_no !== null ? <div className="mt-2">  Reference Part Number : <span style={{ color: 'black', fontWeight: '500' }}>{detail.reference_part_no} </span></div> : null}
                                    {detail.beta_alpha_angle !== null ? <div className="mt-2">  Beta Alpha Angle : <span style={{ color: 'black', fontWeight: '500' }}>{detail.beta_alpha_angle} </span></div> : null}
                                    {detail.no_of_holes_and_hole_dia !== null ? <div className="mt-2">  No Of Holes And Hole Diameter : <span style={{ color: 'black', fontWeight: '500' }}>{detail.no_of_holes_and_hole_dia} </span></div> : null}
                                    {/* {detail.c_l_to_weld_h !== null ? <div className="mt-2"> Flange or Swing Diameter :  <span style={{ color: 'black', fontWeight: '500' }}>{detail.c_l_to_weld_h} </span></div> : null} */}
                                    {/* {detail.c_l_to_weld_h_mm !== null ? <div className="mt-2"> Flange or Swing Diameter :  <span style={{ color: 'black', fontWeight: '500' }}>{detail.c_l_to_weld_h_mm} </span></div> : null} */}
                                    {detail.secondary_part_number !== null ? <div className="mt-2">  Secondary Part No. : <span style={{ color: 'black', fontWeight: '500' }}>{detail.secondary_part_number} </span></div> : null}
                                    {detail.stud_socket_dimension !== null ? <div className="mt-2">  Stud Socket Dimension : <span style={{ color: 'black', fontWeight: '500' }}>{detail.stud_socket_dimension} </span></div> : null}

                                </Col>
                                <Col lg={6}>
                                    {detail.a !== null ? <div className="mt-2"> Flange or Swing Diameter :  <span style={{ color: 'black', fontWeight: '500' }}>{detail.a} </span></div> : null}
                                    {detail.a_mm !== null ? <div className="mt-2"> Flange or Swing Diameter MM:  <span style={{ color: 'black', fontWeight: '500' }}>{detail.a_mm} </span></div> : null}
                                    {detail.b !== null ? <div className="mt-2">  PCD :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.b} </span></div> : null}
                                    {detail.b_mm !== null ? <div className="mt-2">  PCD MM :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.b_mm} </span></div> : null}
                                    {detail.c !== null ? <div className="mt-2">  Pilot Diameter :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.c} </span></div> : null}
                                    {detail.d !== null ? <div className="mt-2">  Universal Joint Bearing Diameter :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.d} </span></div> : null}
                                    {detail.d_mm !== null ? <div className="mt-2">  Universal Joint Bearing Diameter MM:  <span style={{ color: 'black', fontWeight: '500' }}> {detail.d_mm} </span></div> : null}
                                    {detail.d1_mm !== null ? <div className="mt-2">  Universal Joint Bearing Diameter1 MM :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.d1_mm} </span></div> : null}
                                    {detail.e !== null ? <div className="mt-2">  Outside Yoke Dimension :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.e} </span></div> : null}
                                    {detail.e_mm !== null ? <div className="mt-2">  Outside Yoke Dimension MM :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.e_mm} </span></div> : null}
                                    {detail.f !== null ? <div className="mt-2">  Center To Face Of Plug :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.f} </span></div> : null}
                                    {detail.f_mm !== null ? <div className="mt-2">  Center To Face Of Plug MM :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.f_mm} </span></div> : null}
                                    {detail.g !== null ? <div className="mt-2">  Flange Face To Ear Bore Center Line :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.g} </span></div> : null}
                                    {detail.g1_mm !== null ? <div className="mt-2">  Flange Face To Ear Bore Center Line1 MM:  <span style={{ color: 'black', fontWeight: '500' }}> {detail.g1_mm} </span></div> : null}
                                    {detail.g2_mm !== null ? <div className="mt-2">  Flange Face To Ear Bore Center Line2 MM :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.g2_mm} </span></div> : null}
                                    {detail.h !== null ? <div className="mt-2">  Bearing Cap Dia:  <span style={{ color: 'black', fontWeight: '500' }}> {detail.h} </span></div> : null}
                                    {detail.h_mm !== null ? <div className="mt-2">  Bearing Cap Dia MM:  <span style={{ color: 'black', fontWeight: '500' }}> {detail.h_mm} </span></div> : null}
                                    {/* {detail.i !== null ? <div className="mt-2">  Center To Face Of Plug :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.i} </span></div> : null} */}
                                    {detail.j !== null ? <div className="mt-2"> Center  To End Of Spline  :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.j} </span></div> : null}
                                    {detail.k !== null ? <div className="mt-2">  End Of Spline To Weld  :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.k} </span></div> : null}
                                    {detail.l !== null ? <div className="mt-2"> Spline Length :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.l} </span></div> : null}
                                    {detail.l_mm !== null ? <div className="mt-2"> Spline Length MM:  <span style={{ color: 'black', fontWeight: '500' }}> {detail.l_mm} </span></div> : null}
                                    {detail.m !== null ? <div className="mt-2">  Groove Disatance :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.m} </span></div> : null}
                                    {detail.m_mm !== null ? <div className="mt-2">  Groove Disatance MM:  <span style={{ color: 'black', fontWeight: '500' }}> {detail.m_mm} </span></div> : null}
                                    {detail.n !== null ? <div className="mt-2">  Bearing Cap Dia  :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.n} </span></div> : null}
                                    {detail.n_mm !== null ? <div className="mt-2">  Bearing Cap Dia MM  :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.n_mm} </span></div> : null}
                                    {detail.p !== null ? <div className="mt-2">  Pitch Circle Diameter Of Flange Holes :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.p} </span></div> : null}
                                    {detail.p_mm !== null ? <div className="mt-2">  Pitch Circle Diameter Of Flange Holes MM:  <span style={{ color: 'black', fontWeight: '500' }}> {detail.p_mm} </span></div> : null}

                                    {detail.r !== null ? <div className="mt-2">  Radius Length  :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.r} </span></div> : null}
                                    {detail.slip !== null ? <div className="mt-2">  Slip : <span style={{ color: 'black', fontWeight: '500' }}>{detail.slip} </span></div> : null}
                                    {/* {detail.s !== null ? <div className="mt-2"> Slip :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.s} </span></div> : null}                                 */}
                                    {detail.y !== null ? <div className="mt-2">  Spline End To Brg Shoulder :  <span style={{ color: 'black', fontWeight: '500' }}> {detail.y} </span></div> : null}
                                    {detail.hole_dia_number !== null ? <div className="mt-2">  Hole Diameter Number : <span style={{ color: 'black', fontWeight: '500' }}>{detail.hole_dia_number} </span></div> : null}
                                    {detail.spline_size !== null ? <div className="mt-2">  Spline Size : <span style={{ color: 'black', fontWeight: '500' }}>{detail.spline_size} </span></div> : null}
                                    {detail.ground_hub_dia !== null ? <div className="mt-2">  Ground Hub Diameter : <span style={{ color: 'black', fontWeight: '500' }}>{detail.ground_hub_dia} </span></div> : null}
                                    {detail.ground_hub_dia_mm !== null ? <div className="mt-2">  Ground Hub Diameter MM : <span style={{ color: 'black', fontWeight: '500' }}>{detail.ground_hub_dia_mm} </span></div> : null}
                                    {detail.length_of_hole !== null ? <div className="mt-2">  Lenght Of Hole : <span style={{ color: 'black', fontWeight: '500' }}>{detail.length_of_hole} </span></div> : null}
                                    {detail.t_diameter_and_w_thickness !== null ? <div className="mt-2">T Diameter & W Thickness: <span style={{ color: 'black', fontWeight: '500' }}>{detail.t_diameter_and_w_thickness} </span></div> : null}
                                    {detail.tube_diameter_and_wall_thickness !== null ? <div className="mt-2">Tube Diameter & Wall Thickness: <span style={{ color: 'black', fontWeight: '500' }}>{detail.tube_diameter_and_wall_thickness} </span></div> : null}
                                    {detail.spline_data !== null ? <div className="mt-2">  Spline Data : <span style={{ color: 'black', fontWeight: '500' }}>{detail.spline_data} </span></div> : null}
                                    {detail.tube_diameter !== null ? <div className="mt-2">  Tube Diameter : <span style={{ color: 'black', fontWeight: '500' }}>{detail.tube_diameter} </span></div> : null}
                                    {detail.tube_diameter_mm !== null ? <div className="mt-2">  Tube Diameter MM : <span style={{ color: 'black', fontWeight: '500' }}>{detail.tube_diameter_mm} </span></div> : null}
                                    {detail.tube_thickness !== null ? <div className="mt-2">  Tube Thickness : <span style={{ color: 'black', fontWeight: '500' }}>{detail.tube_thickness} </span></div> : null}
                                    {detail.end_of_spline_to_radius !== null ? <div className="mt-2">  End Of Spline To Radius : <span style={{ color: 'black', fontWeight: '500' }}>{detail.end_of_spline_to_radius} </span></div> : null}
                                    {detail.end_of_spline_to_radius_mm !== null ? <div className="mt-2">  End Of  Spline To Radius MM : <span style={{ color: 'black', fontWeight: '500' }}>{detail.end_of_spline_to_radius_mm} </span></div> : null}
                                    {detail.spline_length !== null ? <div className="mt-2">  Spline Length : <span style={{ color: 'black', fontWeight: '500' }}>{detail.spline_length} </span></div> : null}
                                    {detail.spline_length_mm !== null ? <div className="mt-2">  Spline Length MM: <span style={{ color: 'black', fontWeight: '500' }}>{detail.spline_length_mm} </span></div> : null}
                                    {detail.spline !== null ? <div className="mt-2">  Spline  : <span style={{ color: 'black', fontWeight: '500' }}>{detail.spline} </span></div> : null}

                                </Col>
                            </Row>


                            {/* <Row style={{ textAlign: "left" }}>
                <Col style={{ marginLeft: "15px", color: 'black' }}>
                    <AiOutlineHeart style={{ color: '#4CB5E2', fontSize: '18px', fontWeight: 'bolder', }} /> Add to my wish list
                </Col>
            </Row> */}
                        </Col>

                    </Row>
                }
                {/* <div className="d-flex justify-content-between col-10" style={{ marginTop: '50px', paddingLeft: "50px" }}>
            <div style={{ fontWeight: 'bold', color: '#0F328E', borderBottom: "2px solid #0F328E", width: '200px', textAlign: 'left' }}>Related Products </div>
            <div style={{ fontWeight: 'bold', display: 'flex' }}>View All <MdOutlineArrowForwardIos style={{ fontSize: '14px', marginTop: '5px', color: '#4CB5E2' }} /></div>
        </div> */}

            </div>
        </>
    )
}
export default WebProductDetails;