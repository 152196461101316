import React, { useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import { Input } from "reactstrap";
import { FiLogOut } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import { updateCartQty } from '../../../Redux/feature/cartSlice';
import Carticon from "../../../Assets/images/cart.svg"
const Webnavbar = () => {
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const [cartList, setCartlist] = useState([])
  const getCart = useSelector((state) => state.cart.cartQauntity)
  const [cartLength, setCartLength] = useState('')

  useEffect(() => {
    getCartlist();
    // setCartLength(localStorage.getItem('count'))
    // setCartLength(getCart.length)
    // console.log('length', getCart);
  }, [])


  const logout = () => {
    if (localStorage.getItem('token')) {
      navigate('/home');
      localStorage.clear();
      toast.success("logged out successful!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        theme: "colored",
      })
    }
    else {
      navigate('/home')
      toast.success("logged out successful!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        theme: "colored",
      })
    }
  }
  const profile = () => {
    if (localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_KEY)) {
      navigate('/profile');
    }
    else {
      navigate('/profilesales')
    }
  }
  const getCartlist = () => {

    console.log('lengthhhhh', getCart.length);

    if (localStorage.getItem('token')) {
      let url = process.env.REACT_APP_BASEURL_TWO + `cart/`
      const config = {
        headers: {
          "Authorization": localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
        }
      };
      axios.get(url, config)
        .then(res => {

          setCartlist(res.data.data.item_list)
          dispatch(updateCartQty(res.data.data.item_list.length))
          console.log("length", res.data.data.item_list.length)
        }
        ).catch(err => {
          // console.log(err)

        })
    }
    else {
      navigate('/home');
      toast.error(' your session has been expired login again.', {
        autoClose: 1000,
        theme: "colored",
      });
    }
  }

  return (
    <div>
      <div className="row col-11 d-flex justify-content-between" >
        <div className="col-sm-12 col-md-5 mt-3 ">
          {/* <div className="input-group input-group" style={{ paddingLeft: '40px' }}>
            <span className="input-group-text searchbar" id="basic-addon1" style={{ background: '#914f4f', color: '#fff' }}> All Categories </span>

            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px", paddingBottom: '9px', background: '#0F328E', border: "#0F328E" }}>
                All Categories
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <span className="input-group-text searchbar" id="basic-addon1" style={{ background: '#E5E4E2', color: '#914f4f' }}><FaSearch></FaSearch></span>
            <Input name="search" type="text" placeholder="Search by Part No,Product Type,Product Line" style={{ backgroundColor: '#F4F4F4', width: '15px' }} />
            <span className="input-group-text searchbar" id="basic-addon1" style={{ background: '#E5E4E2', color: '#914f4f' }}><TfiMenuAlt></TfiMenuAlt></span>

          </div> */}
        </div>
        <div className='col-sm-12 col-md-6 mt-3 '>
          <Dropdown style={{ display: 'inline-block' }}>
            <Dropdown.Toggle id="dropdown-basic" style={{ background: '#fff', color: 'black', border: "none" }}>
              <img src={require("../../../Assets/newimages/user.png")} alt="user" width='30px' className='rounded-pill ' />&nbsp; User
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1" onClick={profile}><FaUserCircle style={{ fontSize: "20px", color: 'grey' }} />&nbsp;View Profile</Dropdown.Item>
              <Dropdown.Item href="#/action-2" onClick={logout} ><FiLogOut style={{ color: 'grey' }} />&nbsp; Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <span style={{ cursor: 'pointer', color: 'black' }} onClick={() => { navigate('/showallcart') }}><img src={require('../../../Assets/images/Line.png')} alt="line"></img>&nbsp;&nbsp;
            <div style={{ height: '10px', width: '25px', borderRadius: '50%', background: '#157eab', display: 'inline-block', padding: '0px 7px 24px 5px', color: 'white', position: 'absolute' }}>{getCart}</div><img src={Carticon} style={{width:'30px'}} alt="bulk" className='ms-2'></img>&nbsp;Cart &nbsp;&nbsp;&nbsp;</span>
          {/* <img src={require('../../../Assets/images/bulk.png')} alt="bulk"></img>&nbsp;Cart &nbsp;&nbsp;&nbsp; {getCart.length}</span> */}
          <span onClick={() => { navigate('/bulkimport') }} style={{ cursor: 'pointer' }}>
            <img src={require('../../../Assets/images/Line.png')} alt="line">
            </img> &nbsp; <img src={require('../../../Assets/images/bulkcart.png')} alt="bulkcart"></img>&nbsp;Bulk Import</span>
        </div>
      </div>
    </div>
  )
}

export default Webnavbar
