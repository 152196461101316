import React, { useState, useEffect } from "react";
import { Row, Col ,Container} from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown';
import { AiOutlineSearch } from "react-icons/ai";
import { Input } from "reactstrap";
import axios from "axios";
import { handleError } from '../../util/HandleErrors';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import PlaceholderImage from "../../Assets/images/image_placeholder.jpg"
import { BiRupee } from 'react-icons/bi';
import { MdOutlineArrowForwardIos } from "react-icons/md";
import AllCategory from "./AllCategory";
import Brands from "./Brands";
import Categories from "./Categories";
import DashboardProduct from "./DashboardProduct";
import Products from "./ProductsDemo";
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from "react-router";
import WebProducts from "../../Content/OurProduct/WebProducts";
import WebProductSKeleton from "./skeleton/WebProductSkeleton";
import AllProducts from "./AllProducts";
import AdroitmainSkelerton from "../adroitmainskeloten";
// import Webnavbar from "./Webnavbar/Webnavbar";
function AdroitMainPage() {
    const [category, setCategory] = useState()
    const [publicId, setPublicId] = useState('')
    const [categoryName, setCategoryName] = useState('')
    const [showAllProduct, setShowAllProdcut] = useState(true)
    const [series, setSeries] = useState('')
    const [seriesName, setSeriesName] = useState('')
    const [categoryList, setCategoryList] = useState([])
    const [seriesList, setSeriesList] = useState([])
    const [searchValue, setSearchValue] = useState('');
    let [universalJoint, setUniversalJoint] = useState('')
    const [productList, setProductList] = useState([])
    const [loading, setLoading] = useState(false)
    let navigate = useNavigate()
    useEffect(() => {
        categoryListData();
        seriesListData();
    }, [])
    const productListData = (public_id) => {
        setLoading(true);
        let url = process.env.REACT_APP_BASEURL_TWO + `product_list/?search=${searchValue}&category_id=${public_id}&series=${seriesName}&universal=${universalJoint}`
        console.log(url)
        const config = {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        axios.get(url, config)
            .then(res => {
                setProductList(res.data.data)

                setLoading(false)
            }
            ).catch(err => {

                setLoading(false);
            })
    }
    const productListDataSearch = () => {

        setLoading(true);
        setSeriesName('All Series');
        // let url = process.env.REACT_APP_BASEURL_TWO + `product_list/?search=${searchValue}&category_id=${publicId}`
        let url = process.env.REACT_APP_BASEURL_TWO + `product_list/?search=${searchValue}&category_id=${publicId}&series=${seriesName}&universal=${universalJoint}

`
        console.log(url)
        const config = {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        axios.get(url, config)
            .then(res => {
                setProductList(res.data.data)

                setLoading(false)
            }
            ).catch(err => {

                setLoading(false);
            })
    }
    const productListDataSeries = (name) => {

        setLoading(true);
        // let url = process.env.REACT_APP_BASEURL_TWO + `product_list/?search=${name}&category_id=`
        let url = process.env.REACT_APP_BASEURL_TWO + `product_list/?search=${searchValue}&category_id=${publicId}&series=${name}&universal=${universalJoint}`
        console.log(url)
        const config = {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        axios.get(url, config)
            .then(res => {
                setProductList(res.data.data)

                setLoading(false)
            }
            ).catch(err => {

                setLoading(false);
            })
    }
    const categoryListData = () => {
        setLoading(true)

        let url = process.env.REACT_APP_BASEURL_TWO + "category/"
        const config = {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        console.log(config)
        axios.get(url, config)
            .then(res => {

                setCategoryList(res.data.data)
                console.log(res.data.data)
                setLoading(false)
            }
            ).catch(err => {
                console.log(err)
                setLoading(false)
                const getErrRes = handleError(err)

            })

    }
    const searchUniversalJoint = (de) => {
        setLoading(true);
        // let url = process.env.REACT_APP_BASEURL_TWO + `product_list/?search=${searchValue}&category_id=${publicId}`
        let url = process.env.REACT_APP_BASEURL_TWO + `product_list/?search=${searchValue}&category_id=${publicId}&series=${seriesName}&universal=${de}`
        console.log(url)
        const config = {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        axios.get(url, config)
            .then(res => {
                setProductList(res.data.data)
                setLoading(false)
            }
            ).catch(err => {
                setLoading(false);
            })
    }
    const seriesListData = () => {
        setLoading(true)

        let url = process.env.REACT_APP_BASEURL_TWO + "series/"
        const config = {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        console.log(config)
        axios.get(url, config)
            .then(res => {

                setSeriesList(res.data)
                console.log(res.data, "series")
                setLoading(false)
            }
            ).catch(err => {
                console.log(err)
                setLoading(false)
                const getErrRes = handleError(err)

            })

    }
    return (
        <>
            {/* <Webnavbar/> */}
            <div className="" style={{ marginTop: '60px' ,fontFamily:'roboto'}}>
            <Container fluid >
                {/* <div style={{ width: "75vw", paddingLeft: '50px' }} className="d-flex justify-content-center" >
                    <Carousel variant="primary">
                        <Carousel.Item >
                            <img className="d-block w-80" src={require('../../Assets/images/slider_dashboard.png')} alt="img" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-80" src={require('../../Assets/images/slider_dashboard.png')} alt="img" />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-80" src={require('../../Assets/images/slider_dashboard.png')} alt="img" />
                        </Carousel.Item>
                    </Carousel>
                </div> */}
                <div className='d-flex justify-content-center'>
                    <div className="row col-12 d-flex justify-content-start mt-2" >
                        <div className="col-sm-12 col-md-9 mt-2 ">
                            <div className="input-group input-group d-flex justify-content-center" >
                                <Row style={{ width: "80%" }}>
                                    <Col className="mt-3" lg={1} >Search</Col>
                                    <Col lg={11} className="mt-2">
                                    <form className="d-flex col-12 " onSubmit={(e) => { e.preventDefault(); productListDataSearch(); setShowAllProdcut(false); setSeriesName('') }}>
                                            <Input name="search" required className='col-12 border border-secondary' value={searchValue} onChange={(e) => { setSearchValue(e.target.value) }} type="text" placeholder="Search by Part No,Product Type" style={{ backgroundColor: '#F4F4F4' }} />
                                            <button className='btn border border-secondary ' style={{ background: '#fff', border: "#0F328E", marginLeft: '-30px' }} > <AiOutlineSearch /></button>
                                        </form>
                                    </Col>
                                </Row>
                                <Row className="col-12 d-flex justify-content-center mt-4">
                                    {/* <Col className="mt-3">  <span className="">Filters</span></Col>*/}
                                    <Col className="mt-3" lg={1} >Filters</Col>
                                    <Col lg={3} className='mt-2'>
                                        <Dropdown >
                                            <Dropdown.Toggle id="dropdown-basic" className=" border border-secondary" style={{ paddingBottom: '9px', background: '#fff', color: 'black', minWidth: '210px' }}>
                                                {categoryName === '' ? "All Categories" : categoryName}
                                            </Dropdown.Toggle>
                                            {categoryList.length === 0 ? <Dropdown.Menu><Dropdown.Item disabled>No Data Available</Dropdown.Item></Dropdown.Menu> : <Dropdown.Menu style={{ height: '30vh', overflowX: "hidden" }}>
                                                <Dropdown.Item onClick={() => { setShowAllProdcut(true); setCategoryName(''); setPublicId(''); setCategory('') }}>All Categories</Dropdown.Item>
                                                {
                                                    categoryList.map((item) => {
                                                        return (
                                                            <Dropdown.Item onClick={() => { setPublicId(item.public_id); setCategory(item.name); setCategoryName(item.name); productListData(item.public_id); setShowAllProdcut(false) }} >{item.name}</Dropdown.Item>
                                                        )
                                                    })
                                                }

                                            </Dropdown.Menu>}
                                        </Dropdown>
                                    </Col>
                                    <Col lg={3} className='mt-2'>
                                        <Dropdown >
                                            <Dropdown.Toggle id="dropdown-basic" className=" border border-secondary text-black" style={{ paddingBottom: '9px', background: '#fff', width: '210px' }}>
                                                {seriesName === '' ? "All Series" : seriesName}
                                            </Dropdown.Toggle>
                                            {seriesList.length === 0 ? <Dropdown.Menu><Dropdown.Item disabled>No Data Available</Dropdown.Item></Dropdown.Menu> : <Dropdown.Menu style={{ height: '30vh', overflowX: "hidden" }}>
                                                <Dropdown.Item onClick={() => { setShowAllProdcut(true); setSeriesName(''); }}>All Series</Dropdown.Item>
                                                {
                                                    seriesList.map((item) => {
                                                        return (
                                                            <Dropdown.Item onClick={() => { productListDataSeries(item.series); setSeries(item.series); setSeriesName(item.series); setShowAllProdcut(false) }} >{item.series}</Dropdown.Item>
                                                        )
                                                    })
                                                }


                                            </Dropdown.Menu>}
                                        </Dropdown>
                                    </Col>
                                    <Col lg={3} className='mt-2'>
                                        <Dropdown >
                                            <Dropdown.Toggle id="dropdown-basic" className=" border border-secondary text-black" style={{ paddingBottom: '9px', background: '#fff', width: '210px' }}>
                                                {universalJoint === '' ? "Universal Joint" : universalJoint}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{ height: '30vh', overflowX: "hidden" }}>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint(''); setShowAllProdcut(false); setUniversalJoint('') }} >No Select Universal Joint</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1*1.875'); setShowAllProdcut(false); setUniversalJoint('1*1.875') }} >1 x 1.875</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.125*1.875'); setShowAllProdcut(false); setUniversalJoint('1.125*1.875') }}>1.125 x 1.875</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.25*1.875'); setShowAllProdcut(false); setUniversalJoint('1.25*1.875') }}>1.25 x 1.875</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('0.875*1.875'); setShowAllProdcut(false); setUniversalJoint('0.875*1.875') }} >0.875 x 1.875</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.181*1.875'); setShowAllProdcut(false); setUniversalJoint('1.181*1.875') }} >1.181 x 1.875</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.375*2.12'); setShowAllProdcut(false); setUniversalJoint('1.375*2.12') }} >1.375 x 2.12</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.5*2.438'); setShowAllProdcut(false); setUniversalJoint('1.5*2.438') }} >1.5 x 2.438</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.124*1.875'); setShowAllProdcut(false); setUniversalJoint('1.124*1.875') }}  >1.124 x 1.875</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('0.75*2.17'); setShowAllProdcut(false); setUniversalJoint('0.75*2.17') }} >0.75 x 2.17</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('0.938*1.5'); setShowAllProdcut(false); setUniversalJoint('0.938*1.5') }} >0.938 x 1.5</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('0.937*2.656'); setShowAllProdcut(false); setUniversalJoint('0.937*2.656') }} >0.937 x 2.656</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('0.937*2.756'); setShowAllProdcut(false); setUniversalJoint('0.937*2.756') }} >0.937 x 2.756</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('0.9374*2.755'); setShowAllProdcut(false); setUniversalJoint('0.9374*2.755') }} >0.9374 x 2.755</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.062*3.469'); setShowAllProdcut(false); setUniversalJoint('1.062*3.469') }} >1.062 x 3.469</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.062*3.47'); setShowAllProdcut(false); setUniversalJoint('1.062*3.47') }} >1.062 x 3.47</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.062*3.875'); setShowAllProdcut(false); setUniversalJoint('1.062*3.875') }} >1.062 x 3.875</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.188*3.875'); setShowAllProdcut(false); setUniversalJoint('1.188*3.875') }} >1.188 x 3.875</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.188*4.438'); setShowAllProdcut(false); setUniversalJoint('1.188*4.438') }} >1.188 x 4.438</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.88*4.438'); setShowAllProdcut(false); setUniversalJoint('1.88*4.438') }} >1.88 x 4.438</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.375*4.438'); setShowAllProdcut(false); setUniversalJoint('1.375*4.438') }} >1.375 x 4.438</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.559*4.697'); setShowAllProdcut(false); setUniversalJoint('1.559*4.697') }} >1.559 x 4.697</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.375*5.25'); setShowAllProdcut(false); setUniversalJoint('1.375*5.25') }} >1.375 x 5.25</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.875*5.312'); setShowAllProdcut(false); setUniversalJoint('1.875*5.312') }} >1.875 x 5.312</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.938*6.094'); setShowAllProdcut(false); setUniversalJoint('1.938*6.094') }} >1.938 x 6.094</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.938*7.547'); setShowAllProdcut(false); setUniversalJoint('1.938*7.547') }} >1.938 x 7.547</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.188*8.094'); setShowAllProdcut(false); setUniversalJoint('2.188*8.094') }} >2.188 x 8.094</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.125*3.625'); setShowAllProdcut(false); setUniversalJoint('1.125*3.625') }} >1.125 x 3.625</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('0.938*2.656'); setShowAllProdcut(false); setUniversalJoint('0.938*2.656') }} >0.938 x 2.656</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.062*2.937'); setShowAllProdcut(false); setUniversalJoint('1.062*2.937') }} >1.062 x 2.937</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.063*3.469'); setShowAllProdcut(false); setUniversalJoint('1.063*3.469') }} >1.063 x 3.469</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.562*4.562'); setShowAllProdcut(false); setUniversalJoint('1.562*4.562') }} >1.562 x 4.562</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.876*5.312'); setShowAllProdcut(false); setUniversalJoint('1.876*5.312') }} >1.876 x 5.312</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.938*7'); setShowAllProdcut(false); setUniversalJoint('1.938*7') }} >1.938 x 7</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.142*2.047'); setShowAllProdcut(false); setUniversalJoint('1.142*2.047') }} >1.142 x 2.047</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.876*5.31'); setShowAllProdcut(false); setUniversalJoint('1.876*5.31') }} >1.876 x 5.31</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.938*6.091'); setShowAllProdcut(false); setUniversalJoint('1.938*6.091') }} >1.938 x 6.091</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('0.75*2.165'); setShowAllProdcut(false); setUniversalJoint('0.75*2.165') }} >0.75 x 2.165</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.374*5.25'); setShowAllProdcut(false); setUniversalJoint('1.374*5.25') }} >1.374 x 5.25</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('0.937*1.57'); setShowAllProdcut(false); setUniversalJoint('0.937*1.57') }} >0.937 x 1.57</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.187*3.875'); setShowAllProdcut(false); setUniversalJoint('1.187*3.875') }} >1.187 x 3.875</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.124*3.555'); setShowAllProdcut(false); setUniversalJoint('1.124*3.555') }} >1.124 x 3.555</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.124*2.563'); setShowAllProdcut(false); setUniversalJoint('1.124*2.563') }} >1.124 x 2.563</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.26*2.52'); setShowAllProdcut(false); setUniversalJoint('1.26*2.52') }} >1.26 x 2.52</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('0.749*2.165'); setShowAllProdcut(false); setUniversalJoint('0.749*2.165') }} >0.749 x 2.165</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.498*2.441'); setShowAllProdcut(false); setUniversalJoint('1.498*2.441') }} >1.498 x 2.441</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.624*5.375'); setShowAllProdcut(false); setUniversalJoint('1.624*5.375') }} >1.624 x 5.375</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.654*4.449'); setShowAllProdcut(false); setUniversalJoint('1.654*4.449') }} >1.654 x 4.449</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.89*5.039'); setShowAllProdcut(false); setUniversalJoint('1.89*5.039') }} >1.89 x 5.039</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.047*6.193'); setShowAllProdcut(false); setUniversalJoint('2.047*6.193') }} >2.047 x 6.193</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.559*7.283'); setShowAllProdcut(false); setUniversalJoint('2.559*7.283') }} >2.559 x 7.283</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('0.938 *2.756'); setShowAllProdcut(false); setUniversalJoint('0.938 *2.756') }} >0.938 x 2.756</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.062*3.228'); setShowAllProdcut(false); setUniversalJoint('1.062*3.228') }} >1.062 x 3.228</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.062*3.218'); setShowAllProdcut(false); setUniversalJoint('1.062*3.218') }} >1.062 x 3.218</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.188*3.622'); setShowAllProdcut(false); setUniversalJoint('1.188*3.622') }} >1.188 x 3.622</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.188*3.625'); setShowAllProdcut(false); setUniversalJoint('1.188*3.625') }} >1.188 x 3.625</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.188*4.188'); setShowAllProdcut(false); setUniversalJoint('1.188*4.188') }} >1.188 x 4.188</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.375*4.188'); setShowAllProdcut(false); setUniversalJoint('1.375*4.188') }} >1.375 x 4.188</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.938*7.094'); setShowAllProdcut(false); setUniversalJoint('1.938*7.094') }} >1.938 x 7.094</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.938*7.643'); setShowAllProdcut(false); setUniversalJoint('1.938*7.643') }} >1.938 x 7.643</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.187*3.543'); setShowAllProdcut(false); setUniversalJoint('1.187*3.543') }} >1.187 x 3.543</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.166*6.456'); setShowAllProdcut(false); setUniversalJoint('2.166*6.456') }} >2.166 x 6.456</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.362*6.412'); setShowAllProdcut(false); setUniversalJoint('2.362*6.412') }} >2.362 x 6.412</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.625*4.957'); setShowAllProdcut(false); setUniversalJoint('1.625*4.957') }} >1.625 x 4.957</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.062*2.688'); setShowAllProdcut(false); setUniversalJoint('1.062*2.688') }} >1.062 x 2.688</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.062*3.268'); setShowAllProdcut(false); setUniversalJoint('1.062*3.268') }} >1.062 x 3.268</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.062*3.504'); setShowAllProdcut(false); setUniversalJoint('1.062*3.504') }} >1.062 x 3.504</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.962*3.469'); setShowAllProdcut(false); setUniversalJoint('1.962*3.469') }} >1.962 x 3.469</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('30.162*98.4'); setShowAllProdcut(false); setUniversalJoint('30.162*98.4') }} >30.162 x 98.4</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.187*4.438'); setShowAllProdcut(false); setUniversalJoint('1.187*4.438') }} >1.187 x 4.438</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.563*4.563'); setShowAllProdcut(false); setUniversalJoint('1.563*4.563') }} >1.563 x 4.563</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.562*4.921'); setShowAllProdcut(false); setUniversalJoint('1.562*4.921') }} >1.562 x 4.921</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.562*4.875'); setShowAllProdcut(false); setUniversalJoint('1.562*4.875') }} >1.562 x 4.875</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.938*0.094'); setShowAllProdcut(false); setUniversalJoint('1.938*0.094') }} >1.938 x 0.094</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2*2.953'); setShowAllProdcut(false); setUniversalJoint('2*2.953') }} >2 x 2.953</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('0.749*2.17'); setShowAllProdcut(false); setUniversalJoint('0.749*2.17') }} >0.749 x 2.17</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.497*3.819'); setShowAllProdcut(false); setUniversalJoint('1.497*3.819') }} >1.497 x 3.819</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.497*2.433'); setShowAllProdcut(false); setUniversalJoint('1.497*2.433') }} >1.497 x 2.433</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.244*6.811'); setShowAllProdcut(false); setUniversalJoint('2.244*6.811') }} >2.244 x 6.811</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.164*6.023'); setShowAllProdcut(false); setUniversalJoint('2.164*6.023') }} >2.164 x 6.023</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.363*5.981'); setShowAllProdcut(false); setUniversalJoint('2.363*5.981') }} >2.363 x 5.981</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.142*2.039'); setShowAllProdcut(false); setUniversalJoint('1.142*2.039') }} >1.142 x 2.039</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.062*3.248'); setShowAllProdcut(false); setUniversalJoint('1.062*3.248') }} >1.062 x 3.248</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.187*3.937'); setShowAllProdcut(false); setUniversalJoint('1.187*3.937') }} >1.187 x 3.937</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.875*5.31'); setShowAllProdcut(false); setUniversalJoint('1.875*5.31') }} >1.875 x 5.31</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.374*4.438'); setShowAllProdcut(false); setUniversalJoint('1.374*4.438') }} >1.374 x 4.438</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.06*7.274'); setShowAllProdcut(false); setUniversalJoint('2.06*7.274') }} >2.06 x 7.274</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.062*3.26'); setShowAllProdcut(false); setUniversalJoint('1.062*3.26') }} >1.062 x 3.26</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.189*6.437'); setShowAllProdcut(false); setUniversalJoint('1.189*6.437') }} >1.189 x 6.437</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.559*7.688'); setShowAllProdcut(false); setUniversalJoint('2.559*7.688') }} >2.559 x 7.688</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.062*3.739'); setShowAllProdcut(false); setUniversalJoint('1.062*3.739') }} >1.062 x 3.739</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.062*3.468'); setShowAllProdcut(false); setUniversalJoint('1.062*3.468') }} >1.062 x 3.468</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.938*7.548'); setShowAllProdcut(false); setUniversalJoint('1.938*7.548') }} >1.938 x 7.548</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.89*4.921'); setShowAllProdcut(false); setUniversalJoint('1.89*4.921') }} >1.89 x 4.921</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.047*5.63'); setShowAllProdcut(false); setUniversalJoint('2.047*5.63') }}>2.047 x 5.63</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.654*5.059'); setShowAllProdcut(false); setUniversalJoint('1.654*5.059') }}>1.654 x 5.059</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.244*6.39'); setShowAllProdcut(false); setUniversalJoint('2.244*6.39') }} >2.244 x 6.39</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.169*6.193'); setShowAllProdcut(false); setUniversalJoint('2.169*6.193') }} >2.169 x 6.193</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.181*3.504'); setShowAllProdcut(false); setUniversalJoint('1.181*3.504') }} >1.181 x 3.504</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.187*3.354'); setShowAllProdcut(false); setUniversalJoint('1.187*3.354') }} >1.187 x 3.354</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.559*7.264'); setShowAllProdcut(false); setUniversalJoint('2.559*7.264') }}  >2.559 x 7.264</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.164*6.024'); setShowAllProdcut(false); setUniversalJoint('2.164*6.024') }} >2.164 x 6.024</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.82*2.5'); setShowAllProdcut(false); setUniversalJoint('2.82*2.5') }} >2.82 x 2.5</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.818*2.688'); setShowAllProdcut(false); setUniversalJoint('2.818*2.688') }}>2.818 x 2.688</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.818*2.5'); setShowAllProdcut(false); setUniversalJoint('2.818*2.5') }}>2.818 x 2.5</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.159*2.94'); setShowAllProdcut(false); setUniversalJoint('3.159*2.94') }} >3.159 x 2.94</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.27*2.82'); setShowAllProdcut(false); setUniversalJoint('3.27*2.82') }} >3.27 x 2.82</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.135*2.75'); setShowAllProdcut(false); setUniversalJoint('3.135*2.75') }} >3.135 x 2.75</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.742*2.75'); setShowAllProdcut(false); setUniversalJoint('3.742*2.75') }} >3.742 x 2.75</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.32*2.82'); setShowAllProdcut(false); setUniversalJoint('3.32*2.82') }}  >3.32 x 2.82</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.74*3.855'); setShowAllProdcut(false); setUniversalJoint('3.74*3.855') }} >3.74 x 3.855</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.63*2.88'); setShowAllProdcut(false); setUniversalJoint('3.63*2.88') }} >3.63 x 2.88</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('4.13*3.25'); setShowAllProdcut(false); setUniversalJoint('4.13*3.25') }} >4.13 x 3.25</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('4*3.25'); setShowAllProdcut(false); setUniversalJoint('4*3.25') }} >4 x 3.25</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('4.01*3.25'); setShowAllProdcut(false); setUniversalJoint('4.01*3.25') }} >4.01 x 3.25</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.32*2.5'); setShowAllProdcut(false); setUniversalJoint('3.32*2.5') }} >3.32 x 2.5</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.32*2.66'); setShowAllProdcut(false); setUniversalJoint('3.32*2.66') }} >3.32 x 2.66</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.32*1.88'); setShowAllProdcut(false); setUniversalJoint('2.32*1.88') }} >2.32 x 1.88</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('4*3.5'); setShowAllProdcut(false); setUniversalJoint('4*3.5') }} >4 x 3.5</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('5.126*4.2519'); setShowAllProdcut(false); setUniversalJoint('5.126*4.2519') }}>5.126 x 4.2519</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('6.004*4.508'); setShowAllProdcut(false); setUniversalJoint('6.004*4.508') }}>6.004 x 4.508</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.978*2.2531'); setShowAllProdcut(false); setUniversalJoint('1.978*2.2531') }} >1.978 x 2.2531</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.438*1.88'); setShowAllProdcut(false); setUniversalJoint('2.438*1.88') }} >2.438 x 1.88</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.343*1.88'); setShowAllProdcut(false); setUniversalJoint('3.343*1.88') }} >3.343 x 1.88</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('1.97*1.927'); setShowAllProdcut(false); setUniversalJoint('1.97*1.927') }} >1.97 x 1.927</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.51*2.5'); setShowAllProdcut(false); setUniversalJoint('2.51*2.5') }}  >2.51 x 2.5</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.318*2.5'); setShowAllProdcut(false); setUniversalJoint('3.318*2.5') }} >3.318 x 2.5</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.315*2.5'); setShowAllProdcut(false); setUniversalJoint('3.315*2.5') }} >3.315 x 2.5</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.451*2.5'); setShowAllProdcut(false); setUniversalJoint('2.451*2.5') }} >2.451 x 2.5</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.63*2.5'); setShowAllProdcut(false); setUniversalJoint('2.63*2.5') }} >2.63 x 2.5</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.31*2.5'); setShowAllProdcut(false); setUniversalJoint('3.31*2.5') }} >3.31 x 2.5</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.438*2.5'); setShowAllProdcut(false); setUniversalJoint('2.438*2.5') }} >2.438 x 2.5</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.455*2.756'); setShowAllProdcut(false); setUniversalJoint('2.455*2.756') }} >2.455 x 2.756</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.159*2.82'); setShowAllProdcut(false); setUniversalJoint('3.159*2.82') }} >3.159 x 2.82</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('2.844*2.82'); setShowAllProdcut(false); setUniversalJoint('2.844*2.82') }} >2.844 x 2.82</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.3*2.9'); setShowAllProdcut(false); setUniversalJoint('3.3*2.9') }} >3.3 x 2.9</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.13*2.75'); setShowAllProdcut(false); setUniversalJoint('3.13*2.75') }} >3.13 x 2.75</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.2*4'); setShowAllProdcut(false); setUniversalJoint('3.2*4') }} >3.2 x 4</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.32*2.75'); setShowAllProdcut(false); setUniversalJoint('3.32*2.75') }} >3.32 x 2.75</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.737*2.75'); setShowAllProdcut(false); setUniversalJoint('3.737*2.75') }} >3.737 x 2.75</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.242*2.511'); setShowAllProdcut(false); setUniversalJoint('3.242*2.511') }} >3.242 x 2.511</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.159*2.75'); setShowAllProdcut(false); setUniversalJoint('3.159*2.75') }} >3.159 x 2.75</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.242*1.811'); setShowAllProdcut(false); setUniversalJoint('3.242*1.811') }} >3.242 x 1.811</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.74*3.5'); setShowAllProdcut(false); setUniversalJoint('3.74*3.5') }} >3.74 x 3.5</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.74*3.499'); setShowAllProdcut(false); setUniversalJoint('3.74*3.499') }} >3.74 x 3.499</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.474*3.499'); setShowAllProdcut(false); setUniversalJoint('3.474*3.499') }} >3.474 x 3.499</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.63*3.5'); setShowAllProdcut(false); setUniversalJoint('3.63*3.5') }} >3.63 x 3.5</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.553*3.604'); setShowAllProdcut(false); setUniversalJoint('3.553*3.604') }} >3.553 x 3.604</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.55*3.855'); setShowAllProdcut(false); setUniversalJoint('3.55*3.855') }} >3.55 x 3.855</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.947*3.25'); setShowAllProdcut(false); setUniversalJoint('3.947*3.25') }} >3.947 x 3.25</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('3.992*3.25'); setShowAllProdcut(false); setUniversalJoint('3.992*3.25') }} >3.992 x 3.25</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { searchUniversalJoint('4.736*3.309'); setShowAllProdcut(false); setUniversalJoint('4.736*3.309') }} >4.736 x 3.309</Dropdown.Item>

                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <AllCategory /> */}
                <Row className="mt-5">
                <Col className='col-lg-11 d-flex flex-direction-row flex-wrap justify-content-center ' >
                        {showAllProduct ? <AllProducts/> : <div className="mt-2">
                            <div className="d-flex justify-content-start" style={{}}>
                                <div style={{ fontWeight: 'bold', color: '#0F328E', borderBottom: "2px solid #0F328E", width: '200px',  }}>All Products </div>
                            </div>
                            {loading ? <AdroitmainSkelerton/>: <div className='row  col-12' >
                                <div class=" flex-wrap d-flex justify-content-start mt-4 mb-4" >
                                    {productList.length === 0 ? <div className="d-flex justify-content-start mx-auto mt-5"><div className="text-center fs-4 fw-bold">Coming Soon, Check back later.</div></div> :
                                        productList.map((prdouct) => {
                                            return (<>
                                                <Card onClick={() => navigate(`/productdetail/${prdouct.public_id}`)} style={{ width: '230px', height: 'auto', borderColor: '#A9ADB0', borderRadius: '16px', boxShadow: "0px 97px 39px rgba(0, 0, 0, 0.01), 0px 54px 33px rgba(0, 0, 0, 0.03), 0px 24px 24px rgba(0, 0, 0, 0.04), 0px 6px 13px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)", margin: '10px', cursor: 'pointer' }} >
                                                    <div style={{ background: '#F5F5F5', borderRadius: '16px' }}>
                                                        <Card.Img variant="top" onClick={() => navigate(`/webdetail/${prdouct.public_id}`)} src={prdouct.images === "" ? PlaceholderImage : prdouct.images} style={{ background: '#F5F5F5', height: 'auto', width: 'auto', borderRadius: '16px', }} />
                                                    </div>
                                                    <Card.Body>
                                                        <Card.Title onClick={() => navigate(`/productdetail/${prdouct.public_id}`)} style={{ color: '#0F328E', textAlign: 'left', marginTop: '10PX', fontSize: '16px' }}>{prdouct.part_number}</Card.Title>
                                                        <Card.Text onClick={() => navigate(`/productdetail/${prdouct.public_id}`)} style={{ color: 'grey', textAlign: 'left', fontSize: '13px', lineHeight: '22px' }}>
                                                            {prdouct.description}
                                                            <div className='d-flex justify-content-between mt-1' >
                                                                <p style={{ textAlign: 'left', fontSize: '17px' ,fontWeight:'500',color:'black'}}> $ {prdouct.product_price}</p>
                                                                {/* <h5 style={{ color: 'green', fontSize: '16px' }}>Save-<BiRupee />{Math.floor(Math.random() * 10000)}</h5> */}
                                                            </div>
                                                            <hr style={{ marginTop: "-3px" }}></hr>
                                                        </Card.Text>

                                                        <div className='d-flex justify-content-center mt-1 '>

                                                            <Button style={{ background: '#0F328E', borderColor: '#0F328E' }} onClick={() => navigate(`/productdetail/${prdouct.public_id}`)}>Buy Now</Button>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </>)
                                        })
                                    }
                                </div>
                            </div>}
                        </div>}
                    </Col>
                </Row>
                {/* <div className="d-flex justify-content-between col-10" style={{ paddingLeft: "50px", marginTop: '50px' }}>
                    <div style={{ fontWeight: 'bold', color: '#0F328E', borderBottom: "2px solid #0F328E", width: '200px', textAlign: 'left' }}>All Products</div>
                    <div style={{ fontWeight: 'bold', display: 'flex', cursor: 'pointer' }} onClick={() => { navigate('/productlist') }}>View All</div>
                </div>
                <DashboardProduct /> */}
                {/* <div className="d-flex justify-content-between col-10" style={{ paddingLeft: "50px", marginTop: '50px' }}>
                    <div style={{ fontWeight: 'bold', color: '#0F328E', borderBottom: "2px solid #0F328E", width: '200px', textAlign: 'left' }}>Top Brand</div>
                    <div style={{ fontWeight: 'bold', display: 'flex' }}>View All<MdOutlineArrowForwardIos style={{ fontSize: '14px', marginTop: '5px', color: '#4CB5E2' }} /></div>
                </div>
                <Brands /> */}
                </Container>
            </div>

        </>
    )

}
export default AdroitMainPage;