import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { AiOutlineShoppingCart, AiOutlineHeart } from "react-icons/ai";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import StarRatings from "react-star-ratings";
import { Input } from "reactstrap";
import Productimg from '../../Assets/images/product_1.png';
import SleeveYolk1 from '../../Assets/images/sleevyoke1.PNG';
import SleeveYolk2 from '../../Assets/images/sleevyoke2.PNG';
import Sleevyoke3 from '../../Assets/images/sleevyoke3.PNG';
import Endyoke1 from '../../Assets/images/endyoke1.PNG';
import Endyoke2 from '../../Assets/images/endyoke2.PNG';
import Endyoke3 from '../../Assets/images/endyoke3.PNG';
import Endyoke4 from '../../Assets/images/endyoke4.PNG';
import Slipshaft from '../../Assets/images/slipshaft1.PNG';
import FlangeYoke1 from '../../Assets/images/flangeyoke1.PNG';
import FlangeYoke2 from '../../Assets/images/flangeyoke2.PNG';
import FlangeYoke3 from '../../Assets/images/flangeyoke3.PNG';
import CVFlangeyoke from '../../Assets/images/cvflangeyoke.PNG';
import YokeShaft1 from '../../Assets/images/yokeshaft1.PNG';
import YokeShaft2 from '../../Assets/images/yokeshaft2.PNG';
import YokeShaft3 from '../../Assets/images/yokeshaft3.PNG';
import MidShaft1 from '../../Assets/images/midshaft1.PNG';
import MidShaft2 from '../../Assets/images/midshaft2.PNG';
import Splinedsleeve from '../../Assets/images/splinedsleeve.PNG';
import TubeYoke1 from '../../Assets/images/tubeyoke1.PNG';
import TubeYoke2 from '../../Assets/images/tubeyoke2.PNG';
import TubeYoke3 from '../../Assets/images/tubeyoke3.PNG';
import CampanionFlange1 from '../../Assets/images/sfcampanion1.PNG';
import CampanionFlange2 from '../../Assets/images/slfcampanion2.PNG';
import ShortCouple from '../../Assets/images/SAEshortcouple.PNG';
import HoleCampanion5 from '../../Assets/images/4holecampanion5.PNG';
import CampanionFlange3 from '../../Assets/images/roundcampanion3.PNG';
import Groovetype from '../../Assets/images/groove_type.PNG';
import SlipJoint from '../../Assets/images/slip_joint.PNG';
import SlipStubShaft from '../../Assets/images/slip_stub_shaft.PNG';
import Cseries from '../../Assets/images/c_series_sy.PNG';
import CseriesTy from '../../Assets/images/c_series_ty.PNG';
import CseriesYs from '../../Assets/images/c_series_ys.PNG';
import CseriesSj from '../../Assets/images/slip_joint_c_series.PNG';
import CseriesShortcouple from '../../Assets/images/short_couple_c_series.PNG';
import CenterYoke from '../../Assets/images/center_yoke.PNG';
import CvTubeyoke from '../../Assets/images/cv_tube_yoke.PNG';
import CvFlangeyoke from '../../Assets/images/cv_flange_yoke.PNG';
import SarratedFlange from '../../Assets/images/serrated_flange_yoke.PNG';
import CVheadTypeA from '../../Assets/images/CVheadA.PNG';
import CVheadTypeB from '../../Assets/images/CVheadB.PNG';
import DashboardProduct from "./DashboardProduct";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateCartQty } from "../../Redux/feature/cartSlice";

// import ReactImageMagnify from "react-image-magnify";

function ProductDetail() {

    const dispatch = useDispatch()
    // const getCart = useSelector(state => state.allcart)

    let { id } = useParams()
    let [detail, setDetail] = useState({})
    const [cartList, setCartlist] = useState([])
    const [img1, setImg1] = useState({ images: '' })
    const [img2, setImg2] = useState({ images: '' })
    const [img3, setImg3] = useState({ images: '' })
    const [img4, setImg4] = useState({ images: '' })
    const [img5, setImg5] = useState({ images: '' })
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false)

    let navigate = useNavigate()
    useEffect(() => {
        productDetail();
    }, [])
    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const productDetail = () => {
        let url = process.env.REACT_APP_BASEURL_TWO + `products/${id}/`
        const config = {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        setLoading(true)
        axios.get(url, config)
            .then(res => {

                console.log(res.data.data)
                setDetail(res.data.data)
                setImg1(res.data.data.images[0])
                setImg2(res.data.data.images[1])
                setImg3(res.data.data.images[2])
                setImg4(res.data.data.images[3])
                setImg5(res.data.data.images[4])
                setLoading(false)
            }
            ).catch(err => {
                console.log(err)
                setLoading(false)
            })
    }
    const addtoCart =  (public_id) => {
       

        setLoading(true)

        let url = process.env.REACT_APP_BASEURL_TWO + `cart/`
        const config = {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            }
        };
        const body = {
            cart_data: ([
                {
                    product_id: public_id,
                    quantity: 1,
                },
                // Add more objects if needed
            ]),
        };
        axios.post(url, body, config)
            .then(res => {
                setLoading(false)
                console.log("Add cart product", res.data)
                navigate("/showallcart");
            }

            ).catch(err => {
                console.log("errrrr", err)
                setLoading(false)
            })
        getCartlist()

    }
    const getCartlist = () => {
        setLoading(true)
        if (localStorage.getItem('token')) {
            let url = process.env.REACT_APP_BASEURL_TWO + `cart/`
            const config = {
                headers: {
                    "Authorization": localStorage.getItem("token"),
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                }
            };
            axios.get(url, config)
                .then(res => {
                    setLoading(false)
                    setCartlist(res.data.data.item_list)
                    // localStorage.setItem('count', res.data.data.length+1)
                    dispatch(updateCartQty(res.data.data.item_list.length))
                    console.log("gjgjg", res.data.data.length)
                }
                ).catch(err => {
                    // console.log(err)
                    setLoading(false)
                })
        }
        else {
            navigate('/home');
            toast.error(' your session has been expired login again.', {
                autoClose: 1000,
                theme: "colored",
            });
        }
    }

    return (
        <>
            <div style={{ marginTop: '100px', marginBottom: '180px' ,fontFamily:'roboto'}}>
                {loading ? <div className="d-flex justify-content-center"><div>
                    <img src={require('../../Assets/images/loading-waiting.gif')} alt="loading"></img>
                </div></div> :
                    <Row className="m-0 col-11">
                        {/* <Col md={12} lg={5} style={{backgroundColor:"#ededed"}}>
                        <img src={Productimg} alt="product" className="mt-5"></img>
                        <Row className="ms-0 me-0 mt-5 d-flex justify-content-center">                           
                        </Row>
                    </Col> */}
                        {selectedImage === null ?

                            <Col md={12} lg={5} style={{ backgroundColor: '#ededed', padding: '30px' }}>
                                {
                                    detail.fig === "1.0" && detail.category === 5 ?

                                        <img src={SleeveYolk1} alt="sleevyoke" style={{ height: '347px', width: '642px' }}></img>
                                        :
                                        detail.fig === "2.0" && detail.category === 5 ?
                                            <img src={SleeveYolk2} alt="sleevyoke" style={{ height: '347px', width: '642px' }}></img>
                                            :
                                            detail.fig === "3.0" && detail.category === 5 ?
                                                <img src={Sleevyoke3} alt="sleevyoke" style={{ height: '347px', width: '642px' }}></img>
                                                :
                                                detail.fig === "1.0" && detail.category === 6 ?
                                                    <img src={Endyoke1} alt="endyoke" style={{ height: '347px', width: '642px' }}></img>
                                                    :
                                                    detail.fig === "2.0" && detail.category === 6 ?
                                                        <img src={Endyoke2} alt="endyoke" style={{ height: '347px', width: '642px' }}></img>
                                                        :
                                                        detail.fig === "3.0" && detail.category === 6 ?
                                                            <img src={Endyoke3} alt="endyoke" style={{ height: '347px', width: '642px' }}></img>
                                                            :
                                                            detail.fig === "4.0" && detail.category === 6 ?
                                                                <img src={Endyoke4} alt="endyoke" style={{ height: '347px', width: '642px' }}></img>
                                                                :
                                                                detail.category === 8 ?
                                                                    <img src={Slipshaft} alt="sleevyoke" style={{ height: '347px', width: '642px' }}></img>
                                                                    :
                                                                    detail.fig === "1" && detail.category === 3 ?

                                                                        <img src={FlangeYoke1} alt="flangeyoke" style={{ height: '270px', width: '470px', }}></img>

                                                                        :
                                                                        detail.fig === "2" && detail.category === 3 ?
                                                                            <img src={FlangeYoke2} alt="flangeyoke" style={{ height: '270px', width: '470px' }}></img>
                                                                            :
                                                                            detail.fig === "3" && detail.category === 3 ?
                                                                                <img src={FlangeYoke3} alt="flangeyoke" style={{ height: '270px', width: '470px' }}></img>
                                                                                :
                                                                                detail.fig === "BLANK" && detail.category === 3 ?
                                                                                    <img src={FlangeYoke1} alt="flangeyoke" style={{ height: '270px', width: '470px' }}></img>
                                                                                    :
                                                                                    detail.fig === "CVTYP" && detail.category === 3 ?
                                                                                        <img src={CVFlangeyoke} alt="flangeyoke" style={{ height: '270px', width: '470px' }}></img>
                                                                                        :
                                                                                        detail.fig === "1" && detail.category === 12 ?
                                                                                            <img src={YokeShaft1} alt="yokeshaft" style={{ height: '347px', width: '642px' }}></img>
                                                                                            :
                                                                                            detail.fig === "2" && detail.category === 12 ?
                                                                                                <img src={YokeShaft2} alt="yokeshaft" style={{ height: '347px', width: '642px' }}></img>
                                                                                                :
                                                                                                detail.fig === "3" && detail.category === 12 ?
                                                                                                    <img src={YokeShaft3} alt="yokeshaft" style={{ height: '347px', width: '642px' }}></img>
                                                                                                    :
                                                                                                    detail.fig === "1.0" && detail.category === 9 ?
                                                                                                        <img src={MidShaft1} alt="midshaft" style={{ height: '347px', width: '642px' }}></img>
                                                                                                        :
                                                                                                        detail.fig === "2.0" && detail.category === 9 ?
                                                                                                            <img src={MidShaft2} alt="midshaft" style={{ height: '347px', width: '642px' }}></img>
                                                                                                            :
                                                                                                            detail.category === 11 ?
                                                                                                                <img src={Splinedsleeve} alt="splinedsleeve" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                :
                                                                                                                detail.fig === "1.0" && detail.category === 7 ?
                                                                                                                    <img src={TubeYoke1} alt="tubeyoke" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                    :
                                                                                                                    detail.fig === "2.0" && detail.category === 7 ?
                                                                                                                        <img src={TubeYoke2} alt="tubeyoke" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                        :
                                                                                                                        detail.fig === "3.0" && detail.category === 7 ?
                                                                                                                            <img src={TubeYoke3} alt="tubeyoke" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                            :
                                                                                                                            detail.fig === "1" && detail.category === 1 ?
                                                                                                                                <img src={CampanionFlange1} alt="campanion" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                                :
                                                                                                                                detail.fig === "2" && detail.category === 1 ?
                                                                                                                                    <img src={CampanionFlange2} alt="campanion" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                                    :
                                                                                                                                    detail.fig === "5" && detail.category === 1 ?
                                                                                                                                        <img src={HoleCampanion5} alt="campanion" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                                        :
                                                                                                                                        detail.fig === "3" && detail.category === 1 ?
                                                                                                                                            <img src={CampanionFlange3} alt="campanion" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                                            :
                                                                                                                                            detail.category === 26 ?
                                                                                                                                                <img src={ShortCouple} alt="shortcouple" style={{ height: '347px', width: '642px' }}></img> :
                                                                                                                                                detail.internal_category === "TY-GWB" && detail.category === 13 ?
                                                                                                                                                    <img src={Groovetype} alt="shortcouple" style={{ height: '347px', width: '642px' }}></img> :
                                                                                                                                                    detail.internal_category === "FY Serrated - GWB" && detail.category === 13 ?
                                                                                                                                                        <img src={SarratedFlange} alt="shortcouple" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                                                        : detail.internal_category === "SJ-GWB" && detail.category === 13 ?
                                                                                                                                                            <img src={SlipJoint} alt="shortcouple" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                                                            : detail.internal_category === "C-SSS" && detail.category === 17 ?
                                                                                                                                                                <img src={SlipStubShaft} alt="shortcouple" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                                                                : detail.internal_category === "C-SY" && detail.category === 17 ?
                                                                                                                                                                    <img src={Cseries} alt="shortcouple" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                                                                    : detail.internal_category === "C-TY" && detail.category === 17 ?
                                                                                                                                                                        <img src={CseriesTy} alt="shortcouple" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                                                                        : detail.internal_category === "C-YS" && detail.category === 17 ?
                                                                                                                                                                            <img src={CseriesYs} alt="shortcouple" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                                                                            : detail.internal_category === "C-SJ" && detail.category === 17 ?
                                                                                                                                                                                <img src={CseriesSj} alt="shortcouple" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                                                                                : detail.internal_category === "C-SC" && detail.category === 17 ?
                                                                                                                                                                                    <img src={CseriesShortcouple} alt="shortcouple" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                                                                                    : detail.internal_category === "H-Yoke-Center Yoke" && detail.category === 35 ?
                                                                                                                                                                                        <img src={CenterYoke} alt="shortcouple" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                                                                                        : detail.internal_category === "Ball Stud Yoke1" && detail.category === 35 ?
                                                                                                                                                                                            <img src={CvTubeyoke} alt="shortcouple" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                                                                                            : detail.internal_category === "Ball Stud Yoke" && detail.category === 35 ?
                                                                                                                                                                                                <img src={CvTubeyoke} alt="shortcouple" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                                                                                                : detail.internal_category === "Center Yoke" && detail.category === 35 ?
                                                                                                                                                                                                    <img src={CvFlangeyoke} alt="shortcouple" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                                                                                                    : detail.internal_category === "1310 CV Head" && detail.category === 35 ?
                                                                                                                                                                                                        <img src={CVheadTypeA} alt="cvhead" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                                                                                                        : detail.internal_category === "1350 CV Head" && detail.category === 35 ?
                                                                                                                                                                                                            <img src={CVheadTypeB} alt="cvhead" style={{ height: '347px', width: '642px' }}></img>
                                                                                                                                                                                                            :
                                                                                                                                                                                                            img1 ?
                                                                                                                                                                                                                <img src={img1} alt="cvhead" style={{ height: '347px', width: '642px' }}></img> :
                                                                                                                                                                                                                <img src={Productimg} alt="cvhead" style={{ height: '347px', width: '642px' }}></img>


                                }

                                <Row className='d-flex justify-content-center mt-3'>
                                    {img1 ? <Col className="col-2 ms-2"> <img src={img1} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: '80px' }} alt="product" onClick={() => handleImageClick(img1)}></img></Col> : null}
                                    {img2 ? <Col className="col-2 ms-2"> <img src={img2} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: '80px' }} alt="product" onClick={() => handleImageClick(img2)}></img></Col> : null}
                                    {img3 ? <Col className="col-2 ms-2"> <img src={img3} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: '80px' }} onClick={() => handleImageClick(img3)} alt="product"></img></Col> : null}
                                    {img4 ? <Col className="col-2 ms-2"> <img src={img4} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(img4)} alt="product"></img></Col> : null}

                                </Row>
                            </Col> :

                            <Col md={12} lg={5} style={{ backgroundColor: '#ededed', padding: '30px' }}>

                                {selectedImage ? <img src={selectedImage} alt="Selected" style={{ height: '347px', width: '642px' }} /> : <img src={Productimg} alt="product" style={{ height: '270px', width: '470px' }} />}
                                <Row className='d-flex justify-content-center mt-3' style={{ backgroundColor: '' }}>
                                    {img1 ? <Col className="col-2 ms-2"> <img src={img1} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: '80px' }} alt="product" onClick={() => handleImageClick(img1)}></img></Col> : null}
                                    {img2 ? <Col className="col-2 ms-2"> <img src={img2} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: '80px' }} alt="product" onClick={() => handleImageClick(img2)}></img></Col> : null}
                                    {img3 ? <Col className="col-2 ms-2"> <img src={img3} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: '80px' }} onClick={() => handleImageClick(img3)} alt="product"></img></Col> : null}
                                    {img4 ? <Col className="col-2 ms-2"> <img src={img4} style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(img4)} alt="product"></img></Col> : null}
                                    <Col className="col-2 ms-2">
                                        {
                                            detail.fig === "1.0" && detail.category === 5 ?

                                                <img src={SleeveYolk1} alt="sleevyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(SleeveYolk1)}></img>
                                                :
                                                detail.fig === "2.0" && detail.category === 5 ?
                                                    <img src={SleeveYolk2} alt="sleevyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(SleeveYolk2)}></img>
                                                    :
                                                    detail.fig === "3.0" && detail.category === 5 ?
                                                        <img src={Sleevyoke3} alt="sleevyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Sleevyoke3)}></img>
                                                        :
                                                        detail.fig === "1.0" && detail.category === 6 ?
                                                            <img src={Endyoke1} alt="endyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Endyoke1)}></img>
                                                            :
                                                            detail.fig === "2.0" && detail.category === 6 ?
                                                                <img src={Endyoke2} alt="endyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Endyoke2)}></img>
                                                                :
                                                                detail.fig === "3.0" && detail.category === 6 ?
                                                                    <img src={Endyoke3} alt="endyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Endyoke3)}></img>
                                                                    :
                                                                    detail.fig === "4.0" && detail.category === 6 ?
                                                                        <img src={Endyoke4} alt="endyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Endyoke4)}></img>
                                                                        :
                                                                        detail.category === 8 ?
                                                                            <img src={Slipshaft} alt="sleevyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Slipshaft)}></img>
                                                                            :
                                                                            detail.fig === "1" && detail.category === 3 ?

                                                                                <img src={FlangeYoke1} alt="flangeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(FlangeYoke1)}></img>

                                                                                :
                                                                                detail.fig === "2" && detail.category === 3 ?
                                                                                    <img src={FlangeYoke2} alt="flangeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(FlangeYoke2)}></img>
                                                                                    :
                                                                                    detail.fig === "3" && detail.category === 3 ?
                                                                                        <img src={FlangeYoke3} alt="flangeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(FlangeYoke3)}></img>
                                                                                        :
                                                                                        detail.fig === "BLANK" && detail.category === 3 ?
                                                                                            <img src={FlangeYoke1} alt="flangeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(FlangeYoke1)}></img>
                                                                                            :
                                                                                            detail.fig === "CVTYP" && detail.category === 3 ?
                                                                                                <img src={CVFlangeyoke} alt="flangeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CVFlangeyoke)}></img>
                                                                                                :
                                                                                                detail.fig === "1" && detail.category === 12 ?
                                                                                                    <img src={YokeShaft1} alt="yokeshaft" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(YokeShaft1)}></img>
                                                                                                    :
                                                                                                    detail.fig === "2" && detail.category === 12 ?
                                                                                                        <img src={YokeShaft2} alt="yokeshaft" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(YokeShaft2)}></img>
                                                                                                        :
                                                                                                        detail.fig === "3" && detail.category === 12 ?
                                                                                                            <img src={YokeShaft3} alt="yokeshaft" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(YokeShaft3)}></img>
                                                                                                            :
                                                                                                            detail.fig === "1.0" && detail.category === 9 ?
                                                                                                                <img src={MidShaft1} alt="midshaft" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(MidShaft1)}></img>
                                                                                                                :
                                                                                                                detail.fig === "2.0" && detail.category === 9 ?
                                                                                                                    <img src={MidShaft2} alt="midshaft" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(MidShaft2)}></img>
                                                                                                                    :
                                                                                                                    detail.category === 11 ?
                                                                                                                        <img src={Splinedsleeve} alt="splinedsleeve" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Splinedsleeve)}></img>
                                                                                                                        :
                                                                                                                        detail.fig === "1.0" && detail.category === 7 ?
                                                                                                                            <img src={TubeYoke1} alt="tubeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(TubeYoke1)}></img>
                                                                                                                            :
                                                                                                                            detail.fig === "2.0" && detail.category === 7 ?
                                                                                                                                <img src={TubeYoke2} alt="tubeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(TubeYoke2)}></img>
                                                                                                                                :
                                                                                                                                detail.fig === "3.0" && detail.category === 7 ?
                                                                                                                                    <img src={TubeYoke3} alt="tubeyoke" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(TubeYoke3)}></img>
                                                                                                                                    :
                                                                                                                                    detail.fig === "1" && detail.category === 1 ?
                                                                                                                                        <img src={CampanionFlange1} alt="campanion" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CampanionFlange1)}></img>
                                                                                                                                        :
                                                                                                                                        detail.fig === "2" && detail.category === 1 ?
                                                                                                                                            <img src={CampanionFlange2} alt="campanion" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CampanionFlange2)}></img>
                                                                                                                                            :
                                                                                                                                            detail.fig === "5" && detail.category === 1 ?
                                                                                                                                                <img src={HoleCampanion5} alt="campanion" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(HoleCampanion5)}></img>
                                                                                                                                                :
                                                                                                                                                detail.fig === "3" && detail.category === 1 ?
                                                                                                                                                    <img src={CampanionFlange3} alt="campanion" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CampanionFlange3)}></img>
                                                                                                                                                    :
                                                                                                                                                    detail.category === 26 ?
                                                                                                                                                        <img src={ShortCouple} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(ShortCouple)}></img> :
                                                                                                                                                        detail.internal_category === "TY-GWB" && detail.category === 13 ?
                                                                                                                                                            <img src={Groovetype} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Groovetype)}></img> :
                                                                                                                                                            detail.internal_category === "FY Serrated - GWB" && detail.category === 13 ?
                                                                                                                                                                <img src={SarratedFlange} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(SarratedFlange)}></img>
                                                                                                                                                                : detail.internal_category === "SJ-GWB" && detail.category === 13 ?
                                                                                                                                                                    <img src={SlipJoint} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(SlipJoint)}></img>
                                                                                                                                                                    : detail.internal_category === "C-SSS" && detail.category === 17 ?
                                                                                                                                                                        <img src={SlipStubShaft} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(SlipStubShaft)}></img>
                                                                                                                                                                        : detail.internal_category === "C-SY" && detail.category === 17 ?
                                                                                                                                                                            <img src={Cseries} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(Cseries)}></img>
                                                                                                                                                                            : detail.internal_category === "C-TY" && detail.category === 17 ?
                                                                                                                                                                                <img src={CseriesTy} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CseriesTy)}></img>
                                                                                                                                                                                : detail.internal_category === "C-YS" && detail.category === 17 ?
                                                                                                                                                                                    <img src={CseriesYs} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CseriesYs)}></img>
                                                                                                                                                                                    : detail.internal_category === "C-SJ" && detail.category === 17 ?
                                                                                                                                                                                        <img src={CseriesSj} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CseriesSj)}></img>
                                                                                                                                                                                        : detail.internal_category === "C-SC" && detail.category === 17 ?
                                                                                                                                                                                            <img src={CseriesShortcouple} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CseriesShortcouple)}></img>
                                                                                                                                                                                            : detail.internal_category === "H-Yoke-Center Yoke" && detail.category === 35 ?
                                                                                                                                                                                                <img src={CenterYoke} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CenterYoke)}></img>
                                                                                                                                                                                                : detail.internal_category === "Ball Stud Yoke1" && detail.category === 35 ?
                                                                                                                                                                                                    <img src={CvTubeyoke} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CvTubeyoke)}></img>
                                                                                                                                                                                                    : detail.internal_category === "Ball Stud Yoke" && detail.category === 35 ?
                                                                                                                                                                                                        <img src={CvTubeyoke} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CvTubeyoke)}></img>
                                                                                                                                                                                                        : detail.internal_category === "Center Yoke" && detail.category === 35 ?
                                                                                                                                                                                                            <img src={CvFlangeyoke} alt="shortcouple" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CvFlangeyoke)}></img>
                                                                                                                                                                                                            : detail.internal_category === "1310 CV Head" && detail.category === 35 ?
                                                                                                                                                                                                                <img src={CVheadTypeA} alt="cvhead" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CVheadTypeA)}></img>
                                                                                                                                                                                                                : detail.internal_category === "1350 CV Head" && detail.category === 35 ?
                                                                                                                                                                                                                    <img src={CVheadTypeB} alt="cvhead" style={{ cursor: 'pointer', border: '1px solid gray', borderRadius: '10px', width: '100%', height: "80px" }} onClick={() => handleImageClick(CVheadTypeB)}></img>
                                                                                                                                                                                                                    :
                                                                                                                                                                                                                    null


                                        }
                                    </Col>
                                </Row>
                            </Col>


                        }
                        <Col></Col>
                        <Col md={12} lg={6}>
                            <div style={{ textAlign: "left" }} className='col-10'>
                                <span style={{ color: '#0F328E', fontSize: '26px', fontWeight: '600',lineHeight:'36.4px'}}>{detail.series} / {detail.part_number} / {detail.category_name}</span>
                                <div><StarRatings rating={4}
                                    starDimension="20px"
                                    starSpacing="5px"
                                    starRatedColor='#FDBC15'
                                /> <span style={{ color: '#A9A9A9' }}>(1 customer review)</span></div>

                            </div>
                            <div style={{ textAlign: "left", color: "#6C6C6C", fontSize: '14px', marginTop: '10px' }} className='col-10' >
                                <span>
                                    Description:-  {detail.description}
                                </span>
                            </div>
                            <Row style={{ textAlign: "left", marginTop: "20px", paddingLeft: '20px', marginRight: '0px ' }}>
                                <Col lg={6}>
                                {detail.series !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>Series : </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.series}</span></div> : null}
                                    <div className="mt-2"><span style={{color:'#6C6C6C'}}>Category Name :</span>  <span style={{ color: 'black', fontWeight: '400' }}> {detail.category_name}</span></div>
                                    <div className="mt-2"> <span style={{color:'#6C6C6C'}}>Part Number : </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.part_number} </span></div>
                                    <div className="mt-2"> <span style={{color:'#6C6C6C'}}>Category : </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.category}</span></div>
                                    {detail.fig !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}></span><span style={{color:'#6C6C6C'}}>Fig : </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.fig} </span></div> : null}
                                    {detail.dim_f_key_height !== null ? <div className="mt-2"><span style={{color:'#6C6C6C'}}> Ddim F Key Height : </span><span style={{ color: 'black', fontWeight: '400' }}> {detail.dim_f_key_height}</span></div> : null}
                                    {detail.dim_k_key_width !== null ? <div className="mt-2"><span style={{color:'#6C6C6C'}}>Dim K Key Width : </span><span style={{ color: 'black', fontWeight: '400' }}> {detail.dim_k_key_width}</span></div> : null}
                                    {detail.dim_d_bore !== null ? <div className="mt-2"><span style={{color:'#6C6C6C'}}>Dim D Bore : </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.dim_d_bore}</span></div> : null}
                                    {detail.center_line_to_end !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>Center Line To end :  </span><span style={{ color: 'black', fontWeight: '400' }}> {detail.center_line_to_end}</span></div> : null}
                                    {detail.center_line_to_end_mm !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>Center Line To end MM:  </span><span style={{ color: 'black', fontWeight: '400' }}> {detail.center_line_to_end_mm}</span></div> : null}
                                    {detail.flange_diameter !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}>Flange Diameter :  </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.flange_diameter} </span></div> : null}
                                    {detail.pilot_dia_c !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>Pilot Dia C :  </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.pilot_dia_c} </span></div> : null}
                                    {detail.pcd !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}> PCD : </span> <span style={{ color: 'black', fontWeight: '400' }}>{detail.pcd} </span></div> : null}
                                    {detail.thread_size !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}> Thread Size : </span> <span style={{ color: 'black', fontWeight: '400' }}>{detail.thread_size} </span></div> : null}
                                    {detail.tube_wall_thickness !== null ? <div className="mt-2"><span style={{color:'#6C6C6C'}}>  Tube Wall Thickness :</span>  <span style={{ color: 'black', fontWeight: '400' }}>{detail.tube_wall_thickness} </span></div> : null}
                                    {detail.spline_size_bore_key_way !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}> Spline Size Bore Key Way : </span> <span style={{ color: 'black', fontWeight: '400' }}>{detail.spline_size_bore_key_way} </span></div> : null}
                                    {detail.uj_size !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}>Uj Size : </span> <span style={{ color: 'black', fontWeight: '400' }}>{detail.uj_size} </span></div> : null}
                                    {detail.spline !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>Spline : </span> <span style={{ color: 'black', fontWeight: '400' }}>{detail.spline} </span></div> : null}
                                    {detail.f_to_end_g !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}> F To end G :</span> <span style={{ color: 'black', fontWeight: '400' }}>{detail.f_to_end_g} </span></div> : null}
                                    {detail.face_to_weld_lc !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}> Face To Weld Lc : </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.face_to_weld_lc} </span></div> : null}
                                    {detail.bolt_holes !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}> Bolt Holes : </span> <span style={{ color: 'black', fontWeight: '400' }}>{detail.bolt_holes} </span></div> : null}
                                    {detail.joint_angles !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>  Joint Angles : </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.joint_angles} </span></div> : null}
                                    {detail.tube_size !== null ? <div className="mt-2">   <span style={{color:'#6C6C6C'}}>Tube Size : </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.tube_size} </span></div> : null}
                                    {detail.f_2_f !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>  F2F : </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.f_2_f} </span></div> : null}
                                    {detail.adroit_drg_no !== null ? <div className="mt-2">  Adroit DrgNo : <span style={{ color: 'black', fontWeight: '400' }}>{detail.adroit_drg_no} </span></div> : null}
                                    {detail.reference_part_no !== null ? <div className="mt-2"><span style={{color:'#6C6C6C'}}>  Reference Part Number : </span> <span style={{ color: 'black', fontWeight: '400' }}>{detail.reference_part_no} </span></div> : null}
                                    {detail.beta_alpha_angle !== null ? <div className="mt-2"><span style={{color:'#6C6C6C'}}>   Beta Alpha Angle :  </span> <span style={{ color: 'black', fontWeight: '400' }}>{detail.beta_alpha_angle} </span></div> : null}
                                    {detail.no_of_holes_and_hole_dia !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>  No Of Holes And Hole Diameter : </span>  <span style={{ color: 'black', fontWeight: '400' }}>{detail.no_of_holes_and_hole_dia} </span></div> : null}
                                    {/* {detail.c_l_to_weld_h !== null ? <div className="mt-2"> Flange or Swing Diameter :  <span style={{ color: 'black', fontWeight: '400' }}>{detail.c_l_to_weld_h} </span></div> : null} */}
                                    {/* {detail.c_l_to_weld_h_mm !== null ? <div className="mt-2"> Flange or Swing Diameter :  <span style={{ color: 'black', fontWeight: '400' }}>{detail.c_l_to_weld_h_mm} </span></div> : null} */}
                                    {detail.secondary_part_number !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>  Secondary Part No. : </span>  <span style={{ color: 'black', fontWeight: '400' }}>{detail.secondary_part_number} </span></div> : null}
                                    {detail.stud_socket_dimension !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>  Stud Socket Dimension :  </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.stud_socket_dimension} </span></div> : null}
 
                                </Col>
                                <Col lg={6}>
                                    {detail.a !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}> Flange or Swing Diameter : </span> <span style={{ color: 'black', fontWeight: '400' }}>{detail.a} </span></div> : null}
                                    {detail.a_mm !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}> Flange or Swing Diameter MM: </span> <span style={{ color: 'black', fontWeight: '400' }}>{detail.a_mm} </span></div> : null}
                                    {detail.b !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>  PCD : </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.b} </span></div> : null}
                                    {detail.b_mm !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>  PCD MM : </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.b_mm} </span></div> : null}
                                    {detail.c !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}> Pilot Diameter : </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.c} </span></div> : null}
                                    {detail.d !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>  Universal Joint Bearing Diameter :</span>  <span style={{ color: 'black', fontWeight: '400' }}> {detail.d} </span></div> : null}
                                    {detail.d_mm !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>  Universal Joint Bearing Diameter MM: </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.d_mm} </span></div> : null}
                                    {detail.d1_mm !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>  Universal Joint Bearing Diameter1 MM : </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.d1_mm} </span></div> : null}
                                    {detail.e !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}> Outside Yoke Dimension : </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.e} </span></div> : null}
                                    {detail.e_mm !== null ? <div className="mt-2"><span style={{color:'#6C6C6C'}}>  Outside Yoke Dimension MM : </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.e_mm} </span></div> : null}
                                    {detail.f !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}> Center To Face Of Plug : </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.f} </span></div> : null}
                                    {detail.f_mm !== null ? <div className="mt-2"><span style={{color:'#6C6C6C'}}>  Center To Face Of Plug MM :  </span><span style={{ color: 'black', fontWeight: '400' }}> {detail.f_mm} </span></div> : null}
                                    {detail.g !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}> Flange Face To Ear Bore Center Line : </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.g} </span></div> : null}
                                    {detail.g1_mm !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>Flange Face To Ear Bore Center Line1 MM: </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.g1_mm} </span></div> : null}
                                    {detail.g2_mm !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>Flange Face To Ear Bore Center Line2 MM : </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.g2_mm} </span></div> : null}
                                    {detail.h !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}> Bearing Cap Dia: </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.h} </span></div> : null}
                                    {detail.h_mm !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>Bearing Cap Dia MM: </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.h_mm} </span></div> : null}
                                    {/* {detail.i !== null ? <div className="mt-2">  Center To Face Of Plug :  <span style={{ color: 'black', fontWeight: '400' }}> {detail.i} </span></div> : null} */}
                                    {detail.j !== null ? <div className="mt-2"><span style={{color:'#6C6C6C'}}> Center  To End Of Spline  :</span>   <span style={{ color: 'black', fontWeight: '400' }}> {detail.j} </span></div> : null}
                                    {detail.k !== null ? <div className="mt-2"><span style={{color:'#6C6C6C'}}>  End Of Spline To Weld  :</span>   <span style={{ color: 'black', fontWeight: '400' }}> {detail.k} </span></div> : null}
                                    {detail.l !== null ? <div className="mt-2"><span style={{color:'#6C6C6C'}}> Spline Length : </span>  <span style={{ color: 'black', fontWeight: '400' }}> {detail.l} </span></div> : null}
                                    {detail.l_mm !== null ? <div className="mt-2"><span style={{color:'#6C6C6C'}}> Spline Length MM:</span>   <span style={{ color: 'black', fontWeight: '400' }}> {detail.l_mm} </span></div> : null}
                                    {detail.m !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>Groove Disatance :  </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.m} </span></div> : null}
                                    {detail.m_mm !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>Groove Disatance MM: </span>  <span style={{ color: 'black', fontWeight: '400' }}> {detail.m_mm} </span></div> : null}
                                    {detail.n !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}> Bearing Cap Dia  : </span>  <span style={{ color: 'black', fontWeight: '400' }}> {detail.n} </span></div> : null}
                                    {detail.n_mm !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}> Bearing Cap Dia MM  : </span>  <span style={{ color: 'black', fontWeight: '400' }}> {detail.n_mm} </span></div> : null}
                                    {detail.p !== null ? <div className="mt-2"><span style={{color:'#6C6C6C'}}>  Pitch Circle Diameter Of Flange Holes :  </span><span style={{ color: 'black', fontWeight: '400' }}> {detail.p} </span></div> : null}
                                    {detail.p_mm !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}> Pitch Circle Diameter Of Flange Holes MM: </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.p_mm} </span></div> : null}
                                    {detail.r !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}>  Radius Length  : </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.r} </span></div> : null}
                                    {detail.slip !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}>  Slip :</span> <span style={{ color: 'black', fontWeight: '400' }}>{detail.slip} </span></div> : null}
                                    {/* {detail.s !== null ? <div className="mt-2"> Slip :  <span style={{ color: 'black', fontWeight: '400' }}> {detail.s} </span></div> : null}                                 */}
                                    {detail.y !== null ? <div className="mt-2">   <span style={{color:'#6C6C6C'}}> Spline End To Brg Shoulder : </span> <span style={{ color: 'black', fontWeight: '400' }}> {detail.y} </span></div> : null}
                                    {detail.hole_dia_number !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}>  Hole Diameter Number : </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.hole_dia_number} </span></div> : null}
                                    {detail.spline_size !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}>  Spline Size : </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.spline_size} </span></div> : null}
                                    {detail.ground_hub_dia !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}>  Ground Hub Diameter : </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.ground_hub_dia} </span></div> : null}
                                    {detail.ground_hub_dia_mm !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}>  Ground Hub Diameter MM :</span> <span style={{ color: 'black', fontWeight: '400' }}>{detail.ground_hub_dia_mm} </span></div> : null}
                                    {detail.length_of_hole !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}>  Lenght Of Hole : </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.length_of_hole} </span></div> : null}
                                    {detail.t_diameter_and_w_thickness !== null ? <div className="mt-2"><span style={{color:'#6C6C6C'}}> T Diameter & W Thickness: </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.t_diameter_and_w_thickness} </span></div> : null}
                                    {detail.tube_diameter_and_wall_thickness !== null ? <div className="mt-2"><span style={{color:'#6C6C6C'}}> Tube Diameter & Wall Thickness: </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.tube_diameter_and_wall_thickness} </span></div> : null}
                                    {detail.spline_data !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}> Spline Data : </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.spline_data} </span></div> : null}
                                    {detail.tube_diameter !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}> Tube Diameter : </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.tube_diameter} </span></div> : null}
                                    {detail.tube_diameter_mm !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>  Tube Diameter MM : </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.tube_diameter_mm} </span></div> : null}
                                    {detail.tube_thickness !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>  Tube Thickness : </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.tube_thickness} </span></div> : null}
                                    {detail.end_of_spline_to_radius !== null ? <div className="mt-2"> <span style={{color:'#6C6C6C'}}>  End Of Spline To Radius :</span> <span style={{ color: 'black', fontWeight: '400' }}>{detail.end_of_spline_to_radius} </span></div> : null}
                                    {detail.end_of_spline_to_radius_mm !== null ? <div className="mt-2"><span style={{color:'#6C6C6C'}}>   End Of  Spline To Radius MM :</span> <span style={{ color: 'black', fontWeight: '400' }}>{detail.end_of_spline_to_radius_mm} </span></div> : null}
                                    {detail.spline_length !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}> Spline Length :</span> <span style={{ color: 'black', fontWeight: '400' }}>{detail.spline_length} </span></div> : null}
                                    {detail.spline_length_mm !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}> Spline Length MM: </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.spline_length_mm} </span></div> : null}
                                    {detail.spline !== null ? <div className="mt-2">  <span style={{color:'#6C6C6C'}}> Spline  : </span><span style={{ color: 'black', fontWeight: '400' }}>{detail.spline} </span></div> : null}

                                </Col>
                            </Row>
                            <Row className="col-11" style={{ textAlign: "left", marginTop: "20px", paddingLeft: '20px', marginRight: '0px ' }}>
                                <Col lg={8} >
                                    <h5>Note:- <span style={{ color: '#0F328E', fontSize: '18px', }}>Shipment Price is Added After Checkout</span></h5>
                                </Col>
                                {/* <Col lg={6} className='mt-1'>
                                    <span style={{ color: 'black', fontWeight: '400', fontSize: '16px' }}>Check Delivery</span>
                                    <div className="mt-2 d-flex">
                                        <Input style={{ border: '1px solid #D9D9D9', borderRadius: '4px', borderRight: "none", padding: "7px 15px 8px 15px" }} placeholder='Enter Pincode/Zipcode'></Input>
                                        <button style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', borderLeft: '0px', background: '#0F328E', borderRadius: '4px', color: 'white', border: 'none', padding: '0px 20px 0px 20px' }}>Check</button>
                                    </div>
                                </Col> */}
                            </Row>
                            <Row style={{ textAlign: "Right", marginTop: "20px", marginLeft: '0px', marginRight: '0px ', border: '1px solid #D9D9D9', borderRadius: '5px', padding: '10px 10px 15px 10px' }} className='col-11 '>
                                <Col className="mt-2 d-flex justify-content-lg-start">
                                    <span style={{ color: 'black', fontWeight: '400', fontSize: '20px', }}>$ {detail.product_pricing}</span>
                                    {/* <div><span style={{ color: 'black', textDecoration: 'line-through', fontSize: '14px' }}>₹14999</span> <span style={{ color: "#249B3E", fontSize: '14px' }}>Save - ₹4500</span></div> */}

                                </Col>
                                <Col className='mt-1'>
                                    <div> <Button style={{ background: "#4CB5E2", border: "none", padding: '12px 15px 12px 15px', }} onClick={() => addtoCart(detail.public_id)} ><AiOutlineShoppingCart /> Add to Cart</Button></div>
                                </Col>

                            </Row>
                            {/* <Row style={{ textAlign: "left" }}>
                            <Col style={{ marginLeft: "15px", color: 'black' }}>
                                <AiOutlineHeart style={{ color: '#4CB5E2', fontSize: '18px', fontWeight: 'bolder', }} /> Add to my wish list
                            </Col>
                        </Row> */}
                        </Col>

                    </Row>
                }
                {/* <Col lg={10} style={{ paddingLeft: '50px', marginTop: '40px' }}><TabsNav /></Col> */}
                {/* <div className="d-flex justify-content-between col-10" style={{ marginTop: '50px', paddingLeft: "50px" }}>
                    <div style={{ fontWeight: 'bold', color: '#0F328E', borderBottom: "2px solid #0F328E", width: '200px', textAlign: 'left' }}>Related Products </div>
                    <div style={{ fontWeight: 'bold', display: 'flex' }}>View All <MdOutlineArrowForwardIos style={{ fontSize: '14px', marginTop: '5px', color: '#4CB5E2' }} /></div>
                </div>
                <DashboardProduct /> */}
                {/* <div className="d-flex justify-content-between mt-5 col-10" style={{ paddingLeft: "50px" }}>
                    <div style={{ fontWeight: 'bold', color: '#0F328E', borderBottom: "2px solid #0F328E", width: '200px', textAlign: 'left' }}>Top Brand</div>
                    <div style={{ fontWeight: 'bold', display: 'flex' }}>View All<MdOutlineArrowForwardIos style={{ fontSize: '14px', marginTop: '5px', color: '#4CB5E2' }} /></div>
                </div>
                <Brands /> */}
            </div>
        </>
    )
}
export default ProductDetail;