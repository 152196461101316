import React, { useEffect, useRef, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
const OurProduct = () => {
    const location = useLocation();
    const [showMore, setShowMore] = useState(false);
    const [propellershaft, setPropellershaft] = useState(' Cardan shafts, also known as propeller shafts or driveshafts, are mechanical devices used to transmit torque and rotational motion between two shafts that are not aligned. They are commonly found in vehicles and machinery, particularly in drivetrains and power transmission systems. A cardan shaft consists of a series of universal joints connected by a solid or tubular shaft. Each universal joint has two yokes connected by a cross-shaped bearing, which allows for angular misalignment between the input and output shafts. The universal joints typically have needle bearings or ball bearings to facilitate smooth rotation and reduce friction. Cardan shafts are designed to accommodate changes in angle, length, and misalignment between the connected shafts. They transmit torque from the driving shaft to the driven shaft while allowing for the flexibility and movement required in applications where the two shafts are not parallel or have different angles. Cardan shafts are widely used in various industries, including automotive, agriculture, mining, construction, and industrial machinery. They are commonly found in rear-wheel- drive vehicles, where they transmit torque from the transmission to the rear differential, enabling power transfer to the wheels. In addition to their use in vehicles, cardan shafts are employed in industrial applications such as pumps, compressors, conveyors, and other equipment that require torque transmission across non-aligned shafts. Overall, cardan shafts play a crucial role in power transmission by providing a flexible and efficient means of transmitting torque between non-aligned shafts, allowing for smooth operation and compensating for misalignment and angular changes.')

    // let xyz = location.state
    // useEffect((xyz) => {
    //     if (xyz !== undefined || null) {
    //         document.getElementById(xyz).scrollIntoView()
    //     }
    // }, [])

    // const targetRef = useRef();
    // useEffect(() => {
    //     targetRef.current.scrollIntoView({ behavior: 'smooth' });
    //     console.log("Teaget ", targetRef.current);
    // }, []);
    useEffect(() => {
        console.log(window.location.hash)
        if (window.location.hash) {
            const targetElement = document.querySelector(window.location.hash);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [window.location.hash]);

    return (
        <div>
            <div style={{ marginTop: '40px', fontFamily: 'roboto' }} className='container-fluid'>
                <div className='background_contain2'>
                    <div className="content-block">
                        <div>
                            <div className="section-full call-action " >
                                <div className="container">
                                    <div className="col-lg-12 section-head text-center">
                                        <h5 className="title">Our Products</h5>
                                        <div className="dlab-separator-outer">
                                            <div className="dlab-separator bg-primary style-skew" />
                                        </div>
                                        {/* <h2 className="font-weight-700 m-b0">What Defines Adroit</h2> */}
                                    </div>
                                </div>
                            </div>
                            {/* range of product */}

                            {/* propeller carden */}
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className='col-10'>
                                    <div style={{ background: '#F2F7FA', }} >
                                        <Row id="cardenshaft" className='d-flex justify-content-center col-12 p-4'>
                                            <Col lg={5} md={10} className='d-flex justify-content-center '  >
                                                <img src={require('../../Assets/images/propeller.png')} style={{ maxHeight: '360px', aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center ' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    {/* <h4 className="title">Forging Division</h4> */}
                                                    <h2 className="title text-left text-uppercase" style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>Propeller Shafts/ Cardan Shafts/ Driveshafts:</h2>
                                                    {/* <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        Cardan shafts, also known as propeller shafts or driveshafts, are mechanical devices
                                                        used to transmit torque and rotational motion between two shafts that are not aligned.
                                                        They are commonly found in vehicles and machinery, particularly in drivetrains and
                                                        power transmission systems.
                                                        A cardan shaft consists of a series of universal joints connected by a solid or tubular
                                                        shaft. Each universal joint has two yokes connected by a cross-shaped bearing, which
                                                        allows for angular misalignment between the input and output shafts. The universal
                                                        joints typically have needle bearings or ball bearings to facilitate smooth rotation and
                                                        reduce friction.
                                                        Cardan shafts are designed to accommodate changes in angle, length, and
                                                        misalignment between the connected shafts. They transmit torque from the driving
                                                        shaft to the driven shaft while allowing for the flexibility and movement required in
                                                        applications where the two shafts are not parallel or have different angles.
                                                        Cardan shafts are widely used in various industries, including automotive, agriculture,
                                                        mining, construction, and industrial machinery. They are commonly found in rear-wheel-
                                                        drive vehicles, where they transmit torque from the transmission to the rear differential,
                                                        enabling power transfer to the wheels.
                                                        In addition to their use in vehicles, cardan shafts are employed in industrial applications
                                                        such as pumps, compressors, conveyors, and other equipment that require torque
                                                        transmission across non-aligned shafts.
                                                        Overall, cardan shafts play a crucial role in power transmission by providing a flexible
                                                        and efficient means of transmitting torque between non-aligned shafts, allowing for
                                                        smooth operation and compensating for misalignment and angular changes.

                                                    </p> */}
                                                    <p style={{ fontSize: '14px', color: '#767676' }}>
                                                        {!showMore ? <>
                                                            {propellershaft.substring(0, 500)}......<span style={{ marginLeft: '10px', fontWeight: 'bold', color: '#4cb5e2', fontSize: "18px", textDecoration: "underline", cursor: 'pointer' }} onClick={() => { setShowMore(!showMore) }}>Read More</span>
                                                        </> : <>{propellershaft}<span style={{ marginLeft: '10px', fontWeight: 'bold', color: '#0f328e', fontSize: "18px", textDecoration: "underline", cursor: 'pointer' }} onClick={() => { setShowMore(!showMore) }}>Read Less</span> </>}
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>

                            {/* Universal joints */}
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <div className='col-10'>
                                    <div style={{ background: '#E7ECF5', }} >
                                        <Row className='d-flex justify-content-center col-12' id="universaljoint" >
                                            <Col lg={5} md={10} className='d-flex justify-content-center '  >
                                                <img src={require('../../Assets/images/product_05.png')} style={{ borderRadius: '5%', aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center pt-2' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    {/* <h4 className="title">Forging Division</h4> */}
                                                    <h2 className="title text-left text-uppercase" style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>UNIVERSAL JOINTS:</h2>
                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        Universal joints, also known as U-joints, are mechanical devices used to connect two
                                                        shafts that are not aligned in a straight line and allow for the transmission of rotational
                                                        motion and torque between them. They are used in various applications, including
                                                        automotive drivelines, industrial machinery, and power transmission systems.
                                                        A universal joint consists of a cross-shaped component with four arms, which are
                                                        connected to the shafts. The arms of the cross are connected to each other by
                                                        bearings, allowing for rotational movement in multiple directions. This design enables
                                                        the joint to accommodate misalignment and angular movement between the shafts
                                                        while transmitting torque.
                                                        The most common type of universal joint is the cross-and-bearing design, which utilizes
                                                        needle bearings or bushings at each end of the cross. These bearings allow the joint to
                                                        rotate smoothly and withstand high torque loads.
                                                        Universal joints are crucial in transmitting rotational power in applications where the
                                                        shafts are not in perfect alignment or need flexibility to accommodate movement. They
                                                        are used in various industries, including automotive, agriculture, construction, and
                                                        aerospace, to transfer power between shafts that are at different angles or have
                                                        variable distances between them.

                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            {/* slip yoke */}
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <div className='col-10'>
                                    <div style={{ background: '#f2f7fa', }} >
                                        <Row className='d-flex justify-content-center col-12 ' id="slipyoke">
                                            <Col lg={5} md={10} className='d-flex justify-content-center '  >
                                                <img src={require('../../Assets/images/sleevyoke.png')} style={{ borderRadius: '5%', aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center pt-2 ' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    {/* <h4 className="title">Forging Division</h4> */}
                                                    <h2 className="title text-left text-uppercase" style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>Sleeve Yokes/ Slip Yoke:</h2>
                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        Sleeve yokes, also known as slip yokes or sleeve-style yokes, are components used in
                                                        drivelines to connect a driveshaft to the transmission or differential. They allow for the
                                                        transfer of rotational motion and torque between the drivetrain components.
                                                        A sleeve yoke typically consists of a cylindrical sleeve that slides over the splined end of
                                                        a driveshaft and is connected to the transmission or differential output shaft. The
                                                        sleeve yoke is splined internally to match the splines on the driveshaft and externally to
                                                        match the splines on the transmission or differential output shaft.
                                                        The purpose of a sleeve yoke is to accommodate the length changes that occur in the
                                                        driveline due to suspension movement, axle articulation, or drivetrain flex. As the
                                                        driveshaft lengthens or contracts, the sleeve yoke slides in or out, allowing the
                                                        driveshaft to maintain a constant length while still transmitting rotational power.
                                                        Sleeve yokes are commonly used in rear-wheel drive vehicles, where the driveshaft
                                                        extends from the transmission to the rear differential. They are found in various
                                                        applications, including cars, trucks, SUVs, and commercial vehicles.

                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            {/* tube yoke */}
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <div className='col-10'>
                                    <div style={{ background: '#E7ECF5', }} >
                                        <Row className='d-flex justify-content-center col-12 ' id="tubeyoke">
                                            <Col lg={5} md={10} className='d-flex justify-content-center '  >
                                                <img src={require('../../Assets/images/tubeyoke.png')} style={{ borderRadius: '5%', aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center pt-2 ' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    {/* <h4 className="title">Forging Division</h4> */}
                                                    <h2 className="title text-left text-uppercase" style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>Tube Yoke:</h2>
                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        A tube yoke, also known as a weld yoke, is a component used in drivelines to connect a
                                                        driveshaft to another drivetrain component, such as a differential or transfer case. It is
                                                        typically used in applications where the driveshaft is directly welded to the yoke.<br></br>
                                                        A tube yoke consists of a cylindrical tube with a flanged end. The tube is designed to fit
                                                        over the end of a driveshaft and is typically welded in place to ensure a secure
                                                        connection. The flanged end of the yoke has holes or slots that allow for bolts or straps
                                                        to attach the yoke to the companion flange or output shaft of the drivetrain component.<br></br>
                                                        Tube yokes are commonly used in drivelines for heavy-duty applications, such as trucks,
                                                        off-road vehicles, and industrial equipment. They provide a strong and durable
                                                        connection between the driveshaft and the drivetrain component, allowing for the
                                                        transfer of torque and rotational motion.

                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            {/* yoke shaft */}
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <div className='col-10'>
                                    <div style={{ background: '#f2f7fa', }} >
                                        <Row className='d-flex justify-content-center col-12 ' >
                                            <Col lg={5} md={10} className='d-flex justify-content-center p-4'  >
                                                <img src={require("../../Assets/images/yokeshaft.png")} style={{ maxHeight: '370px', aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center mt-4 pt-4' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    <p style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>YOKE SHAFT :</p>
                                                    <p style={{ color: '#4a4949', }}>The use of external splines on yoke shafts offers several advantages:</p>
                                                    <p style={{ fontSize: '15px', color: '#767676', }}> Yoke shafts with external splines are components used in various mechanical systems,
                                                        including cardan shafts and power transmission applications. They are designed with
                                                        external splines on their surface, which are ridges or teeth that provide a form of
                                                        interlocking connection.
                                                        The external splines on the yoke shafts are typically paired with corresponding internal
                                                        splines on other components, such as companion flanges or other mating parts. When
                                                        the external splines of the yoke shaft engage with the internal splines of the mating
                                                        component, it creates a secure and rigid connection, transmitting torque between the
                                                        components.</p>

                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        <ul>
                                                            <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Torque Transmission :</span> <br></br>
                                                                <span style={{ color: '#767676', fontSize: '15px' }}>  The splines on the yoke shafts allow for efficient torque
                                                                    transfer between the shaft and the mating component. </span></li>
                                                        </ul>
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col className='col-10 '>
                                                <ul style={{ textAlign: 'justify' }}>
                                                    <span style={{ color: '#767676', fontSize: '15px' }}>The interlocking
                                                        engagement provides a reliable and high-capacity connection, ensuring effective
                                                        power transmission.</span>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Misalignment Compensation : </span><br></br>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}>  Yoke shafts with external splines are designed to
                                                            accommodate misalignment between the driving and driven components. The
                                                            splines allow for a certain degree of angular misalignment while maintaining
                                                            torque transmission.</span>
                                                    </li>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Easy Assembly and Disassembly :</span><br></br>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}>  The spline connection facilitates easy
                                                            assembly and disassembly of the yoke shaft and mating component. It enables
                                                            quick and secure attachment or removal without requiring complex or time-
                                                            consuming procedures.</span></li>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Versatility :</span><br></br>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}>   Yoke shafts with external splines can be used in various applications
                                                            and systems where torque transmission and misalignment compensation are
                                                            required. They are commonly found in cardan shafts, drive shafts, gearboxes, and
                                                            other power transmission mechanisms.</span></li>
                                                </ul>
                                            </Col>

                                        </Row>
                                    </div>
                                </div>
                            </div>
                            {/* center bearing */}
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <div className='col-10'>
                                    <div style={{ background: '#E7ECF5', }} >
                                        <Row className='d-flex justify-content-center col-12 ' id="centebearing">
                                            <Col lg={5} md={10} className='d-flex justify-content-center '  >
                                                <img src={require('../../Assets/images/centre-bearing.png')} style={{ borderRadius: '5%', aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center pt-2' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    {/* <h4 className="title">Forging Division</h4> */}
                                                    <h2 className="title text-left text-uppercase" style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>CENTER BEARING:</h2>
                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        A center bearing, also known as a center support bearing, is a component used in
                                                        vehicles with a two-piece driveshaft or propeller shaft. It is typically located in the
                                                        middle of the driveshaft, providing support and reducing vibrations and noise.
                                                        The center bearing is designed to bear the weight and torque load of the driveshaft and
                                                        help maintain alignment between the front and rear sections of the shaft. It consists of
                                                        a metal housing that contains a bearing assembly. The bearing assembly consists of an
                                                        inner race, outer race, and a set of rolling elements, such as balls or rollers, that allow
                                                        for smooth rotation.
                                                        The center bearing is mounted within the driveshaft assembly using brackets or
                                                        supports, often made of metal or rubber for vibration dampening. It is typically
                                                        positioned at the midpoint of the driveshaft, where it helps to reduce the bending and
                                                        flexing of the shaft and dampen any vibrations caused by the rotational forces.
                                                        By supporting the driveshaft and minimizing vibrations, the center bearing helps to
                                                        improve overall drivetrain performance, reduce noise and vibration, and enhance the
                                                        longevity of the drivetrain components. It is especially important in long driveshafts or
                                                        vehicles with high torque outputs, where driveshaft flexing and vibration can be more
                                                        significant.

                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            {/* splined stub */}
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <div className='col-10'>
                                    <div style={{ background: '#f2f7fa', }} >
                                        <Row className='d-flex justify-content-center col-12 ' id="splined">
                                            <Col lg={5} md={10} className='d-flex justify-content-center '  >
                                                <img src={require('../../Assets/images/splined_yoke.png')} style={{ borderRadius: '5%', aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center pt-2' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    {/* <h4 className="title">Forging Division</h4> */}
                                                    <h2 className="title text-left text-uppercase" style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>Splined Stub Shaft :</h2>
                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        A splined stub shaft, also known as a splined input shaft or simply a splined shaft, is a
                                                        component used in various mechanical systems, including automotive and industrial
                                                        applications. It consists of a shaft with a series of splines, which are longitudinal ridges
                                                        or grooves that extend along the length of the shaft.
                                                        The splines on a splined stub shaft are designed to mesh with corresponding splines on
                                                        another component, such as a gear, coupling, or drive mechanism. This spline-to-spline
                                                        connection allows for a secure and precise transfer of torque, rotational motion, or
                                                        force between the shaft and the connected component.The splines on a splined stub shaft are typically machined to precise tolerances to
                                                        ensure a tight fit and maximum power transmission efficiency. The number, size, and
                                                        shape of the splines can vary depending on the specific application and the
                                                        requirements of the system.
                                                        Splined stub shafts are commonly used in drivetrains, power transmission systems, and
                                                        other applications where torque or rotational motion needs to be transmitted from one
                                                        component to another. They are often found in automotive transmissions, power take-
                                                        off units, hydraulic pumps, and various industrial machinery.

                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            {/* flange yoke */}
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <div className='col-10'>
                                    <div style={{ background: '#E7ECF5', }} >
                                        <Row className='d-flex justify-content-center col-12 ' id="flangeyoke">
                                            <Col lg={5} md={10} className='d-flex justify-content-center pt-5'  >
                                                <img src={require('../../Assets/images/flang_yoke.png')} style={{ maxHeight: '370px', aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center mt-4' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    {/* <h4 className="title">Forging Division</h4> */}
                                                    <h2 className="title text-left text-uppercase" style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>Flange Yokes :</h2>
                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        A flange yoke is a mechanical component used in driveline systems to connect two
                                                        rotating shafts. It consists of a flange-shaped component with bolt holes and a central
                                                        hole or bore.
                                                        The flange yoke is typically attached to the end of a shaft, and it has a flange portion
                                                        with evenly spaced bolt holes around its perimeter. The bolt holes are used to secure
                                                        the flange yoke to another flanged component, such as a companion flange or a flange
                                                        on another shaft. The central hole or bore of the flange yoke is designed to fit onto the
                                                        corresponding shaft or spindle.
                                                        By bolting the flange yoke to another flanged component, a rigid connection is
                                                        established between the two shafts, allowing for the transmission of torque and
                                                        rotational motion. This coupling method provides a secure and reliable connection,
                                                        capable of handling high loads and rotational speeds.
                                                        Flange yokes are commonly used in automotive drivelines, agricultural equipment,
                                                        industrial machinery, and other applications where torque needs to be transmitted
                                                        between two shafts. They are often found in drive shaft assemblies, propeller shafts,
                                                        and other power transmission systems.

                                                    </p>
                                                    <ul>
                                                        <li><span style={{ color: '#4a4949', fontWeight: '500', fontSize: '15px' }}>There are various types of Flange yokes:</span><br></br>
                                                        </li>
                                                        <li style={{}} >SAE</li>
                                                        <li style={{}} >DIN</li>
                                                        <li style={{}}>Cross-Serrated</li>
                                                    </ul>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>

                            {/* Bore end yoke */}
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <div className='col-10'>
                                    <div style={{ background: '#F2F7FA', }} >
                                        <Row className='d-flex justify-content-center col-12 ' >
                                            <Col lg={5} md={10} className='d-flex justify-content-center p-4'  >
                                                <img src={require("../../Assets/images/bore_end_yoke.png")} style={{ aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center mt-4' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    <p style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>Bore End Yoke :</p>

                                                    <p style={{ color: '#4a4949', }}>Bore and keyway end yokes are specific types of end yokes used in cardan shaft assemblies. Here&#39;s a description of each :</p>

                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        <ul>
                                                            <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Bore End Yoke:</span>
                                                                <span style={{ color: '#767676', fontSize: '15px' }}> A bore end yoke is a type of end yoke that features a central bore or hole in its center. This bore is designed to fit over the end of a cylindrical or tubular shaft, allowing for a secure and precise connection. The bore is typically machined to match the outer diameter of the shaft, ensuring a tight fit. Bore end yokes are commonly used in applications where a shaft needs to be securely attached to the yoke, such as in power transmission systems. These are available in both half round and full round designs.</span></li>

                                                        </ul>
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col className='col-10 '>
                                                <ul style={{ textAlign: 'justify' }}>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Keyway End Yoke:</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}> A keyway end yoke is a type of end yoke that incorporates a keyway and key mechanism. A keyway is a longitudinal slot or channel machined into the inner diameter of the yoke's bore. A corresponding key is inserted into the keyway, providing a positive connection between the yoke and the shaft. The key prevents rotational slippage between the yoke and the shaft, ensuring that torque is effectively transmitted. Keyway end yokes are commonly used in applications where a strong and reliable torque transmission is required, such as in automotive drivelines and industrial machinery. These are available in both half round and full round designs.</span></li>


                                                </ul>
                                            </Col>
                                            <p className="text-justify" style={{ color: '#767676', fontSize: '14px', }}>Both bore end yokes and keyway end yokes play an important role in connecting the
                                                cardan shaft to other components in a system. The choice between these types of end
                                                yokes depends on factors such as the specific application requirements, torque
                                                transmission needs, and the type of shaft being used.</p>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            {/* Clamp yoke */}
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <div className='col-10'>
                                    <div style={{ background: '#E7ECF5', }} >
                                        <Row className='d-flex justify-content-center col-12 ' >
                                            <Col lg={5} md={10} className='d-flex justify-content-center p-4'  >
                                                <img src={require('../../Assets/images/Clamp Yoke.png')} style={{ aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center mt-4' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    {/* <h4 className="title">Forging Division</h4> */}
                                                    <h2 className="title text-left text-uppercase" style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>CLAMP YOKES  :</h2>
                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        Clamp yokes, also known as split yokes or split flange yokes, are a type of end yoke
                                                        used in cardan shaft assemblies. They are designed to provide a secure and adjustable
                                                        connection between the cardan shaft and another component, typically a flange or a
                                                        companion flange.
                                                        The main feature of clamp yokes is their split design. They consist of two separate
                                                        halves that can be clamped together around the flange or companion flange using bolts
                                                        or fasteners. This allows for easy installation and removal without the need to
                                                        disassemble the entire shaft assembly.
                                                        Clamp yokes are commonly used in applications where flexibility and adjustability are
                                                        required, such as in driveline systems for vehicles and industrial machinery. They offer
                                                        advantages such as easy alignment, angular adjustment, and the ability to
                                                        accommodate different flange sizes and bolt patterns. The clamp mechanism ensures
                                                        a secure and tight connection between the yoke and the flange, transmitting torque
                                                        effectively.
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            {/* COMPANION FLANGES : */}
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <div className='col-10'>
                                    <div style={{ background: '#F2F7FA', }} >
                                        <Row className='d-flex justify-content-center col-12 ' >
                                            <Col lg={5} md={10} className='d-flex justify-content-center p-4'  >
                                                <img src={require('../../Assets/images/companion_flanges.png')} style={{ aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center mt-4' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    {/* <h4 className="title">Forging Division</h4> */}
                                                    <h2 className="title text-left text-uppercase" style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>Companion Flanges : </h2>
                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        Companion flanges are components used in cardan shafts or universal joint shafts. A
                                                        cardan shaft typically consists of a central shaft with universal joints at each end and
                                                        companion flanges attached to the shaft ends. These flanges are usually made of steel
                                                        and are designed to connect the cardan shaft to the driven and driving components,
                                                        such as the transmission and differential in an automotive drivetrain.
                                                        The companion flanges have a series of holes that match the bolt pattern on the flange
                                                        of the driven or driving component. By bolting the companion flanges to these
                                                        components, a secure and rigid connection is created, allowing torque to be transmitted
                                                        from the cardan shaft to the driven component.
                                                        One important feature of companion flanges is their ability to accommodate angular
                                                        misalignment. The flanges have a circular shape with a central hole that fits over the
                                                        end of the cardan shaft. This design allows for a certain amount of flexibility and
                                                        movement, which helps to compensate for misalignment between the shafts.
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>

                            {/* MECHANICS TYPE COMPONENTS : */}
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <div className='col-10'>
                                    <div style={{ background: '#E7ECF5', }} >
                                        <Row className='d-flex justify-content-center col-12 ' >
                                            <Col lg={5} md={10} className='d-flex justify-content-center p-4'  >
                                                <img src={require("../../Assets/images/mechanics.png")} style={{ aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center mt-4' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    <p style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>Mechanics Type components :</p>
                                                    <p style={{ fontSize: '15px', color: '#767676', }}> Adroit’s all forged wide range of mechanics-style cardan parts are commonly used in industrial and earthmoving equipment due to their specific advantages in these applications. Here are some key advantages of mechanics-style cardan parts</p>

                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        <ul>
                                                            <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>High Torque Capacity :</span>
                                                                <span style={{ color: '#767676', fontSize: '15px' }}>Mechanics-style cardan parts are designed to handle high torque levels. This makes them suitable for heavy-duty applications commonly found in industrial and earthmoving equipment. The robust construction and materials used in these components allow for the transmission of substantial torque without failure.</span></li>
                                                            <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Increased Articulation :</span>
                                                                <span style={{ color: '#767676', fontSize: '15px' }}>In certain applications, such as off-road vehicles or machinery operating on uneven terrain, mechanics-style cardan parts offer increased articulation angles. This flexibility enables the equipment to navigate rough terrain, uneven surfaces, or changes in elevation while maintaining torque transmission between the driving and driven components.</span></li>


                                                        </ul>
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col className='col-10 '>
                                                <ul style={{ textAlign: 'justify' }}>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Durability and Reliability :</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}> Industrial and earthmoving equipment often operates under demanding conditions, including high loads, vibrations, and exposure to dirt, dust, and moisture. Mechanics-style cardan parts are designed to withstand these harsh environments, offering durability and reliability. They are typically constructed using robust materials and undergo rigorous testing to ensure long- term performance.</span></li>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Maintenance and Serviceability :</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}>Mechanics-style cardan parts are designed for ease of maintenance and serviceability. They are often modular in design, allowing for easier replacement of individual components when necessary. This feature minimizes downtime and reduces overall maintenance costs.</span></li>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Compatibility with Various Equipment :</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}>Mechanics-style cardan parts are versatile and compatible with a wide range of industrial and earthmoving equipment. They can be customized and adapted to suit specific applications, making them a preferred choice in industries where equipment requirements can vary significantly.</span></li>

                                                </ul>
                                            </Col>
                                            <p className="text-justify" style={{ color: '#767676', fontSize: '14px', }}>Overall, mechanics-style cardan parts offer several advantages that make them well- suited for industrial and earthmoving equipment. Their ability to handle high torque, increased articulation angles, durability, and ease of maintenance contribute to their effectiveness in challenging working environments.</p>
                                        </Row>
                                    </div>
                                </div>
                            </div>


                            {/* CV DOUBLE CARDAN DRIVE SHAFT ASSEMBLIES : */}
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <div className='col-10'>
                                    <div style={{ background: '#F2F7FA', }} >
                                        <Row className='d-flex justify-content-center col-12 ' >
                                            <Col lg={5} md={10} className='d-flex justify-content-center p-4'  >
                                                <img src={require("../../Assets/images/cvdouble.png")} style={{ maxHeight: '370px', aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center mt-4' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    <p style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>CV Double Cardan Drive Shaft Assemblies :</p>
                                                    <p style={{ fontSize: '15px', color: '#767676', }}> CV Double Cardan Drive Shaft Assemblies for American 4x4 pickups are specialized
                                                        drive shaft assemblies designed for vehicles with four-wheel drive systems. Here&#39;s an
                                                        overview of their features and functions:</p>
                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        <ul>
                                                            <li style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }} className='mt-2'> <span style={{ color: '#4a4949', fontSize: '15px' }}> Constant Velocity (CV) Joints :</span> <br></br>
                                                                CV Double Cardan Drive Shaft Assemblies utilize
                                                                CV joints at both ends of the shaft. CV joints allow for smooth and consistent
                                                                power transfer, even at varying angles and speeds. These joints help to minimize
                                                                vibrations, maintain proper driveline geometry, and accommodate the articulation
                                                                of the suspension.</li>
                                                            <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }} ><span style={{ color: '#4a4949', fontSize: '15px' }}>Double Cardan Design :</span> <br></br>
                                                                The steering knuckle provides a connection point
                                                                for the tie rods or drag link, which are part of the steering system. These linkages
                                                                transmit the steering input from the steering wheel to the knuckles, allowing the
                                                                wheels to turn and change direction.</li>


                                                        </ul>
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col className='col-10 '>
                                                <ul style={{ textAlign: 'justify' }}>
                                                    <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }}><span style={{ color: '#4a4949', fontSize: '15px' }}>Suitable for American 4x4 Pickups: </span><br></br>
                                                        These drive shaft assemblies are specifically
                                                        designed for American 4x4 pickups, which typically have higher ground clearance
                                                        and more aggressive off-road capabilities. The assemblies are engineered to
                                                        handle the increased torque and demands associated with off-road driving.</li>

                                                </ul></Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            {/* rang products */}
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <div className='col-10'>
                                    <div style={{ background: '#E7ECF5', }} >
                                        <Row className='d-flex justify-content-center col-12 ' >
                                            <Col lg={5} md={10} className='d-flex justify-content-center p-4'  >
                                                <img src={require("../../Assets/images/dummyimg.jpeg")} style={{ aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center mt-4' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    <p style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>Range of Products :</p>
                                                    <p style={{ fontSize: '15px', color: '#767676', }}>Adroit is proud to offer a wide range of quality forged cardan parts, with over 4000
                                                        options available. The forgings are produced in-house, ensuring precise control over the
                                                        micro-structure of the components. This attention to detail and quality control allows
                                                        Adroit to deliver reliable and high-performance products in the following ranges:</p>

                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        <ul>
                                                            <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Adroit's 10 series products (AD-500 to AD-1880, AD-90, AD-170, AD-250, AD-20, and AD-25):</span>
                                                                <span style={{ color: '#767676', fontSize: '15px' }}>These products likely refer to a range of cardan shafts or related components with varying torque capacities and dimensions. Each product within this series is designed to meet specific application requirements.
                                                                </span></li>
                                                            <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Wing Bearing Products (4c to 15c): </span>
                                                                <span style={{ color: '#767676', fontSize: '15px' }}>Adroit offers a range of wing bearing products with different sizes and capacities. Wing bearings are typically used in agricultural or industrial equipment for smooth rotation and movement.
                                                                </span></li>


                                                        </ul>
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col className='col-10 '>
                                                <ul style={{ textAlign: 'justify' }}>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Clamp Yokes :</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}>Clamp yokes are components used in cardan shaft assemblies to
                                                            securely connect the shaft to the universal joint or companion flange. Adroit
                                                            provides clamp yokes in various sizes and configurations to accommodate
                                                            different shaft and joint requirements.</span></li>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>CV Double Cardan Drive Shaft Assemblies :</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}> These assemblies are designed for
                                                            American 4x4 pickups and utilize a constant velocity (CV) joint configuration.
                                                            They provide smooth and efficient torque transfer in demanding off-road
                                                            conditions.</span></li>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>European Range (AD-287.10, AD-587.10, AD-587.20, AD-587.30, AD-687.10, AD-
                                                        687.20, AD-687.30, AD-687.40, AD-687.45, AD-687.55, and AD-687.65) :</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}>  This range
                                                            likely refers to a series of cardan shafts specifically designed for European
                                                            vehicles. Each product in this range is tailored to fit specific models and offers
                                                            the required performance and durability.</span></li>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Irrigation Driveshafts :</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}>   Adroit offers driveshafts specifically designed for irrigation
                                                            systems. These driveshafts are designed to withstand the unique operating
                                                            conditions and requirements of irrigation equipment.</span></li>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Short-coupled and Tubular Shafts :</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}>Adroit provides short-coupled and tubular
                                                            shafts that are used in various applications where compact design and efficient
                                                            torque transmission are required. These shafts are designed to accommodate
                                                            misalignments and transmit torque between non-collinear shafts.</span></li>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Suspension Brackets :</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}>   Suspension brackets are components used in vehicle
                                                            suspension systems to support and secure various suspension components.
                                                            Adroit offers suspension brackets designed to provide mounting points for
                                                            suspension components and maintain proper alignment. </span></li>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Companion Flanges :</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}>   Companion flanges are components used in cardan shaft
                                                            assemblies to connect the shaft to the driven component, such as the gearbox or
                                                            differential. Adroit offers companion flanges as part of its product range.</span></li>

                                                </ul>
                                            </Col>
                                            <p className="text-justify" style={{ color: '#767676', fontSize: '14px', }}>Please note that the specific details and variations within each product category would
                                                be best obtained from Adroit directly or by referring to our product catalog or
                                                documentation.</p>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            {/* end range products */}
                            {/* components of carden */}
                            <div style={{ paddin: '' }} >
                                <div className="col-lg-12 section-head text-center">
                                    <p className="title" style={{ fontSize: '32px' }}>COMPONENTS OF CARDAN:</p>
                                    <div className="dlab-separator-outer">
                                        <div className="dlab-separator bg-primary style-skew" />
                                    </div>
                                    {/* <h2 className="font-weight-700 m-b0">What Defines Adroit</h2> */}
                                </div>
                                <div className="container">
                                    <h5 style={{ color: '#4a4949', alignItems: 'center', fontSize: '22px' }}>Mainly two standards prevail while manufacturing and using the cardan components:</h5>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', }}>
                                    <div className='col-10'>
                                        <div style={{ background: '#F2F7FA', }} >
                                            <Row className='d-flex justify-content-center col-12 ' >
                                                <Col lg={5} md={10} className='d-flex justify-content-center p-4'  >
                                                    <img src={require("../../Assets/images/dummyimg.jpeg")} style={{ aspectRatio: '16/9' }}></img>

                                                </Col>
                                                <Col lg={7} md={12} className='d-flex flex-column justify-content-center mt-4' >
                                                    <div className=" d-flex flex-column justify-content-center text-justify " >

                                                        <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                            DIN (Deutsches Institut für Normung), SAE (Society of Automotive Engineers), and
                                                            Mechanics standards are different sets of technical specifications and guidelines used
                                                            in various industries, including the design and manufacturing of cardan shafts. Here are
                                                            some key differences between these standards:<br></br>
                                                            <span style={{ color: '#4a4949', fontWeight: '500', fontSize: '15px' }}>1. DIN Standards :</span><br></br>
                                                            DIN standards are developed and published by the German
                                                            Institute for Standardization. They are widely used in Europe and other parts of
                                                            the world. DIN standards for cardan shafts define parameters such as
                                                            dimensional requirements, material specifications, torque ratings, and
                                                            performance criteria. They provide guidelines for ensuring compatibility and
                                                            interchangeability of cardan shafts within specified applications.
                                                            <br></br>
                                                            <span style={{ color: '#4a4949', fontWeight: '500', fontSize: '15px' }}>2. SAE Standards :</span><br></br>
                                                            SAE standards are developed by the Society of Automotive
                                                            Engineers, primarily used in the automotive industry. SAE standards for cardan
                                                            shafts focus on the specific requirements and performance expectations of the
                                                            automotive sector. These standards cover topics such as size and dimensions,
                                                            torque ratings, operating angles, and fatigue strength. SAE standards are widely
                                                            adopted by automotive manufacturers and suppliers globally.
                                                            While there may be some overlap in the technical requirements covered by these
                                                            standards, each standard is typically developed by a specific organization for a specific
                                                            industry or application. The choice of which standard to follow may depend on factors
                                                            such as regional regulations, industry practices, customer requirements, and specific
                                                            application needs.
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end of componenet cardan */}
                            {/* splined end yoke */}
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <div className='col-10'>
                                    <div style={{ background: '#E7ECF5', }} >
                                        <Row className='d-flex justify-content-center col-12 ' id="slipyokedata">
                                            <Col lg={5} md={10} className='d-flex justify-content-center p-4'  >
                                                <img src={require("../../Assets/images/dummyimg.jpeg")} style={{ maxHeight: '370px', aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center mt-4' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    {/* <h4 className="title">Forging Division</h4> */}
                                                    <h2 className="title text-left text-uppercase" style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>SPLINED END YOKES :</h2>
                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        A splined end yoke is a type of end yoke that features splines on its mating surface.
                                                        Splines are parallel ridges or grooves machined onto a shaft or within a bore that
                                                        interlock with corresponding ridges or grooves on another component.These are
                                                        available in both half round and full round designs.
                                                        In the case of a splined end yoke, the splines on the mating surface of the yoke are
                                                        designed to interlock with the splines on a mating shaft or component, such as a slip
                                                        yoke or a companion flange. This spline connection provides a secure and precise
                                                        fitment, allowing for the transmission of torque and rotational motion between the
                                                        components.
                                                        The splined design of the end yoke enables a more efficient power transfer, as it
                                                        minimizes any slippage or play between the components. It also allows for angular
                                                        misalignment and axial movement, accommodating for any variation or flexing that may
                                                        occur during operation.
                                                        Splined end yokes are commonly used in applications where a high degree of torque
                                                        transmission is required, such as in heavy-duty driveline systems used in industrial
                                                        machinery, agricultural equipment, off-road vehicles, and other power transmission
                                                        applications.
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            {/* spliend end yoke */}
                            {/* Small Parts */}
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <div className='col-10'>
                                    <div style={{ background: '#F2F7FA', }} >
                                        <Row className='d-flex justify-content-center col-12 ' >
                                            <Col lg={5} md={10} className='d-flex justify-content-center p-4'  >
                                                <img src={require("../../Assets/images/dummyimg.jpeg")} style={{ aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center mt-4' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    <p style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>Small Parts :</p>
                                                    <p style={{ fontSize: '15px', color: '#767676', }}>Adroit supplies a comprehensive range of small accessories for assembling cardan shafts. These components, such as strap kits, u-bolts, rubber parts, seals, washers, dust caps, and greese jerks, are important for ensuring the proper functioning and longevity of cardan shafts. Here's a brief explanation of each component:</p>

                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        <ul>
                                                            <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Strap Kits :</span>
                                                                <span style={{ color: '#767676', fontSize: '15px' }}>   Strap kits typically consist of metal straps or bands that are used to
                                                                    secure the universal joint yokes to the companion flanges. They help to maintain
                                                                    a strong and secure connection between the cardan shaft and the driven/driving
                                                                    components.</span></li>
                                                            <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>U-Bolts :</span>
                                                                <span style={{ color: '#767676', fontSize: '15px' }}>U-bolts are bent bolts in the shape of the letter &quot;U.&quot; They are used to
                                                                    clamp the cardan shaft to the yokes or companion flanges. U-bolts provide a
                                                                    reliable method of securing the shaft in place.</span></li>


                                                        </ul>
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col className='col-10 '>
                                                <ul style={{ textAlign: 'justify' }}>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Rubber Parts :</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}>  Rubber parts, such as bushings or mounts, are used to absorb
                                                            vibrations and reduce noise transmission in the cardan shaft assembly. These
                                                            components help improve the overall comfort and performance of the vehicle or
                                                            machinery.</span></li>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Seals :</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}>Seals, often made of rubber or other flexible materials, are used to prevent
                                                            the leakage of lubricants and contaminants in the cardan shaft assembly. They
                                                            help to maintain proper lubrication and protect the internal components.</span></li>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Washers :</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}>Washers are flat, thin discs that are placed between the nuts or bolts
                                                            and the components being fastened. They distribute the load evenly and help
                                                            prevent damage to the surfaces they are placed against.</span></li>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Dust Caps :</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}>Dust caps are protective covers that are placed over the ends of the
                                                            cardan shaft or other exposed components. They help to keep dirt, debris, and
                                                            moisture out of the assembly, reducing the risk of damage and corrosion.</span></li>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Grease Jerks :</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}> Grease jerks, also known as grease fittings or grease nipples, are
                                                            fittings that allow for the injection of grease into the cardan shaft assembly. They
                                                            facilitate proper lubrication and maintenance of the universal joints and other
                                                            moving parts.</span></li>

                                                </ul>
                                            </Col>
                                            <p className="text-justify" style={{ color: '#767676', fontSize: '14px', }}>These small accessories are essential for assembling and maintaining cardan shafts,
                                                ensuring their reliability and performance. Adroit&#39;s supply of these parts can assist in
                                                the efficient construction and maintenance of cardan shaft systems.</p>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            {/* end small parts */}
                            {/* Suspension bracket  */}
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <div className='col-10'>
                                    <div style={{ background: '#E7ECF5', }} >
                                        <Row className='d-flex justify-content-center col-12 ' >
                                            <Col lg={5} md={10} className='d-flex justify-content-center p-4'  >
                                                <img src={require('../../Assets/images/dummyimg.jpeg')} style={{ aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center mt-4' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    {/* <h4 className="title">Forging Division</h4> */}
                                                    <h2 className="title text-left text-uppercase" style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>SUSPENSION BRACKETS  : </h2>
                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        Suspension brackets are components used in vehicle suspension systems to support
                                                        and secure various suspension components. They are typically made of metal and are
                                                        attached to the chassis or frame of the vehicle.
                                                        The primary function of suspension brackets is to provide mounting points for
                                                        suspension components such as control arms, shock absorbers, sway bars, and other
                                                        related components. They are designed to withstand the forces and loads generated
                                                        during vehicle operation, ensuring the stability, control, and overall performance of the
                                                        suspension system.
                                                        Suspension brackets are strategically positioned at specific locations on the vehicle to
                                                        support the suspension components and maintain proper alignment. They are often
                                                        bolted or welded onto the frame or chassis, providing a strong and rigid connection.
                                                        The design and construction of suspension brackets can vary depending on the specific
                                                        vehicle and suspension system. They need to be robust and durable to withstand the
                                                        constant vibration, impacts, and forces encountered during vehicle operation.
                                                        Properly designed and maintained suspension brackets play a critical role in
                                                        maintaining the integrity of the suspension system, ensuring proper wheel alignment,
                                                        absorbing road shocks, and providing a comfortable and stable ride for the occupants
                                                        of the vehicle.
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            {/* end suspension brackets */}
                            {/* STEERING KNUCKLES : */}
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <div className='col-10'>
                                    <div style={{ background: '#F2F7FA', }} >
                                        <Row className='d-flex justify-content-center col-12 ' >
                                            <Col lg={5} md={10} className='d-flex justify-content-center p-4 '  >
                                                <img src={require('../../Assets/images/dummyimg.jpeg')} style={{ aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center mt-4' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    <p style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>Steering Knuckles :</p>
                                                    <p style={{ fontSize: '15px', color: '#767676', }}> Steering knuckles, also known as steering arms, are important components of a
                                                        vehicle&#39;s steering system. They are typically found in the front suspension of vehicles
                                                        equipped with independent suspension or some types of solid axle suspension.
                                                        The primary function of steering knuckles is to connect the steering linkage, such as the
                                                        tie rods or drag link, to the wheel hubs or spindles. They serve as the connection point
                                                        between the steering system and the wheel assembly, allowing for controlled steering
                                                        movement and transfer of steering inputs to the wheels.</p>
                                                    <p>Key features and functions of steering knuckles include :</p>
                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        <ul>
                                                            <li style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }} className='mt-2'> <span style={{ color: '#4a4949', fontSize: '15px' }}> Wheel Hub Attachment :</span> <br></br>
                                                                Steering knuckles are designed to securely hold the
                                                                wheel hub or spindle assembly. They often have mounting points or flanges for
                                                                the wheel bearings, which allow the wheel to rotate smoothly.</li>
                                                            <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }} ><span style={{ color: '#4a4949', fontSize: '15px' }}>Steering Linkage Connection :</span> <br></br>
                                                                The steering knuckle provides a connection point
                                                                for the tie rods or drag link, which are part of the steering system. These linkages
                                                                transmit the steering input from the steering wheel to the knuckles, allowing the
                                                                wheels to turn and change direction.</li>


                                                        </ul>
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col className='col-10 '>
                                                <ul style={{ textAlign: 'justify' }}>
                                                    <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }}><span style={{ color: '#4a4949', fontSize: '15px' }}>Suspension Geometry Control :</span><br></br>
                                                        Steering knuckles also play a role in controlling
                                                        the suspension geometry of the vehicle. They help determine the steering axis
                                                        inclination and the kingpin inclination angles, which affect steering stability,
                                                        handling, and tire wear.</li>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Bearing Support :</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}> Steering knuckles often have integrated or separate bearings to
                                                            support the wheel hub and allow smooth rotation of the wheels. These bearings
                                                            help reduce friction and provide support for the vehicle&#39;s weight and lateral
                                                            forces.</span></li>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Mounting Points for Brake Components :</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}> In many vehicles, the steering knuckle
                                                            also provides mounting points for brake components such as calipers, rotors,
                                                            and brake pads. This allows the braking system to work in conjunction with the
                                                            steering and wheel assembly.</span></li>

                                                </ul></Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            {/*TUBING*/}
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <div className='col-10'>
                                    <div style={{ background: '#E7ECF5', }} >
                                        <Row className='d-flex justify-content-center col-12 ' >
                                            <Col lg={5} md={10} className='d-flex justify-content-center pt-5'  >
                                                <img src={require("../../Assets/images/dummyimg.jpeg")} style={{ maxHeight: '370px', aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center mt-4' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    <p style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>Tubing :</p>
                                                    <p style={{ fontSize: '15px', color: '#767676', }}> The selection of tubing for cardan shafts depends on several factors, including the
                                                        application requirements, operating conditions, and specific design considerations. The
                                                        following are some common types of tubing that are typically used in cardan shafts:</p>
                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        <ul>
                                                            <li style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }} className='mt-2'> <span style={{ color: '#4a4949', fontSize: '15px' }}> Seamless Steel Tubes :</span> <br></br>
                                                                Seamless steel tubes are widely used in cardan shafts
                                                                due to their high strength, durability, and ability to withstand torque loads. These
                                                                tubes are made from a single piece of steel without any welded seams, ensuring
                                                                uniform strength and reliability. They are commonly available in various grades
                                                                of carbon steel or alloy steel, chosen based on the desired mechanical properties
                                                                and performance requirements.</li>
                                                            <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }} ><span style={{ color: '#4a4949', fontSize: '15px' }}>Stainless Steel Tubes :</span> <br></br>
                                                                Stainless steel tubes are chosen for cardan shafts when
                                                                corrosion resistance is a critical factor. Stainless steel offers excellent resistance
                                                                to rust, corrosion, and other forms of degradation, making it suitable for
                                                                applications where the shaft may be exposed to moisture, chemicals, or
                                                                corrosive environments. Different grades of stainless steel, such as 304 or 316,
                                                                can be used based on the specific environmental conditions</li>


                                                        </ul>
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col className='col-10 '>
                                                <ul style={{ textAlign: 'justify' }}>
                                                    <li className='mt-2' style={{ fontSize: '15px', fontFamily: 'roboto', color: '#767676' }}><span style={{ color: '#4a4949', fontSize: '15px' }}>Aluminum Tubes :</span><br></br>
                                                        In certain applications where weight reduction is a priority,
                                                        aluminum tubes may be used in cardan shafts. Aluminum tubes offer a
                                                        lightweight solution while maintaining sufficient strength and durability. They are
                                                        commonly used in industries such as aerospace and automotive, where weight
                                                        savings are crucial for improved performance and fuel efficiency.</li>
                                                    <li ><span style={{ color: '#4a4949', fontSize: '15px' }}>Carbon Fiber Tubes :</span>
                                                        <span style={{ color: '#767676', fontSize: '15px' }}>  Carbon fiber tubes provide an excellent combination of high
                                                            strength and light weight. They offer superior strength-to-weight ratio compared
                                                            to steel or aluminum, making them suitable for applications where minimizing
                                                            weight is critical, such as high-performance racing vehicles or aerospace
                                                            applications. However, carbon fiber tubes can be more expensive than traditional
                                                            metal tubes.</span></li>

                                                </ul></Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            {/* forgine */}
                            <div style={{ display: 'flex', justifyContent: 'center', }} className="pb-4">
                                <div className='col-10'>
                                    <div style={{ background: '#F2F7FA', }} >
                                        <Row className='d-flex justify-content-center col-12 ' >
                                            <Col lg={5} md={10} className='d-flex justify-content-center p-4'  >
                                                <img src={require('../../Assets/images/dummyimg.jpeg')} style={{ aspectRatio: '16/9' }}></img>

                                            </Col>
                                            <Col lg={7} md={12} className='d-flex flex-column justify-content-center mt-4' >
                                                <div className=" d-flex flex-column justify-content-center text-justify " >
                                                    {/* <h4 className="title">Forging Division</h4> */}
                                                    <h2 className="title text-left text-uppercase" style={{ color: '#4a4949', fontWeight: '500', fontSize: '18px' }}>FORGINGS : </h2>
                                                    <p className="about-inner-content" style={{ color: '#767676', fontSize: '14px' }}>
                                                        Adroit's capability to produce forgings of profile items made from alloy steels with a wide range of input weights, from 1kg to 40kg, showcases their versatility and ability to cater to various industries and applications. The availability of a complete 360-degree infrastructure for heat treatments further emphasizes Adroit's commitment to delivering high-quality forgings.
                                                        By having comprehensive heat treatment facilities in our plant, Adroit can ensure that the forgings undergo the necessary processes to optimize their mechanical properties, such as hardness, strength, and toughness. Heat treatment plays a crucial role in enhancing the performance and durability of forged components.
                                                        Adroit's ability to manufacture forgings based on customer drawings and required specifications is a testament to it’s flexibility and customer-centric approach. This allows customers to obtain tailor-made forgings that precisely meet their specific needs and application requirements.
                                                        Being a reliable source of forgings and our ability to export based on customer requirements demonstrates Adroit's commitment to delivering quality products to a global customer base.
                                                        Overall, Adroit's comprehensive infrastructure, ability to produce a wide range of forgings, and commitment to customer satisfaction position them as a trusted and reliable supplier in the forging industry.
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurProduct
