const handleError = (error) => {
    console.log("error.response.", error);
    if (error?.response?.status === 404) {
        return error?.response?.data.detail
    } else if (error?.response?.status === 400) {
        return error?.response?.data.errors[0].error
    } else if (error?.response?.status === 500) {
        return error?.response?.statusText
    } else if (error?.response?.status === 0) {
        localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_KEY);
        return error?.response?.status
    } else if (error?.response?.status === 401) {
        localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_KEY);
        return error?.response?.status
    } else {
        return 'Network Error.'
    }

}
export { handleError }
